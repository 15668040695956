/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import { useAnimations, useGLTF, useScroll } from '@react-three/drei';
import { useFrame, useThree } from "@react-three/fiber";
import React, { useRef, useState } from 'react';
import * as THREE from 'three';

export default function Network({setIsCloudVisible, welcomeMessage, welcomeMessage2, setWelcomeMessage2, shiftedModel, setWelcomeMessage, isDarkMode, scrollExplore, daoImages, setDaoImages, setScrollExplore, setIsFooterVisible, scroll,  isFooterVisible, props, color = new THREE.Color(), scale}) {
  const group = useRef();
  scroll = useScroll()
  const web = useRef();
  const [currentShiftedModel, setCurrentShiftedModel] = useState(shiftedModel);


  const { nodes, animations, materials } = useGLTF('/Network.glb');
  //const { nodes2, animations2, materials2} = useGLTF('/WhiteNetwork.glb');

  const GradientMaterial = {
    uniforms: {
      startColor: { value: new THREE.Color(0x000000) },
      endColor: { value: new THREE.Color(0xffffff) },
    },
    vertexShader: `
      varying vec3 vNormal;
      void main() {
        vNormal = normal;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform vec3 startColor;
      uniform vec3 endColor;
      varying vec3 vNormal;
      void main() {
        gl_FragColor = vec4(mix(startColor, endColor, dot(vNormal, vec3(0.0, 1.0, 0.0)));
      }
    `,
  };
  
  const darkMaterial = materials['Material.029']; // Black color
  const lightMaterial = materials['Material.032']; // White color

  
  const { width: w, height: h } = useThree((state) => state.viewport)
  const width = window.innerWidth;
  const isMobile = width <= 600; // Check if the window width is 600 or less

  const {actions} = useAnimations(animations, group);
  

  const startFrame = 0;
  const endFrame = 96;
  


  const scaledFactor = isMobile ? 0.5 : 1; // Scaling factor based on mobile view or default

// Assuming you have an array of animation names like this:
const animationNames = [

'Cilindro_0.001|CINEMA_4D_Principale|Layer0',
'Cilindro_1.001|CINEMA_4D_Principale|Layer0',
'Cilindro_2.001|CINEMA_4D_Principale|Layer0',
'Cilindro_3.001|CINEMA_4D_Principale|Layer0',
'Cilindro_4.001|CINEMA_4D_Principale|Layer0',
'Cilindro_5.001|CINEMA_4D_Principale|Layer0',
'Cilindro_6.001|CINEMA_4D_Principale|Layer0',
'Cilindro_7.001|CINEMA_4D_Principale|Layer0',
'Cilindro_8.001|CINEMA_4D_Principale|Layer0',
'Cilindro_9.001|CINEMA_4D_Principale|Layer0',
'Cilindro_10.001|CINEMA_4D_Principale|Layer0',
'Cilindro_11.001|CINEMA_4D_Principale|Layer0',
'Cilindro_12.001|CINEMA_4D_Principale|Layer0',
'Cilindro_13.001|CINEMA_4D_Principale|Layer0',
'Cilindro_14.001|CINEMA_4D_Principale|Layer0',
'Cilindro_15.001|CINEMA_4D_Principale|Layer0',
'Cilindro_16.001|CINEMA_4D_Principale|Layer0',
'Cilindro_17.001|CINEMA_4D_Principale|Layer0',
'Cilindro_18.001|CINEMA_4D_Principale|Layer0',
'Cilindro_19.001|CINEMA_4D_Principale|Layer0',
'Cilindro_20.001|CINEMA_4D_Principale|Layer0',
'Cilindro_21.001|CINEMA_4D_Principale|Layer0',
'Cilindro_22.001|CINEMA_4D_Principale|Layer0',
'Cilindro_23.001|CINEMA_4D_Principale|Layer0',
'Cilindro_24.001|CINEMA_4D_Principale|Layer0',
'Cilindro_25.001|CINEMA_4D_Principale|Layer0',
'Cilindro_26.001|CINEMA_4D_Principale|Layer0',
'Cilindro_27.001|CINEMA_4D_Principale|Layer0',
'Cilindro_28.001|CINEMA_4D_Principale|Layer0',
'Cilindro_29.001|CINEMA_4D_Principale|Layer0',
'Cilindro_30.001|CINEMA_4D_Principale|Layer0',
'Cilindro_31.001|CINEMA_4D_Principale|Layer0',
'Cilindro_32.001|CINEMA_4D_Principale|Layer0',
'Cilindro_33.001|CINEMA_4D_Principale|Layer0',
'Cilindro_34.001|CINEMA_4D_Principale|Layer0',
'Cilindro_35.001|CINEMA_4D_Principale|Layer0',
'Cilindro_36.001|CINEMA_4D_Principale|Layer0',
'Cilindro_37.001|CINEMA_4D_Principale|Layer0',
'Cilindro_38.001|CINEMA_4D_Principale|Layer0',
'Cilindro_39.001|CINEMA_4D_Principale|Layer0',
'Cilindro_40.001|CINEMA_4D_Principale|Layer0',
'Cilindro_41.001|CINEMA_4D_Principale|Layer0',
'Cilindro_42.001|CINEMA_4D_Principale|Layer0',
'Cilindro_43.001|CINEMA_4D_Principale|Layer0',
'Cilindro_44.001|CINEMA_4D_Principale|Layer0',
'Cilindro_45.001|CINEMA_4D_Principale|Layer0',
'Cilindro_46.001|CINEMA_4D_Principale|Layer0',
'Cilindro_47.001|CINEMA_4D_Principale|Layer0',
'Cilindro_48.001|CINEMA_4D_Principale|Layer0',
'Cilindro_49.001|CINEMA_4D_Principale|Layer0',
'Cilindro_50.001|CINEMA_4D_Principale|Layer0',
'Cilindro_51.001|CINEMA_4D_Principale|Layer0',
'Cilindro_52.001|CINEMA_4D_Principale|Layer0',
'Cilindro_53.001|CINEMA_4D_Principale|Layer0',
'Cilindro_54.001|CINEMA_4D_Principale|Layer0',
'Cilindro_55.001|CINEMA_4D_Principale|Layer0',
'Cilindro_56.001|CINEMA_4D_Principale|Layer0',
'Cilindro_57.001|CINEMA_4D_Principale|Layer0',
'Cilindro_58.001|CINEMA_4D_Principale|Layer0',
'Cilindro_59.001|CINEMA_4D_Principale|Layer0',
'Cilindro_60.001|CINEMA_4D_Principale|Layer0',
'Cilindro_61.001|CINEMA_4D_Principale|Layer0',
'Cilindro_62.001|CINEMA_4D_Principale|Layer0',
'Cilindro_63.001|CINEMA_4D_Principale|Layer0',
'Cilindro_64.001|CINEMA_4D_Principale|Layer0',
'Cilindro_65.001|CINEMA_4D_Principale|Layer0',
'Cilindro_66.001|CINEMA_4D_Principale|Layer0',
'Cilindro_67.001|CINEMA_4D_Principale|Layer0',
'Cilindro_68.001|CINEMA_4D_Principale|Layer0',
'Cilindro_69.001|CINEMA_4D_Principale|Layer0',
'Cilindro_70.001|CINEMA_4D_Principale|Layer0',
'Cilindro_71.001|CINEMA_4D_Principale|Layer0',
'Cilindro_72.001|CINEMA_4D_Principale|Layer0',
'Cilindro_73.001|CINEMA_4D_Principale|Layer0',
'Cilindro_74.001|CINEMA_4D_Principale|Layer0',
'Cilindro_75.001|CINEMA_4D_Principale|Layer0',
'Cilindro_76.001|CINEMA_4D_Principale|Layer0',
'Cilindro_77.001|CINEMA_4D_Principale|Layer0',
'Cilindro_78.001|CINEMA_4D_Principale|Layer0',
'Cilindro_79.001|CINEMA_4D_Principale|Layer0',
'Cilindro_80.001|CINEMA_4D_Principale|Layer0',
'Cilindro_81.001|CINEMA_4D_Principale|Layer0',
'Cilindro_82.001|CINEMA_4D_Principale|Layer0',
'Cilindro_83.001|CINEMA_4D_Principale|Layer0',
'Cilindro_84.001|CINEMA_4D_Principale|Layer0',
'Cilindro_85.001|CINEMA_4D_Principale|Layer0',
'Cilindro_86.001|CINEMA_4D_Principale|Layer0',
'Cilindro_87.001|CINEMA_4D_Principale|Layer0',
'Cilindro_88.001|CINEMA_4D_Principale|Layer0',
'Cilindro_89.001|CINEMA_4D_Principale|Layer0',
'Cilindro_90.001|CINEMA_4D_Principale|Layer0',
'Cilindro_91.001|CINEMA_4D_Principale|Layer0',
'Cilindro_92.001|CINEMA_4D_Principale|Layer0',
'Cilindro_93.001|CINEMA_4D_Principale|Layer0',
'Cilindro_94.001|CINEMA_4D_Principale|Layer0',
'Cilindro_95.001|CINEMA_4D_Principale|Layer0',
'Cilindro_96.001|CINEMA_4D_Principale|Layer0',
'Cilindro_97.001|CINEMA_4D_Principale|Layer0',
'Cilindro_98.001|CINEMA_4D_Principale|Layer0',
'Cilindro_99.001|CINEMA_4D_Principale|Layer0',
'Cilindro_100.001|CINEMA_4D_Principale|Layer0',
'Cilindro_101.001|CINEMA_4D_Principale|Layer0',
'Cilindro_102.001|CINEMA_4D_Principale|Layer0',
'Cilindro_103.001|CINEMA_4D_Principale|Layer0',
'Cilindro_104.001|CINEMA_4D_Principale|Layer0',
'Cilindro_105.001|CINEMA_4D_Principale|Layer0',
'Cilindro_106.001|CINEMA_4D_Principale|Layer0',
'Cilindro_107.001|CINEMA_4D_Principale|Layer0',
'Cilindro_108.001|CINEMA_4D_Principale|Layer0',
'Cilindro_109.001|CINEMA_4D_Principale|Layer0',
'Cilindro_110.001|CINEMA_4D_Principale|Layer0',
'Cilindro_111.001|CINEMA_4D_Principale|Layer0',
'Cilindro_112.001|CINEMA_4D_Principale|Layer0',
'Cilindro_113.001|CINEMA_4D_Principale|Layer0',
'Cilindro_114.001|CINEMA_4D_Principale|Layer0',
'Cilindro_115.001|CINEMA_4D_Principale|Layer0',
'Cilindro_116.001|CINEMA_4D_Principale|Layer0',
'Cilindro_117.001|CINEMA_4D_Principale|Layer0',
'Cilindro_118.001|CINEMA_4D_Principale|Layer0',
'Cilindro_119.001|CINEMA_4D_Principale|Layer0',
//'Cilindro_120.001|CINEMA_4D_Principale|Layer0',
'Sfera_0.001|CINEMA_4D_Principale|Layer0',
'Sfera_1.001|CINEMA_4D_Principale|Layer0',
'Sfera_2.001|CINEMA_4D_Principale|Layer0',
'Sfera_3.001|CINEMA_4D_Principale|Layer0',
'Sfera_4.001|CINEMA_4D_Principale|Layer0',
'Sfera_5.001|CINEMA_4D_Principale|Layer0',
'Sfera_6.001|CINEMA_4D_Principale|Layer0',
'Sfera_7.001|CINEMA_4D_Principale|Layer0',
'Sfera_8.001|CINEMA_4D_Principale|Layer0',
'Sfera_9.001|CINEMA_4D_Principale|Layer0',
'Sfera_10.001|CINEMA_4D_Principale|Layer0',
'Sfera_11.001|CINEMA_4D_Principale|Layer0',
'Sfera_12.001|CINEMA_4D_Principale|Layer0',
'Sfera_13.001|CINEMA_4D_Principale|Layer0',
'Sfera_14.001|CINEMA_4D_Principale|Layer0',
'Sfera_15.001|CINEMA_4D_Principale|Layer0',
'Sfera_16.001|CINEMA_4D_Principale|Layer0',
'Sfera_17.001|CINEMA_4D_Principale|Layer0',
'Sfera_18.001|CINEMA_4D_Principale|Layer0',
'Sfera_19.001|CINEMA_4D_Principale|Layer0',
'Sfera_20.001|CINEMA_4D_Principale|Layer0',
'Sfera_21.001|CINEMA_4D_Principale|Layer0',
'Sfera_22.001|CINEMA_4D_Principale|Layer0',
'Sfera_23.001|CINEMA_4D_Principale|Layer0',
'Sfera_24.001|CINEMA_4D_Principale|Layer0',
'Sfera_25.001|CINEMA_4D_Principale|Layer0',
'Sfera_26.001|CINEMA_4D_Principale|Layer0',
'Sfera_27.001|CINEMA_4D_Principale|Layer0',
'Sfera_28.001|CINEMA_4D_Principale|Layer0',
'Sfera_29.001|CINEMA_4D_Principale|Layer0',
'Sfera_30.001|CINEMA_4D_Principale|Layer0',
'Sfera_31.001|CINEMA_4D_Principale|Layer0',
'Sfera_32.001|CINEMA_4D_Principale|Layer0',
'Sfera_33.001|CINEMA_4D_Principale|Layer0',
'Sfera_34.001|CINEMA_4D_Principale|Layer0',
'Sfera_35.001|CINEMA_4D_Principale|Layer0',
'Sfera_36.001|CINEMA_4D_Principale|Layer0',
'Sfera_37.001|CINEMA_4D_Principale|Layer0',
'Sfera_38.001|CINEMA_4D_Principale|Layer0',
'Sfera_39.001|CINEMA_4D_Principale|Layer0',
'Sfera_40.001|CINEMA_4D_Principale|Layer0',
'Sfera_41.001|CINEMA_4D_Principale|Layer0',

];

// Function to play all animations at once
function playAllAnimations(animationNames) {
  animationNames.forEach((animationName) => {
    // Play each animation immediately
   // actions[animationName].play()
  });
}

// Call the function to play all animations at once



const speedFactor = 1.5; // Adjust the speed factor as needed (3 for 3x speed)
const originalAnimationDuration = 3.88; // The original duration of your animation

const animationRefs = animationNames.map(animationName => actions[animationName]);






  
useFrame((state) => {
  const offset = scroll.offset;
  const rotationSpeed = 0.01; // Maintain the rotation speed
  const spinSpeed = 0.025; // Speed for spinning when scroll.offset is 0 or 1

  // Handle rotation and spinning based on scroll offset
  if (offset === 0 || offset === 1) {
    // Spin when scroll.offset is at the endpoints
    const currentTime = state.clock.getElapsedTime();
    const scaledTime = (currentTime * speedFactor) % originalAnimationDuration;
    animationRefs.forEach(animation => {
      if (animation) {
        animation.time = scaledTime;
        animation.play();
      }
    });

    // Apply spinning rotation
    group.current.rotation.y += 0;
    web.current.rotation.y += 0;
  } else {
    // Rotate based on scrolling, with direction change at the midway point
    const rotationDirection = offset > 0.5 ? -1 : 1;
    group.current.rotation.y += rotationDirection * rotationSpeed;
    web.current.rotation.y += rotationDirection * rotationSpeed;

    // Stop and reset animations
    animationRefs.forEach(animation => {
      animation.reset();
    });
  }

  // Update footer visibility and image exploration based on scroll position
   // Update visibility and state based on scroll offset
   setIsFooterVisible(offset === 1);
   setDaoImages(offset > 0 && offset < 1);
   setScrollExplore(offset > 0 && offset < 1);

   const isAtBottom2 = offset >= 0.975; 
   const isAtBottom = offset >= 0.995; // Consider 95% scroll as the bottom or adjust as needed
   setIsFooterVisible(isAtBottom);
   setIsCloudVisible(isAtBottom2);

  const isInRange = offset >= 0.01 && offset <= 1;
  const isInRange2 = offset >= 0.105 && offset <= 1;
  setWelcomeMessage(isInRange);
  setWelcomeMessage2(isInRange2);
});




  
  



  return (
    <>  {welcomeMessage && (
<></>
    )}
  <group ref={group} {...props} dispose={null}>
   <group scale={.65 * scaledFactor}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <group
          name="Cilindro_10001"
          position={[104.26, 51.59, -81.32]}
          rotation={[-1.48, -0.71, 1.9]}
          scale={[1, 1, 0.6]}>
          <mesh geometry={nodes.Cilindro_10_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial}/>
        </group>
        <group
          name="Cilindro_108001"
          position={[-1.08, 193.32, -44.52]}
          rotation={[0.65, -0.26, 2.94]}
          scale={[1, 1, 0.45]}>
          <mesh geometry={nodes.Cilindro_108_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial}/>
        </group>
        <group
          name="Cilindro_11001"
          position={[151.06, 54.66, -20.12]}
          rotation={[-0.64, 0.1, 2.26]}
          scale={[1, 1, 0.81]}>
          <mesh geometry={nodes.Cilindro_11_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_115001"
          position={[25.09, -154.21, 21.34]}
          rotation={[3.08, -0.39, -2.87]}
          scale={[1, 1, 0.53]}>
          <mesh geometry={nodes.Cilindro_115_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_116001"
          position={[58.26, -169.34, -20.77]}
          rotation={[2.94, 0.14, 2.88]}
          scale={[1, 1, 0.93]}>
          <mesh geometry={nodes.Cilindro_116_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_17001"
          position={[135.79, -46.4, -105.6]}
          rotation={[-1.2, 0.13, 1.85]}
          scale={[1, 1, 0.57]}>
          <mesh geometry={nodes.Cilindro_17_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_19001"
          position={[158.63, -24.16, -12.49]}
          rotation={[-1.77, 0.21, 1.65]}
          scale={[1, 1, 0.5]}>
          <mesh geometry={nodes.Cilindro_19_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_22001"
          position={[164.04, 63.56, 11.43]}
          rotation={[-0.87, -0.09, 1.72]}
          scale={[1, 1, 0.52]}>
          <mesh geometry={nodes.Cilindro_22_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_24001"
          position={[106.85, -70.24, -130.01]}
          rotation={[1.43, 0.64, -0.1]}
          scale={[1, 1, 0.36]}>
          <mesh geometry={nodes.Cilindro_24_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_25001"
          position={[53.47, -105.15, -117.93]}
          rotation={[1.32, -0.45, 0.12]}
          scale={[1, 1, 0.73]}>
          <mesh geometry={nodes.Cilindro_25_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_26001"
          position={[46.7, -178.19, -106.59]}
          rotation={[2.05, 1.17, -1.37]}
          scale={[1, 1, 0.27]}>
          <mesh geometry={nodes.Cilindro_26_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_29001"
          position={[109.92, -129.95, -57.12]}
          rotation={[-0.81, 0.45, 1.04]}
          scale={[1, 1, 0.88]}>
          <mesh geometry={nodes.Cilindro_29_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_35001"
          position={[-34.48, -107.11, -123.27]}
          rotation={[0.14, 0.27, -0.51]}
          scale={[1, 1, 0.58]}>
          <mesh geometry={nodes.Cilindro_35_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_36001"
          position={[1.59, -142, -84.76]}
          rotation={[2.13, 0.57, -1.91]}
          scale={[1, 1, 0.38]}>
          <mesh geometry={nodes.Cilindro_36_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_37001"
          position={[-123.33, -35.16, -114.48]}
          rotation={[0.9, -0.54, -1.26]}
          scale={[1, 1, 0.92]}>
          <mesh geometry={nodes.Cilindro_37_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_4001"
          position={[96.65, 72.69, -120]}
          rotation={[-0.52, -0.57, 2.76]}
          scale={[1, 1, 0.58]}>
          <mesh geometry={nodes.Cilindro_4_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_40001"
          position={[-184.9, -86.68, -30.54]}
          rotation={[-0.29, -0.37, -1.17]}
          scale={[1, 1, 0.39]}>
          <mesh geometry={nodes.Cilindro_40_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_41001"
          position={[-108.77, -95.61, -31.71]}
          rotation={[2.65, 1.14, -2.55]}
          scale={[1, 1, 0.99]}>
          <mesh geometry={nodes.Cilindro_41_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_42001"
          position={[-154.56, -52.31, -42.08]}
          rotation={[-1.09, 0.31, -2.1]}
          scale={[1, 1, 0.53]}>
          <mesh geometry={nodes.Cilindro_42_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_44001"
          position={[-85.89, 44.84, -108.66]}
          rotation={[-0.32, -0.17, -2.38]}
          scale={[1, 1, 0.59]}>
          <mesh geometry={nodes.Cilindro_44_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_48001"
          position={[-174.5, 33.86, -14.23]}
          rotation={[1.68, 0.72, -1.24]}
          scale={[1, 1, 0.55]}>
          <mesh geometry={nodes.Cilindro_48_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_52001"
          position={[-117.06, 119.43, -43.04]}
          rotation={[1.76, 0.35, -0.84]}
          scale={[1, 1, 0.61]}>
          <mesh geometry={nodes.Cilindro_52_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_54001"
          position={[70.06, 26.88, 151.92]}
          rotation={[-2.58, -1.15, -1.18]}
          scale={[1, 1, 0.74]}>
          <mesh geometry={nodes.Cilindro_54_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial}/>
        </group>
        <group
          name="Cilindro_56001"
          position={[117.66, 44.45, 170.92]}
          rotation={[1.38, 0.52, 2.89]}
          scale={[1, 1, 0.4]}>
          <mesh geometry={nodes.Cilindro_56_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_57001"
          position={[-61.41, 45.1, 151.38]}
          rotation={[-0.09, -1.2, 1.63]}
          scale={[1, 1, 0.69]}>
          <mesh geometry={nodes.Cilindro_57_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial}/>
        </group>
        <group
          name="Cilindro_6001"
          position={[4.51, 71.04, -111.34]}
          rotation={[2.49, -0.96, 0.85]}
          scale={[1, 1, 0.74]}>
          <mesh geometry={nodes.Cilindro_6_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_62001"
          position={[30.21, 116.82, 143.46]}
          rotation={[1.08, 0.99, 2.62]}
          scale={[1, 1, 0.81]}>
          <mesh geometry={nodes.Cilindro_62_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_66001"
          position={[135.19, 49.96, 141.19]}
          rotation={[0.82, 0.04, 2.03]}
          scale={[1, 1, 0.53]}>
          <mesh geometry={nodes.Cilindro_66_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial}/>
        </group>
        <group
          name="Cilindro_80001"
          position={[113.55, -73.35, 127.4]}
          rotation={[-1.5, 1.17, 0.68]}
          scale={[1, 1, 0.37]}>
          <mesh geometry={nodes.Cilindro_80_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_81001"
          position={[108.28, -38.44, 151.94]}
          rotation={[-1.09, 0.48, -0.12]}
          scale={[1, 1, 0.63]}>
          <mesh geometry={nodes.Cilindro_81_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_82001"
          position={[-45.37, -108.55, 111.77]}
          rotation={[-1.44, -1.07, -0.58]}
          scale={[1, 1, 0.48]}>
          <mesh geometry={nodes.Cilindro_82_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_84001"
          position={[-106.48, -19.3, 145.56]}
          rotation={[-1.14, -0.25, -0.42]}
          scale={[1, 1, 0.76]}>
          <mesh geometry={nodes.Cilindro_84_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_87001"
          position={[-61.32, -123.32, 68.25]}
          rotation={[-2.95, -0.95, -2.53]}
          scale={[1, 1, 0.71]}>
          <mesh geometry={nodes.Cilindro_87_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_89001"
          position={[-159.08, -95.78, 15.96]}
          rotation={[-2.11, -1.05, -1.61]}
          scale={[1, 1, 0.46]}>
          <mesh geometry={nodes.Cilindro_89_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_9001"
          position={[135.34, 27.44, -123.93]}
          rotation={[0.5, 0.17, 1.85]}
          scale={[1, 1, 0.45]}>
          <mesh geometry={nodes.Cilindro_9_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_91001"
          position={[-122.92, -75.76, 34.18]}
          rotation={[1.74, 0.09, -2.38]}
          scale={[1, 1, 0.4]}>
          <mesh geometry={nodes.Cilindro_91_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_92001"
          position={[-106.97, -60.99, 77.7]}
          rotation={[0.59, 0.41, -1.53]}
          scale={[1, 1, 0.49]}>
          <mesh geometry={nodes.Cilindro_92_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_93001"
          position={[-126.03, 5.57, 108.6]}
          rotation={[-0.55, 0.01, -1.09]}
          scale={[1, 1, 0.8]}>
          <mesh geometry={nodes.Cilindro_93_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial}/>
        </group>
        <group
          name="Cilindro_95001"
          position={[-187.14, 73.13, 38.95]}
          rotation={[0.04, 0.45, -1.62]}
          scale={[1, 1, 0.54]}>
          <mesh geometry={nodes.Cilindro_95_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <group
          name="Cilindro_98001"
          position={[-144.48, 59.19, 132.01]}
          rotation={[0.26, 0.39, -1.76]}
          scale={[1, 1, 0.5]}>
          <mesh geometry={nodes.Cilindro_98_2001.geometry} material={isDarkMode ? darkMaterial : lightMaterial} />
        </group>
        <mesh
          name="Cilindro_0001"
          geometry={nodes.Cilindro_0001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-43.17, 26.27, -144.5]}
          rotation={[0.03, 1.04, 1.8]}
          scale={[1, 1, 0.38]}
        />
        <mesh
          name="Cilindro_1001"
          geometry={nodes.Cilindro_1001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-33.6, 35.45, -135.36]}
          rotation={[-2.36, -1.02, 2.03]}
          scale={[1, 1, 0.27]}
        />
        <mesh
          name="Cilindro_100001"
          geometry={nodes.Cilindro_100001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-122.19, 122.05, 60.45]}
          rotation={[1.08, -0.62, -2.22]}
          scale={[1, 1, 0.71]}
        />
        <mesh
          name="Cilindro_101001"
          geometry={nodes.Cilindro_101001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-59.23, 164.26, 77.98]}
          rotation={[0.19, 0.45, -2.93]}
          scale={[1, 1, 0.5]}
        />
        <mesh
          name="Cilindro_102001"
          geometry={nodes.Cilindro_102001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-95.19, 139.95, 79.44]}
          rotation={[-2.52, 0.25, -0.65]}
          scale={[1, 1, 0.58]}
        />
        <mesh
          name="Cilindro_103001"
          geometry={nodes.Cilindro_103001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-136.42, 89.02, 106.55]}
          rotation={[2.39, -0.8, -1.36]}
          scale={[1, 1, 0.38]}
        />
        <mesh
          name="Cilindro_104001"
          geometry={nodes.Cilindro_104001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-46.7, 170.09, 11.58]}
          rotation={[3.01, 1, 0.22]}
          scale={[1, 1, 0.41]}
        />
        <mesh
          name="Cilindro_105001"
          geometry={nodes.Cilindro_105001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-24.97, 161.37, 56.21]}
          rotation={[0.09, -0.19, 2.7]}
          scale={[1, 1, 0.68]}
        />
        <mesh
          name="Cilindro_106001"
          geometry={nodes.Cilindro_106001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-109.49, 174.53, 0.5]}
          rotation={[0.05, 0.71, -2.72]}
          scale={[1, 1, 0.44]}
        />
        <mesh
          name="Cilindro_107001"
          geometry={nodes.Cilindro_107001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-75.24, 171.64, -21.26]}
          rotation={[0.38, 1.38, 3.07]}
          scale={[1, 1, 0.64]}
        />
        <mesh
          name="Cilindro_109001"
          geometry={nodes.Cilindro_109001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[74.38, 104.18, 22.68]}
          rotation={[-3.13, 0.64, 0.31]}
          scale={[1, 1, 0.69]}
        />
        <mesh
          name="Cilindro_110001"
          geometry={nodes.Cilindro_110001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[10.4, 135.28, 33.65]}
          rotation={[0.63, 0.4, 2.48]}
          scale={[1, 1, 0.59]}
        />
        <mesh
          name="Cilindro_111001"
          geometry={nodes.Cilindro_111001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[51.54, 136.1, -21.15]}
          rotation={[1.91, 1.09, 1.8]}
          scale={[1, 1, 0.72]}
        />
        <mesh
          name="Cilindro_112001"
          geometry={nodes.Cilindro_112001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[13.53, -163.07, -64.48]}
          rotation={[-0.16, -0.22, -0.68]}
          scale={[1, 1, 0.39]}
        />
        <mesh
          name="Cilindro_113001"
          geometry={nodes.Cilindro_113001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[38.33, -172.3, -69.27]}
          rotation={[2.8, 0.64, -2.78]}
          scale={[1, 1, 0.56]}
        />
        <mesh
          name="Cilindro_114001"
          geometry={nodes.Cilindro_114001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[0.92, -144.21, 40.87]}
          rotation={[-0.19, -0.06, -0.26]}
          scale={[1, 1, 0.69]}
        />
        <mesh
          name="Cilindro_117001"
          geometry={nodes.Cilindro_117001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-6.78, -136.11, -47.44]}
          rotation={[0.8, 0.39, -0.81]}
          scale={[1, 1, 0.32]}
        />
        <mesh
          name="Cilindro_118001"
          geometry={nodes.Cilindro_118001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-69.02, -115.23, -20.06]}
          rotation={[-3.14, 0.81, -2.52]}
          scale={[1, 1, 0.69]}
        />
        <mesh
          name="Cilindro_119001"
          geometry={nodes.Cilindro_119001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-57.08, -136.29, 0.22]}
          rotation={[2.49, 1.07, -2.23]}
          scale={[1, 1, 0.71]}
        />
        <mesh
          name="Cilindro_12001"
          geometry={nodes.Cilindro_12001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[137.35, 103.99, 6.44]}
          rotation={[-3.12, -0.51, 0.56]}
          scale={[1, 1, 0.44]}
        />
        <mesh
          name="Cilindro_13001"
          geometry={nodes.Cilindro_13001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[129.24, 55.67, -58.68]}
          rotation={[2.06, 0.24, 0.76]}
          scale={[1, 1, 0.58]}
        />
        <mesh
          name="Cilindro_14001"
          geometry={nodes.Cilindro_14001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[90.55, 100.92, -54.76]}
          rotation={[2.96, -0.83, 1.03]}
          scale={[1, 1, 0.34]}
        />
        <mesh
          name="Cilindro_15001"
          geometry={nodes.Cilindro_15001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[62.91, 162.22, -55.49]}
          rotation={[-1.96, -0.71, 1.39]}
          scale={[1, 1, 0.81]}
        />
        <mesh
          name="Cilindro_16001"
          geometry={nodes.Cilindro_16001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[128.18, -25.29, -144.28]}
          rotation={[1.33, 0.01, 0.6]}
          scale={[1, 1, 0.76]}
        />
        <mesh
          name="Cilindro_18001"
          geometry={nodes.Cilindro_18001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[138.49, -85.8, -64.4]}
          rotation={[-0.21, 0.16, 1.19]}
          scale={[1, 1, 0.64]}
        />
        <mesh
          name="Cilindro_2001"
          geometry={nodes.Cilindro_2001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-66.12, 36.07, -154.44]}
          rotation={[2.35, -0.64, 0.03]}
          scale={[1, 1, 0.16]}
        />
        <mesh
          name="Cilindro_20001"
          geometry={nodes.Cilindro_20001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[148.77, -37.49, -74.04]}
          rotation={[2.27, -0.24, 1.45]}
          scale={[1, 1, 0.83]}
        />
        <mesh
          name="Cilindro_21001"
          geometry={nodes.Cilindro_21001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[155.93, 15.24, -53.69]}
          rotation={[2.87, -0.38, 1.2]}
          scale={[1, 1, 0.35]}
        />
        <mesh
          name="Cilindro_23001"
          geometry={nodes.Cilindro_23001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[106.4, 3.6, -148.34]}
          rotation={[1.26, -0.42, -0.2]}
          scale={[1, 1, 0.52]}
        />
        <mesh
          name="Cilindro_27001"
          geometry={nodes.Cilindro_27001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[78.28, -114.39, -122.73]}
          rotation={[-1.72, 0.09, -3.01]}
          scale={[1, 1, 0.74]}
        />
        <mesh
          name="Cilindro_28001"
          geometry={nodes.Cilindro_28001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[100.07, -143.28, -118.67]}
          rotation={[-1.73, 0.57, 2.42]}
          scale={[1, 1, 0.53]}
        />
        <mesh
          name="Cilindro_3001"
          geometry={nodes.Cilindro_3001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[58.55, 37.1, -144.02]}
          rotation={[-2.59, 1.27, -1.79]}
          scale={[1, 1, 0.73]}
        />
        <mesh
          name="Cilindro_30001"
          geometry={nodes.Cilindro_30001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[37.21, -7.85, -129.74]}
          rotation={[1.7, 0.96, -0.06]}
          scale={[1, 1, 0.59]}
        />
        <mesh
          name="Cilindro_31001"
          geometry={nodes.Cilindro_31001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-62.97, -36.14, -171.19]}
          rotation={[1.69, 0.2, -0.12]}
          scale={[1, 1, 0.65]}
        />
        <mesh
          name="Cilindro_32001"
          geometry={nodes.Cilindro_32001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-30.44, -36.76, -152.11]}
          rotation={[-1.17, 0.28, 2.79]}
          scale={[1, 1, 0.71]}
        />
        <mesh
          name="Cilindro_33001"
          geometry={nodes.Cilindro_33001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[17.41, -70.26, -156.43]}
          rotation={[-0.91, 1.08, 2.38]}
          scale={[1, 1, 0.77]}
        />
        <mesh
          name="Cilindro_34001"
          geometry={nodes.Cilindro_34001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-14.18, -134.06, -140.3]}
          rotation={[0.74, 0.61, -0.3]}
          scale={[1, 1, 0.63]}
        />
        <mesh
          name="Cilindro_38001"
          geometry={nodes.Cilindro_38001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-110.61, -98.18, -122.09]}
          rotation={[3.12, 0.82, -2.29]}
          scale={[1, 1, 0.83]}
        />
        <mesh
          name="Cilindro_39001"
          geometry={nodes.Cilindro_39001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-94.84, -106.12, -66.55]}
          rotation={[1.7, 1.45, -1.77]}
          scale={[1, 1, 0.77]}
        />
        <mesh
          name="Cilindro_43001"
          geometry={nodes.Cilindro_43001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-168.49, -41.8, -7.24]}
          rotation={[1.25, -0.7, -1.78]}
          scale={[1, 1, 0.47]}
        />
        <mesh
          name="Cilindro_45001"
          geometry={nodes.Cilindro_45001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-133.53, -17.2, -59.56]}
          rotation={[1.64, -0.44, -1.29]}
          scale={[1, 1, 0.89]}
        />
        <mesh
          name="Cilindro_46001"
          geometry={nodes.Cilindro_46001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-117.12, 27.68, -36.26]}
          rotation={[1.11, -0.49, -1.01]}
          scale={[1, 1, 0.45]}
        />
        <mesh
          name="Cilindro_47001"
          geometry={nodes.Cilindro_47001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-153.47, 68.97, -31.71]}
          rotation={[-0.27, -1.19, -1.73]}
          scale={[1, 1, 0.62]}
        />
        <mesh
          name="Cilindro_49001"
          geometry={nodes.Cilindro_49001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-76.32, 54.02, -99.52]}
          rotation={[-0.19, -0.4, -2]}
          scale={[1, 1, 0.51]}
        />
        <mesh
          name="Cilindro_5001"
          geometry={nodes.Cilindro_5001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[27.46, 61.24, -101.41]}
          rotation={[2.16, -0.73, 0.12]}
          scale={[1, 1, 0.57]}
        />
        <mesh
          name="Cilindro_50001"
          geometry={nodes.Cilindro_50001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-63.87, 197.76, -55.6]}
          rotation={[0.75, -1.17, -2.19]}
          scale={[1, 1, 0.81]}
        />
        <mesh
          name="Cilindro_51001"
          geometry={nodes.Cilindro_51001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-97.29, 110.66, -88.82]}
          rotation={[2.28, 0.44, -0.97]}
          scale={[1, 1, 0.96]}
        />
        <mesh
          name="Cilindro_53001"
          geometry={nodes.Cilindro_53001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-174.44, 125.62, -21]}
          rotation={[1.35, -0.61, -1.37]}
          scale={[1, 1, 0.63]}
        />
        <mesh
          name="Cilindro_55001"
          geometry={nodes.Cilindro_55001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[50.32, 60.52, 145.31]}
          rotation={[-0.75, 1.07, -0.67]}
          scale={[1, 1, 0.54]}
        />
        <mesh
          name="Cilindro_58001"
          geometry={nodes.Cilindro_58001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-117.48, 77.09, 150.99]}
          rotation={[-2.28, 0.2, -0.23]}
          scale={[1, 1, 0.4]}
        />
        <mesh
          name="Cilindro_59001"
          geometry={nodes.Cilindro_59001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-53.35, 74.93, 125.92]}
          rotation={[1.56, 1.05, 3.03]}
          scale={[1, 1, 0.65]}
        />
        <mesh
          name="Cilindro_60001"
          geometry={nodes.Cilindro_60001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-5.75, 92.51, 144.92]}
          rotation={[0.64, 1.34, -2.14]}
          scale={[1, 1, 1.06]}
        />
        <mesh
          name="Cilindro_61001"
          geometry={nodes.Cilindro_61001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-73.46, 131.23, 124.07]}
          rotation={[-1.63, 0.98, -0.68]}
          scale={[1, 1, 0.43]}
        />
        <mesh
          name="Cilindro_63001"
          geometry={nodes.Cilindro_63001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[146.07, 96.05, 74.94]}
          rotation={[0.23, -0.4, 2.27]}
          scale={[1, 1, 0.33]}
        />
        <mesh
          name="Cilindro_64001"
          geometry={nodes.Cilindro_64001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[83.1, 96.24, 91.17]}
          rotation={[-2.66, -1.27, 0.25]}
          scale={[1, 1, 0.52]}
        />
        <mesh
          name="Cilindro_65001"
          geometry={nodes.Cilindro_65001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[96.2, 103.17, 61.24]}
          rotation={[3.13, 1.32, 0.16]}
          scale={[1, 1, 0.65]}
        />
        <mesh
          name="Cilindro_67001"
          geometry={nodes.Cilindro_67001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[115.44, 83.61, 134.59]}
          rotation={[-2.96, 0.53, 0.51]}
          scale={[1, 1, 0.35]}
        />
        <mesh
          name="Cilindro_68001"
          geometry={nodes.Cilindro_68001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[65.58, 90.73, 120.9]}
          rotation={[-2.86, -0.62, 0.4]}
          scale={[1, 1, 0.56]}
        />
        <mesh
          name="Cilindro_69001"
          geometry={nodes.Cilindro_69001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-2.13, 129.45, 100.04]}
          rotation={[2.28, 0.8, 1.04]}
          scale={[1, 1, 0.49]}
        />
        <mesh
          name="Cilindro_7001"
          geometry={nodes.Cilindro_7001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[37.93, 158.14, -78.13]}
          rotation={[-1.58, -0.42, 2.53]}
          scale={[1, 1, 0.67]}
        />
        <mesh
          name="Cilindro_70001"
          geometry={nodes.Cilindro_70001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[150.94, 56.63, 41.36]}
          rotation={[-0.47, -0.25, 1.41]}
          scale={[1, 1, 0.74]}
        />
        <mesh
          name="Cilindro_71001"
          geometry={nodes.Cilindro_71001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[148.15, -65.74, 62.79]}
          rotation={[-0.1, 0, 1.03]}
          scale={[1, 1, 0.66]}
        />
        <mesh
          name="Cilindro_72001"
          geometry={nodes.Cilindro_72001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[158.43, -17.42, 53.15]}
          rotation={[-2.64, 0.12, 1.55]}
          scale={[1, 1, 0.87]}
        />
        <mesh
          name="Cilindro_73001"
          geometry={nodes.Cilindro_73001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[140.46, 15.06, 116.65]}
          rotation={[-1.73, -0.1, 1.42]}
          scale={[1, 1, 0.75]}
        />
        <mesh
          name="Cilindro_74001"
          geometry={nodes.Cilindro_74001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[142.68, -24.09, 152.98]}
          rotation={[-0.96, -0.12, 0.9]}
          scale={[1, 1, 0.43]}
        />
        <mesh
          name="Cilindro_75001"
          geometry={nodes.Cilindro_75001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[96.68, -111.86, 33.5]}
          rotation={[0.83, -0.77, 1.19]}
          scale={[1, 1, 0.74]}
        />
        <mesh
          name="Cilindro_76001"
          geometry={nodes.Cilindro_76001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[96.49, -105.12, 99.14]}
          rotation={[-1, 0.76, 0.73]}
          scale={[1, 1, 0.75]}
        />
        <mesh
          name="Cilindro_77001"
          geometry={nodes.Cilindro_77001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[20.85, -141.24, 89.37]}
          rotation={[-0.47, -0.83, -0.02]}
          scale={[1, 1, 0.33]}
        />
        <mesh
          name="Cilindro_78001"
          geometry={nodes.Cilindro_78001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[37.92, -109.47, 117.63]}
          rotation={[-1.19, 1.05, 0.18]}
          scale={[1, 1, 0.47]}
        />
        <mesh
          name="Cilindro_79001"
          geometry={nodes.Cilindro_79001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[62.09, -119.47, 98.1]}
          rotation={[2.3, -0.38, 2.86]}
          scale={[1, 1, 0.46]}
        />
        <mesh
          name="Cilindro_8001"
          geometry={nodes.Cilindro_8001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-23.13, 132.34, -112.08]}
          rotation={[1.94, -0.34, 0.02]}
          scale={[1, 1, 0.99]}
        />
        <mesh
          name="Cilindro_83001"
          geometry={nodes.Cilindro_83001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-4.13, -86.78, 120.51]}
          rotation={[0.16, 1.5, -1.58]}
          scale={[1, 1, 0.84]}
        />
        <mesh
          name="Cilindro_85001"
          geometry={nodes.Cilindro_85001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-42.35, -21.46, 120.48]}
          rotation={[1.66, -0.61, 2.97]}
          scale={[1, 1, 0.79]}
        />
        <mesh
          name="Cilindro_86001"
          geometry={nodes.Cilindro_86001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[40.94, -22.38, 126.34]}
          rotation={[1.57, 0.53, -2.86]}
          scale={[1, 1, 0.76]}
        />
        <mesh
          name="Cilindro_88001"
          geometry={nodes.Cilindro_88001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-103.37, -100.63, 71.13]}
          rotation={[-0.33, 0.33, -0.88]}
          scale={[1, 1, 0.49]}
        />
        <mesh
          name="Cilindro_90001"
          geometry={nodes.Cilindro_90001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-162.68, -56.14, 22.53]}
          rotation={[-0.83, 0.93, -1.17]}
          scale={[1, 1, 0.45]}
        />
        <mesh
          name="Cilindro_94001"
          geometry={nodes.Cilindro_94001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-132.33, -21.78, 10.98]}
          rotation={[0.45, 0.17, -1.88]}
          scale={[1, 1, 0.34]}
        />
        <mesh
          name="Cilindro_96001"
          geometry={nodes.Cilindro_96001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-150.78, 31.84, 34.39]}
          rotation={[2.51, 0.19, -2.11]}
          scale={[1, 1, 0.67]}
        />
        <mesh
          name="Cilindro_97001"
          geometry={nodes.Cilindro_97001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-144.97, 17.49, 64.16]}
          rotation={[1.98, 0.31, -1.86]}
          scale={[1, 1, 0.61]}
        />
        <mesh
          name="Cilindro_99001"
          geometry={nodes.Cilindro_99001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-145.91, 124.07, 11.84]}
          rotation={[-1.16, 0.88, -1.41]}
          scale={[1, 1, 0.84]}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          name="Sfera_0001"
          geometry={nodes.Sfera_0001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[10.29, 219.44, -78.86]}
          scale={1.25}
        />
        <mesh
          name="Sfera_1001"
          geometry={nodes.Sfera_1001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-37.5, 155.54, 122.6]}
          scale={1.64}
        />
        <mesh
          name="Sfera_10001"
          geometry={nodes.Sfera_10001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-125.54, 47.26, 176.46]}
          scale={1.6}
        />
        <mesh
          name="Sfera_11001"
          geometry={nodes.Sfera_11001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[137.4, 10.81, 177.52]}
          scale={1.74}
        />
        <mesh
          name="Sfera_12001"
          geometry={nodes.Sfera_12001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-10.64, 25.65, -125.42]}
          scale={1.48}
        />
        <mesh
          name="Sfera_13001"
          geometry={nodes.Sfera_13001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[65.57, 96.84, -77.39]}
          scale={1.93}
        />
        <mesh
          name="Sfera_14001"
          geometry={nodes.Sfera_14001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-56.56, 45.25, -145.3]}
          scale={1.04}
        />
        <mesh
          name="Sfera_15001"
          geometry={nodes.Sfera_15001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[142.95, 6.33, -85.25]}
          scale={1.94}
        />
        <mesh
          name="Sfera_16001"
          geometry={nodes.Sfera_16001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[115.53, 105, -32.12]}
          scale={2.15}
        />
        <mesh
          name="Sfera_17001"
          geometry={nodes.Sfera_17001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[128.63, -99.12, -125.95]}
          scale={1.58}
        />
        <mesh
          name="Sfera_18001"
          geometry={nodes.Sfera_18001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[168.91, 24.15, -22.14]}
          scale={1.43}
        />
        <mesh
          name="Sfera_19001"
          geometry={nodes.Sfera_19001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[85.06, -41.35, -134.06]}
          scale={1.6}
        />
        <mesh
          name="Sfera_2001"
          geometry={nodes.Sfera_2001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[159.17, 102.98, 45.01]}
          scale={2}
        />
        <mesh
          name="Sfera_20001"
          geometry={nodes.Sfera_20001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[71.5, -187.43, -111.39]}
          scale={1.11}
        />
        <mesh
          name="Sfera_21001"
          geometry={nodes.Sfera_21001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-50.24, -99.17, -178.8]}
          scale={2.03}
        />
        <mesh
          name="Sfera_22001"
          geometry={nodes.Sfera_22001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-170.97, -97.2, -65.38]}
          scale={1.28}
        />
        <mesh
          name="Sfera_23001"
          geometry={nodes.Sfera_23001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-18.71, -115.05, -67.73]}
          scale={1.11}
        />
        <mesh
          name="Sfera_24001"
          geometry={nodes.Sfera_24001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-96.09, 62.79, -53.74]}
          scale={1.62}
        />
        <mesh
          name="Sfera_25001"
          geometry={nodes.Sfera_25001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-138.15, -7.43, -18.79]}
          scale={1.37}
        />
        <mesh
          name="Sfera_26001"
          geometry={nodes.Sfera_26001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-138.03, 176.08, -32.34]}
          scale={2.16}
        />
        <mesh
          name="Sfera_27001"
          geometry={nodes.Sfera_27001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[2.72, 42.94, 126.31]}
          scale={2.08}
        />
        <mesh
          name="Sfera_28001"
          geometry={nodes.Sfera_28001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-109.42, 106.93, 125.53]}
          scale={1.57}
        />
        <mesh
          name="Sfera_29001"
          geometry={nodes.Sfera_29001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[97.91, 78.1, 164.32]}
          scale={1.57}
        />
        <mesh
          name="Sfera_3001"
          geometry={nodes.Sfera_3001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[148.34, -72.47, -2.84]}
          scale={1.85}
        />
        <mesh
          name="Sfera_30001"
          geometry={nodes.Sfera_30001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[132.97, 89.12, 104.86]}
          scale={1.19}
        />
        <mesh
          name="Sfera_31001"
          geometry={nodes.Sfera_31001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[33.24, 103.36, 77.48]}
          scale={2.13}
        />
        <mesh
          name="Sfera_32001"
          geometry={nodes.Sfera_32001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[147.95, -59, 128.43]}
          scale={1.36}
        />
        <mesh
          name="Sfera_33001"
          geometry={nodes.Sfera_33001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[45.02, -151.24, 69.84]}
          scale={1.39}
        />
        <mesh
          name="Sfera_34001"
          geometry={nodes.Sfera_34001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[79.16, -87.7, 126.36]}
          scale={2.07}
        />
        <mesh
          name="Sfera_35001"
          geometry={nodes.Sfera_35001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-87.42, -85.87, 114.65]}
          scale={1.2}
        />
        <mesh
          name="Sfera_36001"
          geometry={nodes.Sfera_36001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-119.32, -115.4, 27.6]}
          scale={1.05}
        />
        <mesh
          name="Sfera_37001"
          geometry={nodes.Sfera_37001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-126.52, -36.12, 40.75]}
          scale={2.21}
        />
        <mesh
          name="Sfera_38001"
          geometry={nodes.Sfera_38001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-163.42, 71.11, 87.56]}
          scale={1.84}
        />
        <mesh
          name="Sfera_39001"
          geometry={nodes.Sfera_39001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-80.96, 172.98, 33.35]}
          scale={1.37}
        />
        <mesh
          name="Sfera_4001"
          geometry={nodes.Sfera_4001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[21.89, -168.95, -101.79]}
          scale={1.87}
        />
        <mesh
          name="Sfera_40001"
          geometry={nodes.Sfera_40001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-12.45, 167.2, -10.18]}
          scale={1.46}
        />
        <mesh
          name="Sfera_41001"
          geometry={nodes.Sfera_41001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[5.16, -157.18, -27.16]}
          scale={1.4}
        />
        <mesh
          name="Sfera_5001"
          geometry={nodes.Sfera_5001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-3.33, -131.23, 108.89]}
          scale={1.56}
        />
        <mesh
          name="Sfera_6001"
          geometry={nodes.Sfera_6001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-198.83, -76.16, 4.31]}
          scale={1.49}
        />
        <mesh
          name="Sfera_7001"
          geometry={nodes.Sfera_7001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-210.86, 75.15, -9.67]}
          scale={1.72}
        />
        <mesh
          name="Sfera_8001"
          geometry={nodes.Sfera_8001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[127.73, 48.55, -162.61]}
          scale={1.87}
        />
        <mesh
          name="Sfera_9001"
          geometry={nodes.Sfera_9001.geometry}
          material={isDarkMode ? darkMaterial : lightMaterial} 
          position={[-75.69, 26.88, -163.58]}
          scale={2.22}
        />
      </group>
      <group ref={web} name="w3oLogo"  position={[-0.01, 0.01, 0.01]} rotation={[-3.14, 0, -3.14]} scale={[0.26, 0.25, 0.65]}>
        <mesh geometry={nodes.Cube025.geometry} material={materials['Material.029']} />
        <mesh geometry={nodes.Cube025_1.geometry} material={materials['Material.032']} />
      </group>
      </group>
    </group>
    </>
  )
}

useGLTF.preload('/Network.glb')