import React, { useEffect, useState } from 'react';
import { FaCaretDown, FaCaretUp, FaEye, FaGem, FaGlobe, FaInfoCircle } from 'react-icons/fa';
import symbol from '../../../../images/w3oBlackLogo.png';
import icon from '../../../../images/w3oNameHorzBlack.png';
import icon2 from '../../../../images/w3oNameHorzWhite.png';

import {
  AnimateBubbles,
  CompanyLink,
  CompanyName,
  CompanySymbol,
  DiamondButton,
  DiamondColorWrap,
  DiamondCount,
  DropdownMenu,
  DropdownToggle,
  HorzLine,
  NavbarItem,
  NavbarItemLast,
  ProjectName,
  SubNavbar,
  SubscriberCount,
  WebsiteLabel
} from "./NavbarComponents";

const dropdownVariants = {
  hidden: { opacity: 0, y: 20, filter: 'blur(15px)' },
  visible: { 
    opacity: 1, 
    y: 0, 
    filter: 'blur(0px)', 
    transition: { duration: 0.05, staggerChildren: 0.05 }
  }
};


const itemVariants = {
  hidden: { opacity: 0, y: 20, filter: 'blur(15px)' },
  visible: { opacity: 1, y: 0, filter: 'blur(0px)', transition: { duration: 0.3 } }
};

const navbarVariants = {
  hidden: { width: 0, opacity: 0 },
  visible: { 
    width: '85%', 
    opacity: 1, 
    transition: { 
      duration: 1, 
      ease: 'easeInOut', 
      when: 'beforeChildren', 
      staggerChildren: 0.2 
    } 
  }
};

const itemPopVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } }
};

export const MainSubNavbar = ({ isDarkMode, handleFollowClick, handleDiamondClick, subscribers, diamonds, isSubscribed, isDiamondClicked, showAbout, setShowAbout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDiamondClickWithAnimation = (event) => {
    if (!isDiamondClicked) {
      const target = event.currentTarget.querySelector('.animate-bubbles');
      target.classList.add('animate');
      setTimeout(() => {
        target.classList.remove('animate');
      }, 750); // duration of the animation
    }
    handleDiamondClick();
  };

  return (
    <>
      {isMobile ? (
        <SubNavbar isMobile={isMobile} isDarkMode={isDarkMode}>
          <NavbarItemLast className="company-info">
            <CompanySymbol src={symbol} alt="Symbol" />
            <CompanyName src={isDarkMode ? icon : icon2} alt="Company Name" />
          </NavbarItemLast>
          <DropdownToggle onClick={toggleDropdown}>
            {isDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}
          </DropdownToggle>
          <DropdownMenu
            initial="hidden"
            animate={isDropdownOpen ? "visible" : "hidden"}
            variants={dropdownVariants}
            isOpen={isDropdownOpen}
          >
            <NavbarItemLast variants={itemVariants} className="company-info">
              <ProjectName isDarkMode={isDarkMode}>Closet / Project Name</ProjectName>
            </NavbarItemLast>
            <HorzLine variants={itemVariants}/>
            <NavbarItemLast variants={itemVariants} onClick={() => setShowAbout(true)}>
              <FaInfoCircle /> About
            </NavbarItemLast>
            <HorzLine variants={itemVariants}/>
            <NavbarItemLast variants={itemVariants}>
              <WebsiteLabel isDarkMode={isDarkMode}><FaGlobe /> </WebsiteLabel>
              <CompanyLink href="https://companywebsite.com" target="_blank" rel="noopener noreferrer" isDarkMode={isDarkMode}>
                companywebsite.com
              </CompanyLink>
            </NavbarItemLast>
            <HorzLine variants={itemVariants}/>
            <NavbarItemLast variants={itemVariants}>
              <FaEye /> VIEWS
              <SubscriberCount isSubscribed={isSubscribed}>{subscribers} Views</SubscriberCount>
            </NavbarItemLast>
            <HorzLine variants={itemVariants}/>
            <NavbarItemLast variants={itemVariants} onClick={handleDiamondClickWithAnimation}>
              <DiamondButton className="animate-bubbles" isDiamondClicked={isDiamondClicked}><FaGem /></DiamondButton>
              DIAMONDS
              <DiamondCount isDiamondClicked={isDiamondClicked}>{diamonds}</DiamondCount>
            </NavbarItemLast>
          </DropdownMenu>
        </SubNavbar>
      ) : (
        <SubNavbar
          isDarkMode={isDarkMode}
          initial="hidden"
          animate="visible"
          variants={navbarVariants}
          isMobile={isMobile}
        >
          <NavbarItem className="company-info" variants={itemPopVariants}>
            <CompanySymbol src={symbol} alt="Symbol" />
            <CompanyName src={isDarkMode ? icon : icon2} alt="Company Name" />
          </NavbarItem>
          <NavbarItem className="company-info" variants={itemPopVariants}>
            <ProjectName isDarkMode={isDarkMode}>Closet / Project Name</ProjectName>
          </NavbarItem>
          <NavbarItem variants={itemPopVariants} onClick={() => setShowAbout(true)}>
            <FaInfoCircle /> About
          </NavbarItem>
          <NavbarItem variants={itemPopVariants}>
            <WebsiteLabel isDarkMode={isDarkMode}><FaGlobe /></WebsiteLabel>
            <CompanyLink href="https://companywebsite.com" target="_blank" rel="noopener noreferrer" isDarkMode={isDarkMode}>
              companywebsite.com
            </CompanyLink>
          </NavbarItem>
          <NavbarItem variants={itemPopVariants}>
            <FaEye /> VIEWS
            <SubscriberCount isSubscribed={isSubscribed}>{subscribers} Views</SubscriberCount>
          </NavbarItem>
          <NavbarItemLast variants={itemPopVariants} onClick={handleDiamondClickWithAnimation}>
            <AnimateBubbles className="animate-bubbles" isDiamondClicked={isDiamondClicked}>
              <DiamondColorWrap isDiamondClicked={isDiamondClicked}><FaGem /></DiamondColorWrap>
            </AnimateBubbles>
            <DiamondColorWrap isDiamondClicked={isDiamondClicked}>DIAMONDS</DiamondColorWrap>
            <DiamondCount isDiamondClicked={isDiamondClicked}>{diamonds}</DiamondCount>
          </NavbarItemLast>
        </SubNavbar>
      )}
    </>
  );
};
