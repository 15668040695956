import React, { Suspense, createContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './AppElements';
//import { MasterPlan } from './components/MasterPlan/MasterPlan';
import Navbar from './components/Navbar/Navbar';
import NavbarAll from './components/Navbar/NavbarAll';
import PopUp from './components/PopUp/PopUps/PopUp';
import PopUp2 from './components/PopUp/PopUps/PopUp2';
import PopUp3 from './components/PopUp/PopUps/PopUp3';
import Preloader from './components/Preloader/Preloader';
import WhitelistPopUp from './components/Sidebar/PopUp';
import Sidebar from './components/Sidebar/Sidebar';
import Utilities from './components/Utilities/Utilities';
import CustomCloset from './pages/CompanyCloset/Model';
import DaoForm from './pages/JoinDao/DaoForm/DaoForm';
import NetworkScene from './pages/JoinDao/Model';
import Privacy from './pages/Privacy-Tos/Privacy';
import Tos from './pages/Privacy-Tos/Tos';
//import RedeemNavbar from './pages/Redeem/RedeemNavbar/RedeemNavbar';
//import RedeemPage from './pages/Redeem/RedeemPage';
export const UserContext = createContext();

export default function App() {
  const location = useLocation(); // Get the current route location
  const [currentState, setCurrentState] = useState(1); 
  const [whitelistPopup, setWhitelistPopup] = useState(false);
  const [daoPopup, setDaoPopup] = useState(false);
  const [trigger4, setTrigger4] = useState(false);
  const [trigger5, setTrigger5] = useState(false);
  const [utilitiesPopup, setUtilitiesPopup] = useState(false);

  const [redeemAudio, setRedeemAudio] = useState(false);
  const toggleMusic = () => {
    console.log("redeem audio", redeemAudio);
    setRedeemAudio((prevMode) => !prevMode);
  };
  // ... Existing code ...


  /*
  const getMyCollection = async () => {
    let web = window.web3;
    //TODO: get account from state, once state is defined
    let account = window.account;
    const burnReceiptContract = new web3.eth.Contract(burnReceiptAbi.abi, burnReceiptAbi.address);
    let balance = await burnReceiptContract.methods.balanceOf(account).call();
    var burnReceipts = [];
    for (var i = 0; i < parseInt(balance); i++) {
      let tokenId = await burnReceiptContract.methods.tokenOfOwnerByIndex(account, i).call();
      let burnReceipt = await burnReceiptContract.methods.getBurnReceipt(parseInt(tokenId)).call();
      burnReceipts.push(burnReceipt);
    }
    //TODO: Update state variable for my collection here
    return burnReceipts;
  }

  //function that gets all burn receipts (explore/shop)
  const getExploreCollection = async () => {
    let web = window.web3;
    let account = window.account;
    const burnReceiptContract = new web3.eth.Contract(burnReceiptAbi.abi, burnReceiptAbi.address);
    let balance = await burnReceiptContract.methods.totalSupply().call();
    var burnReceipts = [];
    for (var i = 0; i < parseInt(balance); i++) {
      let burnReceipt = await burnReceiptContract.methods.getBurnReceipt(i).call();
      burnReceipts.push(burnReceipt);
    }
    //TODO: Update state variable for my explore/shop collection here
    return burnReceipts;
  }
  */

  const [isTextVisible, setTextVisible] = useState(false);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isUtilitiesOpen, setIsUtilitiesOpen] = useState(false);
  const [start, setStart] = useState(false);
//Coming Soon Popups
  const [buttonPopup, setButtonPopup] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);

  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [planSwitch, setPlanSwitch] = useState(false);
  const [modelSwitch, setModelSwitch] = useState(false);
  const [roadmapSwitch, setRoadmapSwitch] = useState(false); // New state for roadmap display control

  const [isDarkMode, setIsDarkMode] = useState(false); 
  // ...other existing code


  const [orbitControlsEnabled, setOrbitControlsEnabled] = useState(false);
  const [daoProgress, setDaoProgress] = useState(0);
  //Footer State
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  
  const toggleOrbitControls = () => {
    setOrbitControlsEnabled((prevMode) => !prevMode);
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const lightTheme = {
    position: 'absolute',
    background: `
      linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%)
    `,
    text: '#333332',
  };
  
  const darkTheme = {
    position: 'absolute',
    background: `
      linear-gradient(to right, #434343 0%, black 100%)
    `,
    text: '#FAF4E5',
  };
  const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: ${(props) => props.theme.background};
  z-index: -1; /* Ensure it stays behind other content */
`;

  

  const handleMusicToggle = () => {
    const audioPlayer = document.getElementById('audio-player');

    if (audioPlayer.paused) {
      audioPlayer.play();
    } else {
      audioPlayer.pause();
    }
    setIsMusicPlaying(audioPlayer.paused);
  };


  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleUtilities = () => {
    setIsUtilitiesOpen(!isUtilitiesOpen);
  };

  const [windowDimensions, setWindowDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Calculate the width and height
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    // Apply width and height to body and #root elements
    const bodyElement = document.body;
    const rootElement = document.getElementById('root');

    if (bodyElement) {
      bodyElement.style.width = `${w}px`;
      bodyElement.style.height = `${h}px`;
    }

    if (rootElement) {
      rootElement.style.width = `${w}px`;
      rootElement.style.height = `${h}px`;
    }
  }, []);

  return (
    <>

      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <GlobalStyle />
        <BackgroundContainer /> {/* This will apply the background */}
      
        <Navbar
                
      
            isUtilitiesOpen={isUtilitiesOpen} orbitControlsEnabled={orbitControlsEnabled} toggleOrbitControls={toggleOrbitControls}
            handleMusicToggle={handleMusicToggle} isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}
            setDaoPopup={setDaoPopup}  daoPopup={daoPopup} setButtonPopup4={setButtonPopup4}  buttonPopup4={buttonPopup4} setCurrentState={setCurrentState} currentState={currentState} 
            isMusicPlaying={isMusicPlaying} isOpen ={isOpen} started={start} toggle={toggle} isFooterVisible = {isFooterVisible}
            setButtonPopup={setButtonPopup} setButtonPopup2={setButtonPopup2} setButtonPopup3={setButtonPopup3} 
            buttonPopup={buttonPopup} buttonPopup2={buttonPopup2} buttonPopup3={buttonPopup3} />
           
        <Sidebar   
        currentState={currentState} setCurrentState={setCurrentState}
            isFooterVisible={isFooterVisible} setIsFooterVisible={setIsFooterVisible} toggle={toggle} 
            setButtonPopup={setButtonPopup} setButtonPopup2={setButtonPopup2} setButtonPopup3={setButtonPopup3}
            buttonPopup={buttonPopup} buttonPopup2={buttonPopup2} buttonPopup3={buttonPopup3} 
            setWhitelistPopup={setWhitelistPopup}  whitelistPopup={whitelistPopup} 
            setDaoPopup={setDaoPopup}  daoPopup={daoPopup}
            trigger4 ={trigger4} setTrigger4 = {setTrigger4}
            trigger5 ={trigger5} setTrigger5= {setTrigger5}
             isDarkMode={isDarkMode}  isOpen={isOpen} />
        <Utilities   
          setDaoPopup={setDaoPopup}
        daoPopup={daoPopup}
            isFooterVisible={isFooterVisible} setIsFooterVisible={setIsFooterVisible} utilitiesPopup={utilitiesPopup}  
            isDarkMode={isDarkMode} isUtilitiesOpen={isUtilitiesOpen} toggleUtilities={toggleUtilities}  setWhitelistPopup={setWhitelistPopup}  />
        <DaoForm  
            daoPopup={daoPopup} 
            isFooterVisible={isFooterVisible} setIsFooterVisible={setIsFooterVisible}  
            isDarkMode={isDarkMode} trigger ={daoPopup} setTrigger = {setDaoPopup} />
  <PopUp buttonPopup={buttonPopup} isDarkMode={isDarkMode} trigger ={buttonPopup} setTrigger = {setButtonPopup} />
  <PopUp3  isDarkMode={isDarkMode} trigger3 ={buttonPopup3} setTrigger3 = {setButtonPopup3} />
  <PopUp2  isDarkMode={isDarkMode} trigger2 ={buttonPopup2} setTrigger2 = {setButtonPopup2} />
  <WhitelistPopUp  isDarkMode={isDarkMode} trigger ={whitelistPopup} setTrigger = {setWhitelistPopup} />


            <Routes>
            
              <Route
                path="/"
                element={
                  <>
                    < >
                  
                      <Suspense fallback={null}>
                      <NetworkScene 
                                      currentState={currentState} setCurrentState={setCurrentState}
                                      style={{ width: '100vw', height: '100vh' }}
                                      trigger4 ={trigger4} setTrigger4 = {setTrigger4}
                                      planSwitch = {planSwitch} setPlanSwitch = {setPlanSwitch}
                                      isUtilitiesOpen={isUtilitiesOpen}
                                      isFooterVisible={isFooterVisible} setIsFooterVisible={setIsFooterVisible} toggleOrbitControls={toggleOrbitControls}
                                      setButtonPopup={setButtonPopup} setButtonPopup2={setButtonPopup2} setButtonPopup3={setButtonPopup3}
                                      buttonPopup={buttonPopup} buttonPopup2={buttonPopup2} buttonPopup3={buttonPopup3}  isDarkMode={isDarkMode}
                                     setOrbitControlsEnabled={setOrbitControlsEnabled} orbitControlsEnabled={orbitControlsEnabled}
                                     setDaoPopup={setDaoPopup} daoPopup={daoPopup} isTextVisible={isTextVisible} setTextVisible={setTextVisible} 
                                     started={start}  toggleUtilities={toggleUtilities}   setDaoProgress={setDaoProgress}  
                                     setModelSwitch = {setModelSwitch} modelSwitch={modelSwitch} roadmapSwitch={roadmapSwitch}rt setRoadmapSwitch={setRoadmapSwitch}
                                     daoProgress={daoProgress}    setWhitelistPopup={setWhitelistPopup}  whitelistPopup={whitelistPopup} />
              
                               </Suspense>
                         
                    </>
                    {!start &&
                    <Preloader
                      handleMusicToggle={handleMusicToggle}
                      started={start}
                      onStarted={() => setStart(true)}
                      setIsModelLoaded={setIsModelLoaded}
                    />
                    }
                  </>
                }
              />
              <>
  
        </><Route path="/Tos" element={
    <>
      <NavbarAll
        currentState={currentState} 
        setCurrentState={setCurrentState}
        setDaoPopup={setDaoPopup}  
        daoPopup={daoPopup}
        isUtilitiesOpen={isUtilitiesOpen}
        orbitControlsEnabled={orbitControlsEnabled}
        toggleOrbitControls={toggleOrbitControls}
        handleMusicToggle={handleMusicToggle}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        isMusicPlaying={isMusicPlaying}
        isOpen={isOpen}
        started={start}
        toggle={toggle}
        isFooterVisible={isFooterVisible}
        setButtonPopup={setButtonPopup}
        setButtonPopup2={setButtonPopup2}
        setButtonPopup3={setButtonPopup3}
        setButtonPopup4={setButtonPopup4}
        buttonPopup4={buttonPopup4}
        buttonPopup={buttonPopup}
        buttonPopup2={buttonPopup2}
        buttonPopup3={buttonPopup3}
      />
      <Tos isDarkMode={isDarkMode} />
    </>
  } />

  <Route path="/PrivacyPolicy" element={
    <>
      <NavbarAll
                 setDaoPopup={setDaoPopup} 
                  daoPopup={daoPopup}
        isUtilitiesOpen={isUtilitiesOpen}
        orbitControlsEnabled={orbitControlsEnabled}
        toggleOrbitControls={toggleOrbitControls}
        handleMusicToggle={handleMusicToggle}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        isMusicPlaying={isMusicPlaying}
        isOpen={isOpen}
        started={start}
        toggle={toggle}
        isFooterVisible={isFooterVisible}
        setButtonPopup={setButtonPopup}
        setButtonPopup2={setButtonPopup2}
        setButtonPopup3={setButtonPopup3}
        setButtonPopup4={setButtonPopup4}
        buttonPopup4={buttonPopup4}
        buttonPopup={buttonPopup}
        buttonPopup2={buttonPopup2}
        buttonPopup3={buttonPopup3}
      />
      <Privacy isDarkMode={isDarkMode} />
    </>
  } />
   


{
              
              <Route path="/Redeem" element={

               <>
                       {
                    /*
                       <RedeemNavbar
                       toggleMusic={toggleMusic} redeemAudio={redeemAudio}
            isUtilitiesOpen={isUtilitiesOpen} orbitControlsEnabled={orbitControlsEnabled} toggleOrbitControls={toggleOrbitControls}
            handleMusicToggle={handleMusicToggle} isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}
            isMusicPlaying={isMusicPlaying} isOpen ={isOpen} started={start} toggle={toggle} isFooterVisible = {isFooterVisible}
            setButtonPopup={setButtonPopup} setButtonPopup2={setButtonPopup2} setButtonPopup3={setButtonPopup3} 
            buttonPopup={buttonPopup} buttonPopup2={buttonPopup2} buttonPopup3={buttonPopup3} />
                <RedeemPage redeemAudio={redeemAudio} isDarkMode={isDarkMode} />
                      */}
                </>
          
                } />}

{
              
              <Route path="/Closet-7zWxY8gH5pTkL1qMvD4rB2sJuNcK3aV" element={

< >
<NavbarAll
        isUtilitiesOpen={isUtilitiesOpen}
        orbitControlsEnabled={orbitControlsEnabled}
        toggleOrbitControls={toggleOrbitControls}
        handleMusicToggle={handleMusicToggle}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        isMusicPlaying={isMusicPlaying}
        isOpen={isOpen}
        started={start}
        toggle={toggle}
        isFooterVisible={isFooterVisible}
        setButtonPopup={setButtonPopup}
        setButtonPopup2={setButtonPopup2}
        setButtonPopup3={setButtonPopup3}
        buttonPopup={buttonPopup}
        buttonPopup2={buttonPopup2}
        buttonPopup3={buttonPopup3}
      />
                  
                  <Suspense fallback={null}>
                  
                  {
                   <CustomCloset 
                                  style={{ width: '100vw', height: '100vh' }}
                                  isFooterVisible={isFooterVisible} setIsFooterVisible={setIsFooterVisible} toggleOrbitControls={toggleOrbitControls}
                                  setButtonPopup={setButtonPopup} setButtonPopup2={setButtonPopup2} setButtonPopup3={setButtonPopup3}
                                  buttonPopup={buttonPopup} buttonPopup2={buttonPopup2} buttonPopup3={buttonPopup3}  isDarkMode={isDarkMode}
                                 setOrbitControlsEnabled={setOrbitControlsEnabled} orbitControlsEnabled={orbitControlsEnabled}
                                 setDaoPopup={setDaoPopup} daoPopup={daoPopup} isTextVisible={isTextVisible} setTextVisible={setTextVisible} 
                                 started={start}  toggleUtilities={toggleUtilities}   setDaoProgress={setDaoProgress}  
                                 setModelSwitch = {setModelSwitch} modelSwitch={modelSwitch} 
                                 daoProgress={daoProgress}    setWhitelistPopup={setWhitelistPopup}  whitelistPopup={whitelistPopup} /> 
                  }
                           </Suspense>
                     
                </>
                
                } />}

            </Routes>
  
      </ThemeProvider>
    </>
  );
}



