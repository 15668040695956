import { motion } from 'framer-motion';
import { Link as LinkR } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';


export const ClosetTop = styled(motion.div)`
    justify-content: center;
    align-items: center;
    display: grid;
    //background: #FFFFFF;
    position: fixed;
    overflow: hidden;
   // padding: 0 50px;
   // overflow: hidden;


   
    //bottom: -50px;
    left: 0;
    right: 0;
    top: 0%;
    background: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#000')};
    height: 100px;
    z-index: 99;
`
 

export const ClosetBottom = styled(motion.div)`
    justify-content: center;
    align-items: center;
    display: grid;
    //background: #FFFFFF;
    position: fixed;
    overflow: hidden;
   // padding: 0 50px;
   // overflow: hidden;

   
    //bottom: -50px;
    left: 0;
    right: 0;
    bottom: 0%;
    background: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#000')};
    height: 100px;
    z-index: 99;
`
export const WebLogo = styled.img`
    height: 100%;
    width: 100%;

    cursor: pointer;
    
  
 
    
    &:hover {
        
    }

    
`;

export const WebLogoCont = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  position: absolute;
  width: 235px;
  height: 40px;
  top: 3%;
  left: 50%;
  z-index: 99999999;
  transform: translateX(-50%);
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }

  @media screen and (max-width: 1024px) {
    transform: translateX(-50%) scale(0.85);
  }

  @media screen and (max-width: 900px) {
    transform: translateX(-50%) scale(0.75);
  }
`;


///
/// Scroll to begin
///
export const colorBg = '#1E2A33'; // A dark blueish background
export const colorOutline = '#FF8C42'; // An orange outline color
export const colorOutlineFade = '#fff'; // A muted teal color for faded outlines


export const widthMouse = '52px';
export const heightMouse = '88px';
export const borderMouse = '6px';

export const posMouse = '8px';
export const posText = '2px';

export const sizeTrackball = '10px';
export const posTrackball = '20px';
export const shrinkTrackball = '0.4';

export const animDuration = '5s';



export const colorSlide = keyframes`
  0% { background-position: 0% 100%; }
  20% { background-position: 0% 0%; }
  21% { background-color: ${colorOutlineFade}; }
  29.99% { 
    background-color: ${colorOutline};
    background-position: 0% 0%;
  }
  30% { 
    background-color: ${colorOutlineFade};
    background-position: 0% 100%;
  }
  50% { background-position: 0% 0%; }
  51% { background-color: ${colorOutlineFade}; }
  59% { 
    background-color: ${colorOutline};
    background-position: 0% 0%;
  }
  60% { 
    background-color: ${colorOutlineFade};
    background-position: 0% 100%;
  }
  80% { background-position: 0% 0%; }
  81% { background-color: ${colorOutlineFade}; }
  90%, 100% { background-color: ${colorOutline}; }
`;

export const trackBallSlide = keyframes`
  0% {
    transform: scale(1) translateY(-${posTrackball});
    opacity: 1;
  }
  6% { 
    opacity: 1;
    transform: scale(0.9) translateX(${posTrackball / 4});
  }
  14% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateX(${posTrackball * 2});
  }
  15%, 19% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateX(-${posTrackball});
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateX(-${posTrackball});
  }
  30% {
    opacity: 1;
    transform: scale(1) translateX(-${posTrackball});
  }
  36% { 
    opacity: 1;
    transform: scale(0.9) translateX(${posTrackball / 4});
  }
  44% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateX(${posTrackball * 2});
  }
  45%, 49% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateX(-${posTrackball});
  }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateX(-${posTrackball});
  }
  60% {
    opacity: 1;
    transform: scale(1) translateX(-${posTrackball});
  }
  66% { 
    opacity: 1;
    transform: scale(0.9) translateX(${posTrackball / 4});
  }
  74% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateX(${posTrackball * 2});
  }
  75%, 79% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateX(-${posTrackball});
  }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateX(-${posTrackball});
  }
`;

export const nudgeMouse = keyframes`
  0% { transform: translateX(0); }
  20% { transform: translateX(${posMouse}); }
  30% { transform: translateX(0); }
  50% { transform: translateX(${posMouse}); }
  60% { transform: translateX(0); }
  80% { transform: translateX(${posMouse}); }
  90% { transform: translateX(0); }
`;

export const nudgeText = keyframes`
  0% { transform: translateX(0); }
  20% { transform: translateX(${posText}); }
  30% { transform: translateX(0); }
  50% { transform: translateX(${posText}); }
  60% { transform: translateX(0); }
  80% { transform: translateX(${posText}); }
  90% { transform: translateX(0); }
`;

export const colorText = keyframes`
  21% { color: ${colorOutlineFade}; }
  30% { color: ${colorOutline}; }
  51% { color: ${colorOutlineFade}; }
  60% { color: ${colorOutline}; }
  81% { color: ${colorOutlineFade}; }
  90% { color: ${colorOutline}; }
`;

const bgGradient = () => `
  background:
    ${colorOutlineFade}
    linear-gradient(
      transparent 0%,
      transparent 50%,
      ${colorOutline} 50%,
      ${colorOutline} 100%
    );
`;


export const BlurBackground = styled.div`
  backdrop-filter: ${props => (props.isMouseVisible ? 'blur(10px)' : 'none')};
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1; // Adjust z-index as needed
`;


export const MouseBody = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
      z-index: 9999999999;
      top: 40%;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%) scale(0.45);

`;

export const Paragraph = styled.p`
  margin-top: 50px;
  font-family: "Cabin", sans-serif;
  letter-spacing: 12px;
  text-indent: 12px;
  font-size: 32px;
  font-weight: 900;
  color: ${colorOutline};
  animation: 
    ${colorText} ${animDuration} ease-out infinite,
    ${nudgeText} ${animDuration} ease-out infinite;
`;

export const Mouse = styled.div`
  ${bgGradient()}
  position: relative;
  will-change: background-position, transform;
  will-change: transform, background-color;
  width: ${widthMouse};
  height: ${heightMouse};
  zoom: 0.7;
  border-radius: 100px;
  background-size: 100% 200%;
  animation: 
    ${colorSlide} ${animDuration} linear infinite,
    ${nudgeMouse} ${animDuration} ease-out infinite;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;
  }
  
  &:before {
    width: calc(${widthMouse} - ${borderMouse});
    height: calc(${heightMouse} - ${borderMouse});
    background-color: ${colorBg};
    border-radius: 100px;
  }
  
  &:after {
    background-color: ${colorOutline};
    width: ${sizeTrackball};
    height: ${sizeTrackball};
    border-radius: 100%;
    animation: ${trackBallSlide} ${animDuration} linear infinite;
  }
`;

export const ScrollEffect = styled.div`
  opacity: ${props => props.opacity};
  transition: opacity 0.3s ease-in-out;
`;

























/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*                                                                                                                                   ///
//*                           Hero ELEMENTS                                                                                           ///   
//*                                                                                                                                   ///
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////







export const HeroContainer = styled.div`
    
    display: flex;
    justify-content: center;
    align-items: center;
   
    //background: #fff;    
    width: 100vw;
    height: 100vh;
    left: 0px;
    border-radius: 0px;

    @media screen and (max-width: 768px) {
        margin-top: 140px;
        height: 700px;
        
         }

`;

export const HeroSection  = styled.section`
    width: 100vw;
    height: 100vh;



`

export const HeroWrapper  = styled.section`
    
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;



`
export const HeroIntro  = styled.section`




`
export const HeroMain = styled.section`

    position: absolute;
    bottom: 168px;
    left: 0;
    color: var(--color-text);


`

export const HeroLast  = styled.section`
    position: absolute;
    top: calc(50% - 120px);
    right: 0;
    color: var(--color-text);


`
export const HeroLogo = styled.img`
    background-repeat: no-repeat;
    background-size: cover;
`
export const HeroName = styled.img`
    background-repeat: no-repeat;
    background-size: cover;
`




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*                                                                                                                                   ///
//*                           Dao ELEMENTS                                                                                           ///   
//*                                                                                                                                   ///
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const MainContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    overflow: hidden;


`


export const DaoContainer = styled.div`
    
  

    @media screen and (max-width: 768px) {
        margin-top: 140px;
        height: 700px;
        
         }

  

`;

export const DaoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 800px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  

`;

export const DaoRow = styled.div`
    display: grid;
    grid-auto-columns: 1fr 1fr;
    gap: 125px;
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr;
        grid-template-areas:  'col1' 'col2';
        justify-content: center;
        //transform: scale(0.7);
        zoom: 0.7;
        
        
    }
 
`;

export const Column1 = styled.div`
  
    width: 525px;
    height: 375px;

    grid-area: col1;
    @media screen and (max-width: 768px) {
        margin-top: 100px;
       display: flex;
       align-items: center;
       justify-content: center;
      
    }

`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    
    grid-area: col2;
    @media screen and (max-width: 768px) {
        
        display: flex;
        align-items: center;
        justify-content: center;
       
     }
    
`;


export const TextWrapper = styled.div`
    max-width: 540;
    padding-top: 0;
    padding-bottom: 60px;
    
  

`;

export const TopLine = styled.p`

    width: 62px;
    height: 13px;
    top: 74.5px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 13px;

    justify-content: flex-start;
    color: #69F490;
`;

export const Heading = styled.h1`
 
    width: 433px;
    height: 88px;
    left: 99px;
    top: -43.5px;

    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 44px;
    /* or 92% */

    display: flex;
    align-items: center;
    //text-align: center;

    color: #376392;

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    
  
    width: 470px;
    height: 140px;
    left: 70px;
    margin-top: 50px;

    font-style: normal;
    font-weight: 400;
    font-size: 16.5px;
    line-height: 28px;
    /* or 170% */

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;

    color: #747C82;
    @media screen and (max-width: 768px) {
     
    }
`;




export const ImgWrap = styled.div`
    width: 625px;
    height: 500px;
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
    
    @media screen and (max-width: 768px) {
        transform: scale(0.65);
        margin: auto;
         }
`;

export const Img1 = styled.img`
    width: 100%;
    height: 100%;
    
    margin: 0 0 10px 0;
    padding-right: 0;
`;

export const DaoButton = styled(LinkR)`
   
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#660099' : '#010606')} ;
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '30px 30px')} ;
    color: ${({dark}) => (dark ? '#010606' : '#fff')} ;
    text-decoration: none;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
  
    background: linear-gradient(180deg, #00FF01 0%, #33CF09 100%);
    border-radius: 180px;
    height: 0px;
    width: 400px;
    margin-top: 75px;
    &:hover {
  
        opacity: calc(.7);
        transition: all 100ms ease-in-out;
}
`

export const BtnWrap = styled.div`
    display:block;
    align-items: center;
    justify-content: flex-start;
  

  margin: auto;

    @media screen and (max-width: 640px) {
        align-items: center;
    
    display:block;
      
    margin: auto;
    }
    
`
