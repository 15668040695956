import { Environment, Lightformer, OrbitControls, PerformanceMonitor, Sparkles, useScroll } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { Selection } from '@react-three/postprocessing';
import { easing } from 'maath';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import { Effects } from './Model';
import W3oDefaultCloset from './W3oUserDefaultCloset';

const innerMaterial = new THREE.MeshStandardMaterial({
  transparent: true,
  opacity: 1,
  color: 'black',
  roughness: 0,
  side: THREE.FrontSide,
  blending: THREE.AdditiveBlending,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  envMapIntensity: 2
});

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;

  @media (max-width: 600px) {
    .model {
      transform: scale(0.5);
    }
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    .model {
      transform: scale(0.8);
    }
  }
`;

export function Scene({  selectedObject, setSelectedObject, hoveredItemDetails, setHoveredItemDetails, isScrolling, cardToggle, isSelected, browseMode, setBrowseMode, hoveredGroup, setHoveredGroup, setCurrentIndex, perfSucks, onPrev, onNext, onClose, currentIndex, orbitControlsEnabled, setIsSelected, mobile3Dcollecion, setMobile3Dcollecion, BLOOM_LAYER, setIsScrolling, setIsMouseVisible, setWhitelistPopup, orbitControlsRef, setIsFooterVisible, isDarkMode, setButtonPopup, buttonPopup, openWhitelistLink, props, scroll }) {
  const { size } = useThree();
  const [scale, setScale] = useState(1);
  const [scale2, setScale2] = useState(1);

  useEffect(() => {
    const updateScale = () => {
      const width = size.width;
      let newScale = 1;

      if (width <= 600) {
        newScale = 0.4;
      } else if (width > 600 && width <= 1200) {
        newScale = 0.71428571;
      }
      setScale2(newScale)
      setScale(newScale);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [size?.width]);

  return (
    <>
      {isDarkMode ? (
        <></>
      ) : (
        <></>
      )}

      {setButtonPopup && (
        <W3oDefaultCloset
          BLOOM_LAYER={BLOOM_LAYER}
          isDarkMode={isDarkMode}
          isScrolling={isScrolling}
          setIsScrolling={setIsScrolling}
          scroll={scroll}
          mobile3Dcollecion={mobile3Dcollecion}
          setMobile3Dcollecion={setMobile3Dcollecion}
          setWhitelistPopup={setWhitelistPopup}
          setIsMouseVisible={setIsMouseVisible}
          orbitControlsRef={orbitControlsRef}
          setIsFooterVisible={setIsFooterVisible}
          openWhitelistLink={openWhitelistLink}
          setButtonPopup={setButtonPopup}
          buttonPopup={buttonPopup}
          scale={scale}
          isSelected={isSelected}
          orbitControlsEnabled={orbitControlsEnabled}
          perfSucks={perfSucks}
          setIsSelected={setIsSelected}
          props={props}
          currentIndex={currentIndex}
          onClose={onClose}
          onNext={onNext}
          onPrev={onPrev}
          setCurrentIndex={setCurrentIndex}
          browseMode={browseMode}
          setBrowseMode={setBrowseMode}
          hoveredGroup={hoveredGroup} 
          setHoveredGroup ={setHoveredGroup}
          cardToggle={cardToggle}
          hoveredItemDetails={hoveredItemDetails} 
                             setHoveredItemDetails={setHoveredItemDetails}
                             selectedObject={selectedObject}
                               setSelectedObject={setSelectedObject}
        />
      )}
    </>
  );
}

export function EnvDesktop({ perfSucks, orbitControlsEnabled, BLOOM_LAYER }) {
  const ref = useRef();

  useFrame((state, delta) => {
    if (!perfSucks && !orbitControlsEnabled) {
      easing.damp3(ref.current.rotation, [Math.PI / 2, 0, state.clock.elapsedTime / 50 + state.pointer.x], 0.2, delta);
      easing.damp3(state.camera.position, [Math.sin(state.pointer.x / 4) * 9, 1.25 + state.pointer.y, Math.cos(state.pointer.x / 4) * 9], 0.5, delta);
      state.camera.lookAt(0, 0, 0);
      state.camera.layers.enable(BLOOM_LAYER);
    }
  });

  return (
    <Environment background={false} files="https://w3omedia.s3.us-east-2.amazonaws.com/w3o+hdr/metro_noord_1k.hdr" path="" preset={null} frames={perfSucks ? 1 : Infinity} resolution={256} blur={0}>
      <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
      <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
      <group rotation={[Math.PI / 2, 1, 0]}>
        {[2, -2, 2, -4, 2, -5, 2, -9].map((x, i) => (
          <Lightformer key={i} intensity={10} rotation={[Math.PI / 4, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
        ))}
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
      </group>
      <group ref={ref}>
        <Lightformer intensity={1} form="ring" color="ADD7F2" rotation-y={Math.PI / 2} position={[-5, 2, -1]} scale={[0, 0, 0]} />
      </group>
    </Environment>
  );
}

export default function ClosetModel({
  whitelistPopup,
  browseMode,
  setBrowseMode,
  setCurrentIndex,
  onPrev,
  onNext,
  onClose,
  currentIndex,
  mobile3Dcollecion,
  isSelected,
  setIsSelected,
  setMobile3Dcollecion,
  setWhitelistPopup,
  isScrolling,
  setIsScrolling,
  isMouseVisible,
  setIsMouseVisible,
  orbitControlsRef,
  setIsFooterVisible,
  isDarkMode,
  orbitControlsEnabled,
  setButtonPopup,
  buttonPopup,
  started,
  toggleOrbitControls,
  modelSwitch,
  setModelSwitch,
  cardToggle,
  hoveredGroup, setHoveredGroup,
  props, hoveredItemDetails, setHoveredItemDetails,   selectedObject, setSelectedObject
}) {
  const { camera, gl } = useThree();
  const BLOOM_LAYER = 1;
  const [showAbout, setShowAbout] = useState(false);

  const handleCloseAbout = () => setShowAbout(false);
  const ref = useRef();
  const controlsRef = useRef();
  const [perfSucks, degrade] = useState(false);
  const [position, setPosition] = useState([0, -1.5, 0]);
  const scroll = useScroll();
  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width <= 600) {
        setPosition([0, -0.25, 0]);
      } else {
        setPosition([0, -1, 0]);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openWhitelistLink = () => {
    setButtonPopup(true);
  };

  const controls = useThree((s) => s.controls);
  useEffect(() => {
    if (controls) {
      const handler = (event) => {
        setIsMouseVisible(false);
      };

      controls.addEventListener('start', handler);
      return () => controls.removeEventListener('start', handler);
    }
  });

  return (
    <>
      {orbitControlsEnabled && <OrbitControls makeDefault args={[camera, gl.domElement]} enableZoom enableRotate minDistance={5} maxDistance={25} zoomSpeed={0.6} panSpeed={0.5} rotateSpeed={0.4} />}
      <PerformanceMonitor onDecline={() => degrade(true)} />
      <color attach="background" args={['#000']} />
      <group position={position} rotation={[0, -0.75, 0]}>
        <pointLight position={[-0.5, -1.75, 0]} intensity={orbitControlsEnabled ? 3.5 : 1.5} />
        <Sparkles size={2} color={isDarkMode ? '#fff' : '#cc5500'} scale={[10, 10, 10]} />
        <Selection>
          <Effects />
          <Scene
            BLOOM_LAYER={BLOOM_LAYER}
            scroll={scroll}
            isScrolling={isScrolling}
            setIsScrolling={setIsScrolling}
            setWhitelistPopup={setWhitelistPopup}
            setIsMouseVisible={setIsMouseVisible}
            orbitControlsRef={orbitControlsRef}
            orbitControlsEnabled={orbitControlsEnabled}
            perfSucks={perfSucks}
            setIsFooterVisible={setIsFooterVisible}
            openWhitelistLink={openWhitelistLink}
            setButtonPopup={setButtonPopup}
            buttonPopup={buttonPopup}
            isDarkMode={isDarkMode}
            props={props}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            mobile3Dcollecion={mobile3Dcollecion}
            setMobile3Dcollecion={setMobile3Dcollecion}
            currentIndex={currentIndex}
            onClose={onClose}
            onNext={onNext}
            onPrev={onPrev}
            setCurrentIndex={setCurrentIndex}
            browseMode={browseMode}
            setBrowseMode={setBrowseMode}
            hoveredGroup={hoveredGroup} 
            setHoveredGroup={setHoveredGroup}
            cardToggle={cardToggle}
            hoveredItemDetails={hoveredItemDetails} 
            setHoveredItemDetails={setHoveredItemDetails}
            selectedObject={selectedObject}
                               setSelectedObject={setSelectedObject}
          />
        </Selection>
        <mesh scale={[0.95, 1, 0.95]} material={innerMaterial} />
        <mesh material={innerMaterial} />
      </group>
    </>
  );
}