import { motion } from 'framer-motion';
import { BiCloset } from 'react-icons/bi';
import { FaHandshake } from "react-icons/fa6";
import { GiWallet } from 'react-icons/gi';
import { PiCoatHangerFill } from "react-icons/pi";
import { RiSearchEyeLine } from 'react-icons/ri';
import { Link as LinkR } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ComingSoon = styled.div`
  width: 140px;
  height: 60px;
  position: absolute;
  
  top: 50%;
  transform: translate(-5%, 0%) scale(0.75);
  border-style: none;
  object-fit: cover; /* Ensure the image fits the container without overflowing */
  cursor: pointer;
  pointer-events: none;
  border:0;
  padding: 5px;
  pointer-events: none;

  @media screen and (max-width: 1200px) {
  
    transform: scale(0.65);
  }
  @media screen and (max-width: 960px) {

    transform: scale(0.55);
  }
  @media screen and (max-width: 768px) {
   
    transform: scale(0.45);
  }
  &:hover {
  }
`;

export const ComingSoonCont = styled.div`




//top: 15%;
pointer-events: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 999999999;
  text-decoration: none;
  &:hover {
    box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }
`;

export const LogoName = styled(motion.div)`
  display: flex;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
  float: left;
  border-style: none;
  position: absolute;
  border:0;
  pointer-events: none;
  z-index: 2;
  left: 0;
  transform-origin: right center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);

`;

export const LogoName2 = styled(motion.div)`
  display: flex;
  align-items: center;
  -webkit-perspective:700px;
  perspective: 700px;
  border-style: none;
  left: 43%;
  transform:  translate(-50%, -50%);
  pointer-events: none;
  float: left;
  position: relative;


  z-index: 3;
  //left: 30px;
  transform-origin: left center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);

`;
export const PopUpText = styled.div`

  border-style: none;
  cursor: pointer;

  border-style: none;

`;

export const PopUpText2 = styled(motion.div)`
text-align: center;
justify-content: center;
align-items: center;
//position: absolute;
font-size: 1.75rem;
font-weight: 500;
margin-top: 7.5%;
//left: 50%;
//transform: translate(-50%, 50%);
  border-style: none;
  cursor: pointer;
  letter-spacing: -2px;
  border-style: none;
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
`;

export const PopUpBox = styled(motion.div)` 
  position: relative;
  
  margin-top: 25%;
  border-style: none;

  //visibility: hidden;

  //background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

    //color: #fff;
   // background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;
    height: 75px;
    padding: 5px 5px;
    border-radius: 20px;
    z-index: 9999999999999;
    white-space: nowrap;


  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 45%;
    //transform: translateX(-50%);
    //top: -10px;
    top: -20px;
    position: absolute;
    white-space: nowrap;
    z-index: 9999999999999;
    border: 10px solid;
    background: initial;
   // transform: rotate(-225deg);
   transform: rotate(0deg);


 
   border-color: ${({ isDarkMode }) => (isDarkMode ? 'transparent transparent rgba(250, 250, 250, 0.8)' : 'transparent transparent rgba(0, 0, 0, 0.8)')};

  }

  @media screen and (max-width: 768px) {
   // zoom: 0.5;
   opacity: 0;
}

`;

export const PopUpCard = styled.div`
  position: relative;

  z-index: 9999999999999;
`;


export const JoinTeam = styled(motion.a)`
top: 2.5%;
right: 17%;
transform: scale(0.85);
position: fixed;
   & :hover + ${PopUpBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
  &:hover  {
        transition: all 0.2s ease-in-out;
        backdrop-filter: blur(5px);
        opacity: 0.9;
  }


      
`;

export const Wallet = styled(motion.a)`

top: 2.5%;
right: 3.5%;
transform: scale(0.85);
position: fixed;

   & :hover + ${PopUpBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }

  &:hover  {
        transition: all 0.2s ease-in-out;
        backdrop-filter: blur(5px);
        opacity: 0.9;
  }

      
`;

export const ModelContainer = styled.div`
	position: fixed !important;
	top: 0%;

	right: 22.5%;
	width: 12.5vw !important;
	height: 17vh !important;
	z-index: 99999999;
	overflow: scroll;
  @media screen and (max-width: 768px) {
   top: -7.5px;
    right: 18%;
    //width: 10vw !important;
   zoom: 1;
}
  
  `
export const Nav = styled(motion.nav)`
z-index: 999999;
height: 120px;
width: 100vw;
top: 3%;
display: grid;
grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust the minmax values as needed */
grid-gap: 20px; /* Adjust the gap between grid items as needed */
position: fixed;
overflow: hidden;

margin-bottom: 120px;
pointer-events: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? 'none' : 'initial')};
display: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? 'none' : 'initial')};

@media screen and (max-width: 960px) {
  transition: 0.8s all ease;
}

@media screen and (max-width: 1024px) {
  height: auto; /* Adjustx the height for smaller screens */
  grid-template-columns: 1fr; /* On smaller screens, make it a single column */
  grid-gap: 10px; /* Adjust the gap for smaller screens */
}
`;

export const Nav2 = styled(motion.nav)`
z-index: 99999999;
height: 120px;
width: 100vw;
top: 3%;
display: grid;
grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust the minmax values as needed */
grid-gap: 20px; /* Adjust the gap between grid items as needed */
position: fixed;
overflow: hidden;
zoom: 0.85;
margin-bottom: 120px;
pointer-events: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? 'none' : 'initial')};

@media screen and (max-width: 960px) {
  transition: 0.8s all ease;
}

@media screen and (max-width: 1024px) {
  height: auto; /* Adjustx the height for smaller screens */
  grid-template-columns: 1fr; /* On smaller screens, make it a single column */
  grid-gap: 10px; /* Adjust the gap for smaller screens */
}
`;





export const NavbarRow = styled.div`
 

`;
export const NavbarWrapper = styled.div`
  
    
`


export const NavbarSwitchContainer = styled.div`
    text-decoration: none;
    cursor: pointer;
    position: relative;
    margin-left: 5%;
    display: flex;
    margin-top: -3px;
    z-index: 999;
    @media screen and (max-width: 768px) {
       display: none;
    }
    
`
export const NavbarSwitchSpan = styled.span`
 
    margin-top: 7.5px;
    margin-right: 5px;

`
export const NavbarCheckbox = styled.div`
 
 

`


export const NavLogo = styled.img`
    height: 100%;
    width: 100%;
  
    
    cursor: pointer;
    
    
    &:hover {
        
    }

    
`;

export const NavLogoText = styled.text`

   position: absolute;

   white-space: nowrap;
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 4px;
   letter-spacing: -1px;
   transform: translateY(10px) translateX(-50%) matrix(0.8, 0, 0, 1, 0, 0);
   left: 50%;
   word-spacing: 35px;
    /* identical to box height, or 150% */

    

`



export const MobileIcon = styled.div`
 
  
        height: 65px;
        width: 65px;
        //margin-top: 25px;
        right: 12.5%;
        margin-left: 50px;
        font-size: 24px;
        cursor: pointer;
        border-radius: 100%;
        //margin-top: -10px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
        z-index: 999999999999999999;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;
        justify-content: center;
        color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
        background: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
        //transform: translateY(-5px);
    &:hover {
        color: #fff;
        
        background: linear-gradient(180deg, #000000 0%, #1B1B1B 50%, #2A1E36 100%);
        border-radius: 50px;
        text-decoration: none;
        outline: none;
        border: none;
   
        //border: 2px solid #69F490;
        opacity: calc(.8);
        transition: all 0.2s ease-in-out;
       // transition: all 280ms ease-in-out;
       // border-radius: 80px;
        //width: 130px;
    
    }
    &:active {
            opacity: 0.9;
    }
    @media screen and (max-width: 768px) {
      right: 15%;
        margin-left: 50px;
        transform: translateX(-5px) scale(0.8);
    }


    `;

// Individual Strip
export const Strip = styled.div`
  height: 2px;
  border-radius: 2px;
  background: rgb(56, 56, 56);
  margin: 5px;
  transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 30px;

`;

//SidebarHide
export const SidebarHide = styled.div`
  // display: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? 'none' : 'initial')};
   @media screen and (min-width: 768px) {
    display: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? 'none' : 'initial')};
    display: ${({ isOpen }) => (isOpen ? 'none' : 'initial')};
  }
`





//Burger Strip 2

export const BurgerContainer = styled.div`
    width: 50px;
    height: 50px;
    
  
   // border-radius: 360px;
   border-radius: 360px;
    position: fixed;
    top: 3%;
    background: white;
    background: ${({ isDarkMode, orbitControlsEnabled }) => 
                 orbitControlsEnabled ? '#fff' : 
                 (isDarkMode ? '#fff' : '#000')};
    box-shadow: rgba(136, 165, 191, 0.48) 3px 1px 8px 0px, rgba(255, 255, 255, 0.8) -3px -1px 8px 0px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    display: flex;
    position: fixed;
    opacity: 1;
    left: 0.5%;
    top: 2.5%;
    z-index: 999999999;

    //pointer-events: ${({  isUtilitiesOpen, isMobile }) => ((!isMobile) || isUtilitiesOpen ? 'none' : 'initial')};

    opacity: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? '0' : '1')};
    transform: scale(0.85);
  ${props =>
    props.isHovered &&
    css`
     // background: rgb(56 56 56);
    `}

    @media screen and (max-width: 768px) {
    //  top: 5%;
      transform: scale(0.85);
      right: 1%;
      left: unset;
      //right: 5%;
  }
`;

export const BurgerStrip = styled.div`
  transition: all 0.5s ease;
  transform: scale(0.8);

 // zoom: 0.8;
  div {
    height: 1.5px;
    border-radius: 2px;
    background: ${({ isDarkMode, orbitControlsEnabled }) => 
                 orbitControlsEnabled ? '#000' : 
                 (isDarkMode ? '#000' : '#fff')};
    margin: 6px;
    transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 40px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'initial')};
  }

  /* BURGER 1 */
  div:first-child {
    width: 32px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg) translateY(1px) translateX(5px)' : 'initial')};
  }
  div:last-child {
    width: 28px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg) translateY(-11px) translateX(8px) scale(0.8)' : 'initial')};
  }


`;


export const NavMenu = styled.ul`
   
    position: fixed;
    right: 90.5%;
    display: flex;
    top: 5%;


`;



export const NavItem = styled.p`
   

   margin-left: 25px;
    /* or 429% */
   // margin-left: -60px;
    display: flex;
    align-items: center;
    letter-spacing: 0px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  

    
     
`;

export const NavLinksContainer = styled.div`
 
    display: flex;
    right: 2.5%;
  position: fixed ;

 


`









export const TextSpan = styled.text`

    //margin-top: -10px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

    padding: 0px 5px 0px 0px;
 

`

export const TextSpan2 = styled.span`
    color: #fff;
  //  transform: matrix(1.2, 0, 0, 1, 0, 0);
    padding: 10px 30px 30px 0px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

`
export const ButtonText2 = styled.div`
text-align: center;
   position: relative; /* Added position: relative */
   margin: 0 auto;
    padding: 0;
    display: inline-block;
    line-height: 60px;
    top: -5px;
    transform: matrix(1, 0, 0, .7, 0, 0);

`


export const WalletIcon = styled(GiWallet)`
    //background-color: #fff;
   
    color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
    //border-radius: 100%;
   
    transform: translate(2.5px, 10px) matrix(1, 0, 0, 1.2, 0, 0);
    font-size: 32px; 
    &:hover {
        //background-color: #000;
        //background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      
    }

`;

export const NavbarRightIconWrap = styled.div`

    display: flex;
    position: fixed;
    display: flex;
    opacity: 1;
    right: 5%;
    top: 5%;




    @media screen and (max-width: 768px) {
       // margin-right: 20%;
        //transform: translateX(100px);
       display: none;
       //opacity: 0;
        
    }


`
export const ExploreCloset = styled(PiCoatHangerFill)`
 
    color: #000;
    border-radius: 50%;
    padding: 4px;
/* Adjust the padding as per your requirements */
    margin-top: 0px;

    transform: translateY(0px) matrix(1, 0, 0, 1, 0, 0);
    font-size: 20px; 

    @media screen and (max-width: 768px) {
        //margin-left: 0px;
        background: none;
    }
`;


export const JoinTeamIcon = styled(FaHandshake)`
    //background-color: #fff;
    z-index: 999999999999999;
    color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
    //border-radius: 100%;

    transform: translateY(10px)  matrix(1, 0, 0, 1.2, 0, 0);
    font-size: 32px; 
    &:hover {
        ///background-color: #000;
       // color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};
        //background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

   
    }


`;



export const DoubleButtons = styled.div`
   cursor: pointer;

//background-color: #fff; /* Add a solid background color with transparency */
top: 2.5%;
width: 325px;
height: 50px;
right: 10%;
border-radius: 90px;
box-sizing: border-box;
//box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
background-color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};

cursor: pointer;
outline: none;
border: none;
transition: all 0.2s ease-in-out;
z-index: 99999;
white-space: nowrap;

/* text edit */
font-family: 'Inter';
font-style: normal;
font-weight: 900;
font-size: 18px;
line-height: 28px;
letter-spacing: -1px;
text-transform: uppercase;
/* or 81% */

//color: #000;



position: fixed;
text-align: center;
text-decoration: none;
transition: all 0.3s;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;

  &:hover {
    .or {
      background: white;
      color: #b2976a;
      border-color: #b2976a;
    }
  }

  a {

    width: 250px;


    &:first-child {
      
      border-right: 4px solid #fff;
      //margin-left: 20px;
    }

    &:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }



  &:hover  {
    transition: all 0.2s ease-in-out;
    //backdrop-filter: blur(5px);
   // box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);


    ${WalletIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }
    ${TextSpan2}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     //filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
  }
}

  .or {

  }
`;





export const ClosetWalletButton = styled.div`
   cursor: pointer;

    //background-color: #fff; /* Add a solid background color with transparency */
    top: 2.5%;
    width: 325px;
    height: 50px;
    right: 7.5%;
    cursor: pointer;
    display: inline-block;
  
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */

    color: #000;



  position: fixed;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
overflow: hidden;
    background-color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};




  a {
    

    &:first-child {
        height: 60px;
       // border-radius: 90px;
        width: 285px;
        overflow: hidden;
        border-right: ${({ isDarkMode }) => (isDarkMode ? ` 0.15px solid #000` : ` 0.15px solid #fff`)};
        &:hover  {
//transition: all 0.2s ease-in-out;
background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)')};




${TextSpan2}{
  /* styles for the container when button is hovered */
 // background-color: #000;
 color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  //zoom: 1.02;
 //filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

}



}

       // width: 75px;
  //margin-left: 20px;
    }

    &:nth-child(2) {
      border-top-right-radius: 90px;
    border-bottom-right-radius: 90px;
    width: 75px;
    overflow: hidden;
    height: 100%;
    &:hover  {
//transition: all 0.2s ease-in-out;
background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)')};

${JoinTeamIcon}{
  /* styles for the container when button is hovered */
 // background-color: #000;
 color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  //zoom: 1.02;
 //filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

}





}

    }


&:before {
      /*
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
    */
}



&:hover  {
//transition: all 0.2s ease-in-out;
background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)')};

${TextSpan}{
  /* styles for the container when button is hovered */
 // background-color: #000;
 color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  //zoom: 1.02;
 //filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

}

//backdrop-filter: blur(5px);
// box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
//transform: scale(1.05);
//color: ${({ dark }) => (dark ? '#fff' : '#fff')};


${WalletIcon} {
  /* styles for the container when button is hovered */
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};

     // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
     box-shadow: drop-shadow(2px 2px 2px white);
      //filter: drop-shadow(2px 2px 2px white);
}

}
}


  @media screen and (max-width: 1024px) {
    display: none;
    a {
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};


    ${WalletIcon} {
      /* styles for the container when button is hovered */
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
  }
  }
  }



`;

export const IconsContainer = styled.div`
  display: flex;
position: relative;
top: 50%;
transform: translateY(-50%);

  //margin-left: 20px; /* Adjust the margin as needed */
`;

export const Separator = styled.div`
  width: 2px; /* Width of the line separator */
  height: 50px; /* Height of the line separator */
  background-color: #fff; /* Color of the line separator */
right: 18%;
  margin: 0 0px; /* Adjust the margin as needed */
  z-index: 99999999999999999999;
  position: absolute;
`;
export const ClosetIcon = styled(BiCloset)`
    background-color: #fff;
    border-radius: 100%;
    padding: 8px; /* Adjust the padding as per your requirements */
    margin-top: 5px;
    margin-left: 20px;
    transform: translateY(10px) matrix(1, 0, 0, 1.2, 0, 0);
    //font-size: 30px; 
    border-radius: 50%;
  
    &:hover {
        background-color: #000;
        filter: drop-shadow(0 0 10px white);
    }
    @media screen and (max-width: 870px) {
        
        background: none;
    }
`;

export const NavLinks = styled.div`
    
    cursor: pointer;

    //background-color: #fff; /* Add a solid background color with transparency */

    width: 225px;
    height: 50px;

    cursor: pointer;
    display: inline-block;

    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 16.5px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
    /* or 81% */

    color: #000;


    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    overflow: hidden;
    background-color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};


    

    &:hover  {
        transition: all 0.2s ease-in-out;
       // backdrop-filter: blur(5px);

    ${JoinTeamIcon} {
      /* styles for the container when button is hovered
     // background-color: #000;
     color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};
      border-radius: 15px;
      background-color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
      
      */
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
      filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};

    }
    ${WalletIcon} {
      /* styles for the container when button is hovered

      color: ${({ isDarkMode }) => (isDarkMode ? `#fff` : `#000`)};
border-radius: 15px;
      background-color: ${({ isDarkMode }) => (isDarkMode ? `#000` : `#fff`)};
 */
      //background-color: #000;
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
      filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};


    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     // filter: drop-shadow(0px 0px 10px black);
     box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
 filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};


    }

  }
    &:active {
            opacity: 0.9;
    }
    
    @media screen and (max-width: 1200px) {
      width: 0;
      height: 0;
      position: fixed;
      display: flex;
      opacity: 1;
      top: 50%;
      //right: 0%;
      z-index: 99999999999999;
      font-size: 2.5rem;

      //right: 80%;
      border-radius: 0px;
      box-sizing: border-box;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

      backdrop-filter: blur(0);
      width: 100%;
      font-size: 2.5rem;
      align-items: center;
      justify-content: center;
      background-color: none;
      
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    &:hover  {
    transition: all 0.2s ease-in-out;
    //backdrop-filter: blur(5px);


    ${JoinTeamIcon} {
      /* styles for the container when button is hovered */
     
    
    }
    ${WalletIcon} {
      /* styles for the container when button is hovered */
      color: #000;
      background: none;
      background-color: none;
      //color: none;
      filter: drop-shadow(0 0 0 white);

    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }


  }
    }

    @media screen and (max-width: 1150px) {
        //transform: scale(0.6) translateX(0px);
    }
`;

export const NavLogoTextWrap = styled.text`

   
    margin-top: 65px;
   white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 10.5px;
    line-height: 4px;
    letter-spacing: -1px;
    transform: translateY(5px) matrix(0.8, 0, 0, 1, 0, 0);
   
    /* identical to box height, or 150% */

    

`
export const NavLogoCont = styled(LinkR)`
   
    //display: flex;
    transition: all 0.5s ease;
    opacity: ${({ orbitControlsEnabled }) => (orbitControlsEnabled ? 0 : 1)};
    position: fixed;
    width: 235px;
    height: 40px;
    right: 0;
    top: 3%;
    left: 3.5%;
    //transform: translateX(-50%);
    //margin-left: auto;
    //margin-right: auto;
    //margin: 0 auto;
 
    text-decoration: none;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    //opacity: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? '0' : '1')};
    //filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: ${({ isUtilitiesOpen, orbitControlsEnabled }) => (isUtilitiesOpen || orbitControlsEnabled ? 'none' : 'initial')};
    transform: scale(0.85);
    z-index: 999999999;
    &:hover  {
        transition: all 0.2s ease-in-out;
       // backdrop-filter: blur(5px);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

    ${ExploreCloset} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }

  }

  @media screen and (max-width: 1024px)  {
  
   // transform: translateX(-50%);
      //transform: translate(-50%, -50%) scale(0.75);
      //margin-top: 7.5%;
     // margin-top: 35px;
      //display: none;
  }

  @media screen and (max-width: 900px)  {
      
      //transform: translate(-50%, -50%) scale(0.55);
      //margin-top: 7.5%;
     // margin-top: 35px;
      //display: none;
  }

    @media screen and (max-width: 1024px)  {
      
        //top: 5%;
        //zoom: 1;
        left: 50%;
        transform: translateX(-50%) scale(.85);
        //margin-top: 7.5%;
       // margin-top: 35px;
        //display: none;
    }
`

export const NavIcon = styled.img`
  width: 55px;
  height: 55px;
  line-height: 55px;
  left: -85px;
  position: fixed;
  zoom: 1;
  margin-top: 0%;
    @media screen and (max-width: 870px) {
   
      margin-top: 5%;
    }
`;


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*                                                                                                                                   ///
//*                           Navbar Switch ELEMENTS                                                                                  ///   
//*                                                                                                                                   ///
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



// Continue defining other styled components for the remaining classes...


// Export the constants
export const Switch = styled.div`
  position: relative;
  display: block;
  width: 100px;
  height: 40px;
  padding: 3px;
  margin: 0 10px 0px 0;
  border-radius: 90px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  box-sizing: content-box;

  ${({ flat }) =>
    flat &&
    css`
      padding: 0;
      background: #FFF;
      background-image: none;

      .switch-label {
        background: #FFF;
        border: solid 2px #eceeef;
        box-shadow: none;

        &:after {
          color: #0088cc;
        }
      }

      .switch-handle {
        top: 6px;
        left: 6px;
        background: #dadada;
        width: 22px;
        height: 22px;
        box-shadow: none;

        &:before {
          background: #eceeef;
        }
      }

      .switch-input:checked ~ .switch-label {
        background: #ADD7F2;
      }

      .switch-input:checked ~ .switch-handle {
        left: 72px;
        background: #0088cc;
        box-shadow: none;
      }
    `}
`;

export const SwitchInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
`;

export const SwitchLabel = styled.label`
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  background: ${({ isDarkMode }) => (isDarkMode ? '#eceeef' : '#000')};
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  box-sizing: content-box;

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    line-height: 1;
    transition: inherit;
    box-sizing: content-box;
  }

  &:before {
    content: attr(data-off);
    right: 11px;
    color: #aaaaaa;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  }

  &:after {
    content: attr(data-on);
    left: 11px;
    color: #FFFFFF;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }

  .switch-input:checked ~ & {
    background: #E1B42B;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
  }

  .switch-input:checked ~ &:before {
    opacity: 0;
  }

  .switch-input:checked ~ &:after {
    opacity: 1;
  }
`;

export const SwitchHandle = styled.span`
  position: absolute;
  margin-top: -1.5px;
  margin-left: -2px;
  width: 0px;
  height: 0px;
  border-radius: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 70%;
    left: 50%;
    margin: -6px 0 0 -6px;
    border-radius: 6px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  }

  .switch-input:checked ~ & {
    left: 74px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
  }
`;

// Styled-components for the ToggleBar and related components
export const ToggleBar = styled.div`
  position: fixed;
  display: flex;
  opacity: 1;
  right: 25%;
  top: 4%;
  z-index: 99999999999999;
  zoom: 0.8;

  @media screen and (max-width: 768px) {
    left: 5%;
  }
`;

export const ToggleButton = styled.div`
  cursor: pointer;
  position: relative;
  width: 56px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pink);
  border-radius: 999px;
  margin: 0px 16px;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const ToggleCircle = styled.div`
  position: absolute;
  left: 6px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  transition: all 0.2s ease-in-out;

  ${ToggleButton}:hover & {
    //transform: scale(0.9);
  }
`;

export const Slide = styled.div`
  left: 31px;
`;


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*                                                                                                                                   ///
//*                           Navbar Music / Light ELEMENTS                                                                                  ///   
//*                                                                                                                                   ///
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const SunWrapper = styled.div`
color: #f39c12;

margin-top: 3px;
  &:hover {
    border-radius: 360px;
     zoom: 1.02;
     filter: drop-shadow(0 0 5px yellow);
    }
    @media screen and (max-width: 768px) {
 

    } 
`;

export const MoonWrapper = styled.div`

  margin-top: 3px;
  color: #f1c40f;
  &:hover {
     zoom: 1.02;
     border-radius: 360px;
     filter: drop-shadow(0 0 5px yellow);
    }
    @media screen and (max-width: 768px) {
 

    } 
`;



//Lighting Checkbox

export const Checkbox = styled.input`

  opacity: 0;
  position: absolute;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  
`;

export const CheckboxLabel = styled.label`
  background-color: #000;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
 // background-color: #111;
  width: 55px;
  height: 22px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MoonIcon = styled.i`

  color: #f1c40f;
`;

export const SunIcon = styled.i`
  color: #f39c12;
`;

export const Ball = styled.div`
  background-color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 9999;
  left: 2px;
  top: 1px;
  border-radius: 45%;
  transition: transform 0.2s linear;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
`;

export const LightingWrap = styled(motion.div)`
    position: fixed;
    display: grid;
    opacity: 1;
    top: 3%;


    right: 0.5%;
    cursor: pointer;
    height: 50px;

 
  //  ${Checkbox}:checked + ${CheckboxLabel} ${Ball} {
    //transform: translateX(30px);
  //}

  width: 50px;
    height: 50px;
   // border-radius: 360px;
   border-radius: 360px;
    position: fixed;
    background: ${({ isDarkMode, orbitControlsEnabled }) => 
                 orbitControlsEnabled ? '#fff' : 
                 (isDarkMode ? '#fff' : '#000')};
    //box-shadow: rgba(136, 165, 191, 0.48) 3px 1px 8px 0px, rgba(255, 255, 255, 0.8) -3px -1px 8px 0px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    display: flex;
    position: fixed;
    opacity: 1;
   font-size: 28px;
    top: 2.5%;
    z-index: 9999999;
    pointer-events: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? 'none' : 'initial')};
    opacity: ${({ isUtilitiesOpen }) => (isUtilitiesOpen ? '0' : '1')};
    transform: scale(0.85);
  &:hover {
     //zoom: 1.02;
       filter: drop-shadow(0 0 5px white);
     ${SunWrapper} {
      /* styles for the container when button is hovered */
        filter: drop-shadow(0 0 5px yellow);

    }
    ${MoonWrapper}{
      /* styles for the container when button is hovered */
     // background-color: #000;
       filter: drop-shadow(0 0 5px yellow);

    }
    }
    @media screen and (max-width: 768px) {
    //  top: 5%;
    transform: scale(0.85);
      left: 1%;
      right: unset;
      //right: 5%;
  }
`

export const DarkLabel = styled.span`
  font-size: 12px; // Adjust the font size as needed
  margin-right: 5px; // Add spacing between the label and the toggle switch

`;

export const LightLabel = styled.span`
  font-size: 12px; // Adjust the font size as needed
  margin-left: 5px; // Add spacing between the label and the toggle switch

`;

export const ToggleLabels = styled.div`
 color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  display: flex;
  align-items: center;
`;


export const InspectModel = styled(RiSearchEyeLine)`
   
   opacity: 1;
   z-index: 99999999999999;
   font-size: 40px;


  &:hover {
     zoom: 1.02;
     filter: drop-shadow(0 0 5px yellow);
    }
    @media screen and (max-width: 768px) {
 

    } 
`
export const InspectModelWrap = styled.div`
     position: fixed;
    display: flex;
    opacity: 1;
    top: 5%;
    left: 27.5%;
    cursor: pointer;
  &:hover {
     zoom: 1.02;
     filter: drop-shadow(0 0 5px white);
    }
    @media screen and (max-width: 768px) {
      // display: none;
      //zoom: 0.85;
        top: 5%;
        left: 22.5%;
        transform: translateX(-50%) scale(0.65);

    }
`


export const AudioPlayer = styled.audio`
  display: none;
`;

/*

*********************************  NAVBAR w/Music ICON - NavMenu Update ********************************
export const NavMenu = styled.ul`
   
   position: absolute;
    margin-right: 90.5%;
    display: flex;
    margin-top: 30px;      
`;

export const SunWrapper = styled.div`
     position: fixed;
   display: flex;
   opacity: 1;
   left: 10%;
   top: 5%;
   z-index: 99999999999999;
   font-size: 2.5rem;

   
  left: 25%;
  cursor: pointer;
  &:hover {
     zoom: 1.02;
     filter: drop-shadow(0 0 5px yellow);
    }
    @media screen and (max-width: 870px) {
        left: 50%;
        transform: translateX(-50%);

    } 
`;

export const MoonWrapper = styled.div`
     position: fixed;
   display: flex;
   opacity: 1;
   left: 10%;
   top: 5%;
   z-index: 99999999999999;
   font-size: 2.5rem;

  left: 25%;
  cursor: pointer;
  &:hover {
     zoom: 1.02;
     filter: drop-shadow(0 0 5px white);
    }
    @media screen and (max-width: 870px) {
        left: 50%;
        transform: translateX(-50%);

    } 
`;


export const LightbulbNight = styled(HiLightBulb)`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
        }   
`;

export const InspectModel = styled(RiSearchEyeLine)`
     position: fixed;
   display: flex;
   opacity: 1;
   top: 5%;
   z-index: 99999999999999;
   font-size: 2.5rem;

  left: 32.5%;
  cursor: pointer;
  &:hover {
     zoom: 1.02;
     filter: drop-shadow(0 0 5px white);
    }
    @media screen and (max-width: 870px) {
        left: 62.5%;
        //transform: translateX(-50%);

    }
`

///
///
///

// Define the keyframes animation
export const soundAnimation = keyframes`
    0% {
        opacity: 0.35;
        height: 3px;
    }
    100% {
        opacity: 1;
        height: 28px;
    }
`;

// Styled components
export const BarsContainer = styled.div`
    height: 20px;
    left: 17.5%;
    position: fixed;

    width: 40px;
    text-decoration: none;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
   

    // Apply animation if music is playing
    ${({ isMusicPlaying }) =>
        isMusicPlaying &&
        css`
            & > div {
                animation: ${null} 0ms -800ms linear infinite alternate;
            }
        `}

    &:hover {
        filter: drop-shadow(0 0 10px white);
    }
       
    @media screen and (max-width: 870px) {
        left: 30%;
        //transform: translateX(-50%);

    }
`;

export const Bar = styled.div`
  background: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  bottom: 1px;
  height: 3px;
  position: absolute;
  width: 3px;
  animation: ${soundAnimation} 0ms -800ms linear infinite alternate;
`;

export const AudioPlayer = styled.audio`
  display: none;
`;
*/

///
///
///

export const HeaderSearch = styled.div`
position: relative;
top: 5%;
  left: 27.5%;
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 150px;
  margin: 0 10px;
`;
export const HeaderSearchIcon = styled.div`
position: absolute;
left: 1%;
 // padding: 5px;
  height: 22px !important;
  font-size: 1.5rem;
  //background-color: lightblue;
  border-radius: 5px;
`;

export const HeaderSearchInput = styled.input`
  height: 40px;
  padding: 10px;
  border: none;
  left: 5%;
  width: 50%;
  border-radius:30px;
`;

