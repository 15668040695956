import { motion } from 'framer-motion';
import styled from 'styled-components';

// Grid container for NFT cards
export const NFTGrid = styled(motion.div)`
  position: relative;
  display: grid;
  gap: 20px;
  padding: 50px;
  margin-top: 0vh;

  grid-template-columns: ${({ isFullScreen }) => (isFullScreen ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)')}; // 4 columns if full screen, else 2 columns: ;

  @media (max-width: 1200px) {
    grid-template-columns: ${({ isFullScreen }) => (isFullScreen ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)')}; // 3 columns when width is <= 1200px
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns when width is <= 900px
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr); // 1 column when width is <= 600px
  }
`;
// Individual NFT card
export const NFTCard = styled.div`
  background: ${({ isDarkMode }) => isDarkMode ? '#333' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: 1px solid ${({ isDarkMode }) => isDarkMode ? '#444' : '#ddd'};
  border-radius: 10px;
  padding: 15px;

  grid-column: span ${({ isMobile, isFullScreen }) => (isMobile ? '0' : isFullScreen ? '1' : '0')};
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
`;

export const NFTCard2 = styled.div`
  background: ${({ isDarkMode }) => isDarkMode ? '#333' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: 1px solid ${({ isDarkMode }) => isDarkMode ? '#444' : '#ddd'};
  border-radius: 10px;
  padding: 15px;

  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
`;
// Styled components for card elements
export const NFTImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

export const NFTTitle = styled.h3`
  margin: 10px 0;
  font-size: 1.2em;
`;

export const NFTDescription = styled.p`
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
`;

export const NFTPrice = styled.p`
  font-weight: bold;
  margin-top: 5px;
`;

export const NFTOwner = styled.p`
  font-size: 0.9em;
  color: ${({ isDarkMode }) => isDarkMode ? '#aaa' : '#888'};
`;
// Title for the NFT collection
export const CollectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the start */
  width: 100%;
  margin-bottom: 50px;
 // margin-top: 12.5vh;
  margin-top: ${({ isMobile }) => isMobile ? '20vh' : '12.5vh'};
  white-space: nowrap;
  margin-left: 5%;
`;

// Title for the NFT collection
export const NFTCollectionTitle = styled.h2`
  font-size: 2em;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  text-align: left; /* Align text to the left */
  margin: 0;
  white-space: nowrap;
`;

// Subheader for the NFT collection
export const NFTCollectionSubheader = styled.h3`
  font-size: 1.5em;
  color: ${({ isDarkMode }) => isDarkMode ? '#aaa' : '#666'};
  text-align: left; /* Align text to the left */
  margin: 0;
  white-space: nowrap;
`;

// Featured NFT card
export const FeaturedNFTCard = styled(NFTCard2)`
  grid-column: span 4; // Span across all columns
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px;
  width: 90%;
`;

// Button for the featured NFT card
export const ViewDropsButton = styled.button`
  background-color: ${({ isDarkMode }) => isDarkMode ? '#444' : '#ddd'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 20px;
`;





///
/// Physical Wearable View
///



// Grid container for Wearable cards
export const WearableGrid = styled(motion.div)`
  position: relative;
  display: grid;
  grid-template-columns: ${({ isFullScreen }) => (isFullScreen ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)')}; // Default: 4 columns if full screen, else 2 columns
  gap: 20px;
  padding: 50px;


  @media (max-width: 1200px) {
    grid-template-columns: ${({ isFullScreen }) => (isFullScreen ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)')}; // Adjust based on isFullScreen
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns when width is <= 900px
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr); // 1 column when width is <= 600px
  }
`;


// Individual Wearable card
export const WearableCard = styled.div`
  background: ${({ isDarkMode }) => isDarkMode ? '#333' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: 1px solid ${({ isDarkMode }) => isDarkMode ? '#444' : '#ddd'};
  border-radius: 10px;
  padding: 15px;

  grid-column: span ${({ isMobile, isFullScreen }) => (isMobile ? '0' : isFullScreen ? '1' : '0')};
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
`;

export const WearableCard2 = styled.div`
  background: ${({ isDarkMode }) => isDarkMode ? '#333' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: 1px solid ${({ isDarkMode }) => isDarkMode ? '#444' : '#ddd'};

  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
`;


export const WearableImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

export const WearableTitle = styled.h3`
  margin: 10px 0;
  font-size: 1.2em;
`;

export const WearableDescription = styled.p`
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
`;

export const WearablePrice = styled.p`
  font-weight: bold;
  margin-top: 5px;
`;

export const WearableOwner = styled.p`
  font-size: 0.9em;
  color: ${({ isDarkMode }) => isDarkMode ? '#aaa' : '#888'};
`;




// Title for the NFT collection
export const WearableCollectionTitle = styled.h2`
  font-size: 2em;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  text-align: left; /* Align text to the left */
  margin: 0;
  white-space: nowrap;
`;

// Subheader for the NFT collection
export const WearableCollectionSubheader = styled.h3`
  font-size: 1.5em;
  color: ${({ isDarkMode }) => isDarkMode ? '#aaa' : '#666'};
  text-align: left; /* Align text to the left */
  margin: 0;
  white-space: nowrap;
`;

export const FeaturedWearableCard = styled(WearableCard2)`
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px;
  width: 90%;
`;
