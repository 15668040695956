import { useGLTF } from '@react-three/drei'
import React from 'react'

export function Symbol({ isDarkMode, ...props }) {
  const { nodes, materials } = useGLTF('/w3oSymbol.glb')
  return (
    <group {...props} dispose={null}>
      <group
        position={[-0.005, 0.012, 0.008]}
        rotation={[-3.137, 0.004, -3.136]}
        scale={[0.258, 0.248, 0.648]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube025.geometry}
          material={isDarkMode ? materials['Material.032'] : materials['Material.029']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube025_1.geometry}
          material={isDarkMode ? materials['Material.029'] : materials['Material.032']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/w3oSymbol.glb')
