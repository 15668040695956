import { Cloud, Environment, Sparkles } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { EffectComposer, Noise } from '@react-three/postprocessing';
import { getProject } from "@theatre/core";
import { BlendFunction } from 'postprocessing';
import React, { Suspense, useRef } from 'react';
import styled from 'styled-components';
import World from './MasterPlan3D';
import './Plan.css';
import state from './site.json';
if (process.env.NODE_ENV === 'development') {
 // studio.initialize();
}



const project = getProject("New Game", { state });

const MasterPlanContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -10;
  display: flex;
`;

const Text1 = styled.p`
  font-size: 18rem;
  font-weight: normal;
  color: #0d0d0d;
  opacity: 0.9;
  mix-blend-mode: luminosity;
  -webkit-text-stroke: 1px #d1d5db;
`;

const Text2 = styled(Text1)`
  font-size: 20rem;
`;

const FixedContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  padding: 1rem 2.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

function Sky({ isDarkMode }) {
    const ref = useRef();
    const cloud0 = useRef();
  
    const config = {
      seed: 1,
      segments: 20,
      volume: 6,
      opacity: 0.8,
      fade: 10,
      growth: 4,
      speed: 0.1,
      x: 6,
      y: 1,
      z: 1
    };
  
    const darkModeColors = ['#112244', '#223355', '#334466', '#445577', '#556688'];
    const lightModeColors = ['#eed0d0', '#d0e0d0', '#a0b0d0', '#c0c0dd', '#ffccdd'];
  
    useFrame((state, delta) => {
      ref.current.position.y = Math.cos(state.clock.elapsedTime / 2) / 2;
      ref.current.position.x = Math.sin(state.clock.elapsedTime / 2) / 2;
      if (cloud0.current) {
        cloud0.current.position.y -= delta * config.speed;
      }
    });
  
    const colors = isDarkMode ? darkModeColors : lightModeColors;
  
    return (
      <group ref={ref}>
        {colors.map((color, index) => (
          <Cloud key={index} {...config} color={color} seed={index + 1} />
        ))}
      </group>
    );
  }

  function MasterPlan({ isDarkMode, setDaoPopup }) {
    return (
      <>

        <>
        <group>
          <World setDaoPopup={setDaoPopup} style={{zIndex:'-9999999'}} className='theatre-controls-container' isDarkMode={isDarkMode} project={project} />
          <Sparkles  count={350} size={45} speed={0.8} opacity={0.75} scale={30}  color={isDarkMode ? '#cc5500' : '#000'} />
          {/* Render Sky outside of EffectComposer to avoid applying post-processing effects to it */}

          <EffectComposer>
            <Noise opacity={0.8} premultiply blendFunction={BlendFunction.HARD_LIGHT} />
            
          </EffectComposer>
          <Suspense fallback={null}>
            <Environment preset="studio" blur={0} />
          </Suspense>
          </group>
        </>
      </>
    );
  }

export default MasterPlan;
