import { motion } from 'framer-motion';
import { FaColumns, FaExpand, FaHome, FaSearch, FaTags, FaThLarge, FaTshirt } from 'react-icons/fa'; // Added icons for Wearables, NFTs, and Browse All
import styled from 'styled-components';

export const lightTheme = {
  background: `
    background: -webkit-linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    background: -o-linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    background: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    color: #333332;
  `,
};

export const darkTheme = {
  background: `
    background: -webkit-linear-gradient(to top, #283E51, #0A2342);
    background: linear-gradient(to right, #434343 0%, black 100%);
    background: -o-linear-gradient(to top, #283E51, #0A2342);
    background: -webkit-linear-gradient(to right, #434343 0%, black 100%);
    background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
    color: #FAF4E5;
  `,
};

export const SubNavbar2 = styled(motion.div)`
  position: absolute;
  top: ${({ browseMode }) => (browseMode ? '22.5%' : 'auto')};
  bottom: ${({ browseMode }) => (browseMode ? 'auto' : '2.5%')};
  margin-left: ${({ browseMode }) => (browseMode ? '7.5%' : '18.75%')};
  transform: translate(-50%, 0%);

  height: ${({ browseMode }) => (browseMode ? '37.5px' : '35px')};

  width: ${({ browseMode }) => (browseMode ? '40%' : '17.5%')};
  background-color: ${({ isDarkMode }) => !isDarkMode ? darkTheme.background : lightTheme.background};
  z-index: 999999999999999;
  color: ${({ isDarkMode }) => isDarkMode ? darkTheme.color : lightTheme.color};
  display: flex;
  justify-content: space-around;
  z-index: 9999;
  border-radius: 30px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
  align-items: center;
  padding: 0 10px;
  @media (max-width: 600px) {
    left: 50%;
    margin-left: 0;
    transform: translate(-50%, 0%) !important;
    width: 80%;
  }
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  flex: 1;
  margin-right: 1rem;
  @media (max-width: 600px) {
    min-width: 50%; /* Adjust width to 50% when window width is 600px or less */
    margin: 0;
  }
`;

export const NavbarItem = styled.div`
  margin: 0 20px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  font-size: 10px;
  letter-spacing: -1px;

  &.company-info {
    display: flex;
    align-items: center; // Align items in a row
  }

  &:after {
    content: '';
    position: absolute;
    height: 20px;
    width: 1px;
    background: white;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    color: yellow;
    text-shadow: 0 0 5px yellow;
  }

  @media (max-width: 600px) {
    margin: 0 10px;
    padding: 0 5px;
    flex-direction: column;
    font-size: 1em;
    &:after {
      //display: none;
    }
  }
`;

export const NavbarItemLast = styled(NavbarItem)`
  &:after {
    display: none;
  }
`;

export const FaTshirtIcon = styled(FaTshirt)`
  margin-right: 8px; /* Add space between icon and text */
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const FaTagsIcon = styled(FaTags)`
  margin-right: 8px; /* Add space between icon and text */
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const FaThLargeIcon = styled(FaThLarge)`
  margin-right: 8px; /* Add space between icon and text */
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const FaHomeIcon = styled(FaHome)`
  margin-right: 8px; /* Add space between icon and text */
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const FaExpandIcon = styled(FaExpand)`
  margin-right: 8px; /* Add space between icon and text */
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const FaColumnsIcon = styled(FaColumns)`
  margin-right: 8px; /* Add space between icon and text */
  @media (max-width: 600px) {
    margin-right: 0;
    display: block;
  }
`;

export const IconText = styled.span`
  display: none;
  @media (max-width: 600px) {
    display: block;
    font-size: 0.5em;
    text-align: center;
  }
`;


export const SearchInput = styled.input`
  width: 100%;
  padding: 10px 0px 10px 40px; /* Add space for the icon */
  border: none;
  border-radius: 5px;
  background: transparent; /* Make the background always transparent */
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')}; /* Text color */
  ::placeholder {
    color: ${({ isDarkMode }) => (isDarkMode ? '#ddd' : '#888')}; /* Placeholder color */
  }
  &:focus {
    outline: none;
  }
`;


export const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')}; /* Icon color */
`;

export const BrowseButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#eee')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  cursor: pointer;
  &:hover {
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ccc')};
  }
`;
