
import { a, useSpring } from "@react-spring/three";
import { AccumulativeShadows, Billboard, Image, OrbitControls, PerformanceMonitor, RandomizedLight, Scroll, ScrollControls, Sparkles, Text, useIntersect, useTexture } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import {
  Mouse,
  MouseBody,
  Paragraph,
  WelcomeContainer,
  WelcomeMessage
} from '../JoinDao/DaoOverlay/OverlayElements';
import Network from '../JoinDao/Network';




/////////////////////////////////////////////////////////////////////////
//word globe


const titles = [
  "Artists", "Graphic Designer", "Painter", "Social Media Marketer", "Digital Creator",
  "3D Modeler", "Innovator", "Clothing Designer", "Influencer", "Illustrator",
  "Web Developer", "UI/UX Designer", "Photographer", "Videographer", "Copywriter",
  "Music Producer", "Animator", "Game Developer", "Architect", "Interior Designer",
  "Fashion Stylist", "Brand Strategist", "Creative Director", "Art Director", "Product Designer",
  "Film Director", "Screenwriter", "Cinematographer", "Editor", "Sound Engineer",
  "Set Designer", "Motion Graphics Designer", "Printmaker", "Sculptor", "Ceramic Artist",
  "Jewelry Designer", "Textile Artist", "Concept Artist", "Performance Artist", "Muralist",
  "Installation Artist", "Visual Effects Artist", "Voice Actor", "Composer", "Playwright",
  "Choreographer", "Dance Instructor", "Graphic Novelist", "Cartoonist", "Character Designer",
  "Voiceover Artist", "Podcast Host", "SEO Specialist", "Content Strategist", "Marketing Consultant",
  "Public Relations Specialist", "Advertising Executive", "Event Planner", "Museum Curator", "Gallery Owner",
  "Fashion Photographer", "Photo Editor", "Makeup Artist", "Hair Stylist", "Nail Artist",
  "Costume Designer", "Prop Maker", "Art Therapist", "Music Therapist", "Sound Designer",
  "Lighting Designer", "Stage Manager", "Production Coordinator", "Broadcast Engineer", "Radio Producer",
  "Multimedia Artist", "3D Animator", "Environmental Designer", "Urban Planner", "Landscape Architect",
  "Industrial Designer", "Automotive Designer", "Aerospace Designer", "Marine Designer", "Package Designer"
];




function Word({isMobile, children, ...props }) {
  const color = new THREE.Color()
  const fontProps = { font: '/Inter-Bold.woff', fontSize: .5, letterSpacing: -0.05, lineHeight: 1, 'material-toneMapped': false }
  const ref = useRef()
  const [hovered, setHovered] = useState(false)
  const fontSize = isMobile ? 0.05 : 0.085;
  useEffect(() => {
    if (hovered) document.body.style.cursor = 'pointer'
    return () => (document.body.style.cursor = 'auto')
  }, [hovered])

  useFrame(({ camera }) => {
    ref.current.material.color.lerp(color.set(hovered ? '#a76032' : 'black'), 0.1)
  })

  const over = (e) => (e.stopPropagation(), setHovered(true))
  const out = () => setHovered(false)

  return (
    <Billboard {...props} onPointerOver={over} onPointerOut={out}>
      <Text ref={ref} onClick={() => console.log('clicked')}  {...fontProps} fontSize={fontSize} >
        {children}
      </Text>
    </Billboard>
  )
}

function Cloud({ count, radius, isMobile, isVisible }) {
  const [spring, api] = useSpring(() => ({
    scale: 0,
    blur: 15,
    positionY: 0,
    config: { mass: 1, tension: 170, friction: 26 },
  }));

  useEffect(() => {
    if (isVisible) {
      api.start({ scale: 1, blur: 0, positionY: isMobile ? 0.15 : 0 });
    } else {
      api.start({ scale: 0, blur: 15, positionY: 0 });
    }
  }, [api, isMobile, isVisible]);

  const words = useMemo(() => {
    const temp = [];
    const spherical = new THREE.Spherical();
    const phiSpan = Math.PI / (count + 1);
    const thetaSpan = (Math.PI * 2) / count;

    for (let i = 1; i <= count; i++) {
      for (let j = 0; j < count; j++) {
        const title = titles[(i * count + j) % titles.length];
        temp.push([new THREE.Vector3().setFromSpherical(spherical.set(radius, phiSpan * i, thetaSpan * j)), title]);
      }
    }

    return temp;
  }, [count, radius]);

  return (
    <a.group scale={spring.scale} position-y={spring.positionY}>
      {words.map(([pos, word], index) => <Word isMobile={isMobile} key={index} position={pos} children={word} />)}
    </a.group>
  );
}








////////////////////////////////////////////////////////////////////////
// Utility function to shuffle an array
function shuffleArray2(array) {
  let currentIndex = array.length, randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
}

// Generate a network of images around a central node
// Generate a network of images around a central node
function ImageNetwork({ category, baseImageUrl, scale, position, isDarkMode, scaledFactor }) {
  const count = 6; // 1 central image + 5 surrounding images
  const imagesSetCount = 30; // Total images in each category
  const textureLoader = new THREE.TextureLoader();

  // Use state to store random scales and indexes
  const [randomScales, setRandomScales] = useState([]);
  const [shuffledIndexes, setShuffledIndexes] = useState([]);

  useEffect(() => {
    setShuffledIndexes(shuffleArray2(Array.from({ length: imagesSetCount }, (_, i) => i + 1)));
    setRandomScales(Array.from({ length: count }, () => Math.random()));
  }, [count, imagesSetCount]);

  const images = [];
  const lines = [];

  if (shuffledIndexes.length > 0) {
    const centralImageUrl = `${baseImageUrl}${category}/${category}${shuffledIndexes[0]}.png`;
    const centralTexture = textureLoader.load(centralImageUrl);
    images.push(
      <mesh key="central" position={[0, 0, 0]}>
        <circleGeometry args={[scale * scaledFactor * 0.35, 32]} />
        <meshBasicMaterial map={centralTexture} />
      </mesh>
    );

    const radiusBase = scale * 0.5 * scaledFactor;
    const angleStep = (2 * Math.PI) / (count - 1);
    randomScales.forEach((randomScale, i) => {
      if (i === 0) return; // Skip the central image

      const angle = angleStep * i;
      const radius = radiusBase * (1 + randomScale * 0.15); // Use stored random scale
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;
      const imageUrl = `${baseImageUrl}${category}/${category}${shuffledIndexes[i]}.png`;
      const texture = textureLoader.load(imageUrl);
      images.push(
        <mesh key={i} position={[x, y, 0]}>
          <circleGeometry args={[scale * scaledFactor * (0.075 + randomScale * 0.075), 32]} />
          <meshBasicMaterial map={texture} />
        </mesh>
      );

      const lineColor = isDarkMode ? 0xffffff : 0x000000;
      const material = new THREE.LineBasicMaterial({ color: lineColor, linewidth: 2 });
      const points = [new THREE.Vector3(0, 0, 0), new THREE.Vector3(x, y, -0.001)];
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      lines.push(<line key={`line-${i}`} geometry={geometry} material={material} />);
    });
  }

  return (
    <group position={position}>
      {images}
      {lines}
    </group>
  );
}





function Items2({ started, baseImageUrl, isDarkMode, scaledFactor }) {
  const { width: w, height: h } = useThree((state) => state.viewport);
  const { viewport } = useThree();
  const categories = ['Artist', 'Artist', 'Graphic', 'Graphic', 'Digital', 'Digital', 'Innovator', 'Innovator','Social','Social',  'Influencer', 'Influencer'];
  const count =30; // Number of images per category
  const scale = 1; // Adjust scale as needed
  
  const yPositions = [
    -h * 1,
    -h * 0.7,
    -h * 1.35,
    -h * 1.75,
    -h * 2.2,
    -h * 2.6,
    -h * 3.1,
    -h * 3.4,
    -h * 3.6,
    -h * 4.1,
    -h * 5,
    -h * 5.45,
  ];
  return (
      <group>
          { categories.map((category, index) => (
              <ImageNetwork
              isDarkMode={isDarkMode}
                  key={category}
                  category={category}
                  baseImageUrl={baseImageUrl}
                  count={count}
                  scaledFactor={scaledFactor}
                  scale={scale}
                  position={[ index % 2 === 0 ? w / 16 : -w / 16,yPositions[index], 5]} // Example positioning
              />
          ))}
      </group>
  );
}

function Item({ url, scale,  ...props }) {
  const visible = useRef(false)
  const [hovered, hover] = useState(false)
  const ref = useIntersect((isVisible) => (visible.current = isVisible))
  const { height } = useThree((state) => state.viewport)
  //const circleImageMaterial = useMemo(() => createCircleImageMaterial(), []);

  useFrame((state, delta) => {
    ref.current.position.y = THREE.MathUtils.damp(ref.current.position.y, visible.current ? 0 : -height / 2 + 1, 4, delta)
    ref.current.material.zoom = THREE.MathUtils.damp(ref.current.material.zoom, visible.current ? 1 : 1.5, 4, delta)
    ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, hovered ? 1 : 0, 4, delta)
  })
  return (

   
    <group  {...props}>
        <Image   ref={ref} onPointerOver={() => hover(true)} onPointerOut={() => hover(false)} scale={scale} url={url}   loading="lazy" />
    </group>

  )
}

function createCircularMaskTexture() {
  const size = 512; // Adjust the size as needed
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext('2d');
  const centerX = size / 2;
  const centerY = size / 2;
  const radius = size / 2;

  context.fillStyle = 'transparent'; // Set the background color
  context.fillRect(0, 0, size, size);

  context.beginPath();
  context.arc(centerX, centerY, radius, 0, Math.PI * 2);
  context.closePath();
  context.clip();

  // Fill the circle with white to create the mask
  context.fillStyle = 'white';
  context.fill();

  return new THREE.CanvasTexture(canvas);
}

function Item2({ url, scale, daoImages, ...props }) {
  const visible = useRef(false);
  const [hovered, hover] = useState(false);
  const ref = useIntersect((isVisible) => (visible.current = isVisible));
  const { height } = useThree((state) => state.viewport);
  const circularMaskTexture = useMemo(() => createCircularMaskTexture(), []);
  // Define a spring animation for opacity
  const opacitySpring = useSpring({
    opacity: daoImages ? 0 : 1,
    config: { duration: 1000 }, // Adjust the duration as needed for a smoother transition
  });
  useFrame((state, delta) => {
    ref.current.position.y = THREE.MathUtils.damp(
      ref.current.position.y,
      visible.current ? 0 : -height / 2 + 1,
      0,
      delta
    );
    ref.current.material.zoom = THREE.MathUtils.damp(
      ref.current.material.zoom,
      visible.current ? 1 : 1.5,
      4,
      delta
    );
    ref.current.material.grayscale = THREE.MathUtils.damp(
      ref.current.material.grayscale,
      hovered ? 1 : 0,
      4,
      delta
    );
  });

  return (
    <group {...props}>
      <mesh
        ref={ref}
        onPointerOver={() => hover(true)}
        onPointerOut={() => hover(false)}
        scale={scale}
      >
        <circleGeometry args={[0.5, 64]} />
        <a.meshBasicMaterial map={circularMaskTexture} side={THREE.DoubleSide} transparent />
        <a.meshBasicMaterial
          map={useTexture(url)}
          side={THREE.DoubleSide}
          transparent
          opacity={opacitySpring.opacity} // Use the opacity from the spring animation
        />
      </mesh>
    </group>
  );
}

const uniqueRandomNumbers = Array.from({ length: 100 }, (_, index) => index + 1);
shuffleArray(uniqueRandomNumbers); // Shuffle the array to randomize the numbers

// Function to shuffle an array randomly
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}



function AnimatedItem({ daoImages, position, imageUrl, scale, started }) {
  const itemAnimation = useSpring({
    from: {
      position: [0, 0, 0],
      opacity: 0, // Initial opacity set to 0
      scale: 0,
    },
    to: {
      position,
      opacity: 1, // Target opacity set to 1 for fade-in
      scale: 1,
    },
    config: { duration: 250 }, // Adjust the duration as needed
    //immediate: started, // Skip animation if started is false
  });

  return (
    started && (
    <a.group scale={itemAnimation.scale} opacity={itemAnimation.opacity}   position={position}>
      <Item2 daoImages={daoImages}  url={imageUrl} scale={scale} position={position} />
    </a.group>
    )
  );
}


const numItems = 20; // Total number of items to display
const totalItems = 30; // Total number of possible items



function Items({started, daoImages, isDarkMode}) {
  const { width: w, height: h } = useThree((state) => state.viewport);
  const width = window.innerWidth;
  const isMobile = width <= 600; // Check if the window width is 600 or less
  const scaledCreatives = isMobile ? 1.6 : 2.75; // Scaling factor based on mobile view or default
  //const scaledFactor = isMobile ? 0.65 : 1;
  const scaledFactor = isMobile ? 2 : 1;
  const scaledFactorBetween = isMobile ? 2 : 0.9;
  const scaledFactorGraphic = isMobile ? 0.7 : 1;
  const scaledAdd = isMobile ? 0.0001 : 1;
  const scaledCreativesPosition = isMobile ? 3 : 1;
  const scrollPosition = isMobile ? 0.125 : .35;
  const shiftedCreatives = isMobile ? 0.5 : 0.5; 
  // Constants for common properties
  const imageUrlBase = "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoHeroImages/";
  const scale = [10 / 24 * scaledCreatives, 10/ 24 * scaledCreatives, 0.5 * 0.25];
  const baseImageUrl = "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/";
  const numItems = 16; // Number of items to distribute in the circle
  const radius = isMobile ? 0.95 : 1.75; // Radius of the circle
  const creativePosition = isMobile ? 0.5 : 0;
  const angleStep = (2 * Math.PI) / numItems; // Angle step for each item
  
  // Create an array of unique random numbers from 1 to 30
//

  

  const CreativeItems = Array.from({ length: numItems }, (_, index) => {
    const angle = (index / numItems) * 2 * Math.PI;
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * radius;
    const position = [x, y, 0];
    const opacity = daoImages ? 0 : 1;
    const randomImageIndex = uniqueRandomNumbers[index] - 1; // Use unique random numbers
    return started  ? (
      <AnimatedItem
        key={index}
        scale={scale}
        position={position}
        imageUrl={`${imageUrlBase}CreativesCirc${randomImageIndex + 1}.png`} // Use the random image index
        started={started}
        daoImages={daoImages}
      />
    ) : null;
  });
  

  


  const imageUrls = [
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Artist1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Artist2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Graphic1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Graphic2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Digital1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Digital2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Innovate1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Innovate2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Social1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Social2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Influence1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Influence2.jpg",
  ];
  



  // Calculate Y position based on your original code
  const yPositions = [
    -h * 1.6,
    -h * 1.3,
    -h * 1.95,
    -h * 2.35,
    -h * 2.8,
    -h * 3.2,
    -h * 3.7,
    -h * 4.0,
    -h * 4.2,
    -h * 4.7,
    -h * 5.6,
    -h * 6.05,
  ];
  

 
  return (
    <Scroll>
   <a.group position={[0, -2 + creativePosition +  shiftedCreatives, -10]} >
      {CreativeItems}
    </a.group>
      { <group position={[scrollPosition, 0, 0]}>
        {imageUrls.map((imageUrl, index) => (
          <Item2
            key={index}
            url={imageUrl}
            scale={[w * 1.25 / 12 * scaledFactor, w * 1.25 / 12 * scaledFactor, 0.25]}
            position={[
              index % 2 === 0 ? w / 32 : -w / 8,
              yPositions[index], // Maintain the same Y position
              5,
            ]}
          />
        ))}
      </group> }

     {/* { <Items2  scaledFactor={scaledFactor} isDarkMode={isDarkMode} started={started} baseImageUrl={baseImageUrl} />} */}
    </Scroll>
  );
}





  



const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  //background: #000;
  @media (max-width: 600px) {
    .model {
      transform: scale(0.5);
    }
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    .model {
      transform: scale(0.8);
    }
  }
`;





const innerMaterial = new THREE.MeshStandardMaterial({
  transparent: true,
  opacity: 1,
  color: 'black',
  roughness: 0,
  side: THREE.FrontSide,
  blending: THREE.AdditiveBlending,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  envMapIntensity: 2
});




  




function Scene({setIsCloudVisible, welcomeMessage2, setWelcomeMessage2, welcomeMessage,  setWelcomeMessage, decreaseShiftedModel, damping, setDamping, isScrolled, setIsScrolled, shiftedModel, isMobile, daoImages, scrollExplore, setDaoImages, setScrollExplore, isDarkMode, setIsFooterVisible, isFooterVisible, config, scroll}) {
  
  const { size } = useThree();
  const [scale, setScale] = useState(1);
  const [scale2, setScale2] = useState(1);
 
  useEffect(() => {
    const updateScale = () => {
      const width = size.width;
      let newScale = 1;

      if (width <= 600) {
        newScale = 1; // Adjust the scale value as needed for smaller screens
      } else if (width > 600 && width <= 1200) {
        newScale = 0.71428571; // Adjust the scale value as needed for medium-sized screens
      }
      setScale2(newScale)
      setScale(newScale);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [size?.width]);

  const [position, setPosition] = useState([0, -2.5, 0]);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width <= 600) {
        setPosition([0, 1, 0]); // Adjust the position value as needed for smaller screens
      } else {
        setPosition([0, -2.15, 0]); // Default position value for larger screens
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
            <Network setWelcomeMessage={setWelcomeMessage} setIsCloudVisible={setIsCloudVisible} damping={damping} setDamping={setDamping} decreaseShiftedModel={decreaseShiftedModel} shiftedModel={shiftedModel} isMobile={isMobile} 
                     daoImages={daoImages} scrollExplore={scrollExplore} setDaoImages={setDaoImages} welcomeMessage={welcomeMessage}
                    setScrollExplore={setScrollExplore} isFooterVisible={isFooterVisible} setIsFooterVisible={setIsFooterVisible}
                    welcomeMessage2={welcomeMessage2}
        setWelcomeMessage2={setWelcomeMessage2}
                     scroll={scroll} isDarkMode={isDarkMode} scale={scale} isScrolled={isScrolled} setIsScrolled={setIsScrolled} />

      
      </>
  );
}








export default function DaoModel({setShiftedModel,  scroll, daoImages, scrollExplore, setDaoImages,
                                   setScrollExplore, isFooterVisible, setIsFooterVisible, 
                                   isDarkMode, orbitControlsEnabled, setButtonPopup,  
                                   buttonPopup, start, toggleOrbitControls, modelSwitch,
                                   started, setModelSwitch, props, welcomeMessage2, setWelcomeMessage2, welcomeMessage, setWelcomeMessage }) {
  const { camera, gl } = useThree();
  const ref = useRef();
  const [perfSucks, degrade] = useState(false);
  const [position, setPosition] = useState([0, -1.5, 0]);
  const [timedDisplay, setTimedDisplay] = useState(true);
  const [isCloudVisible, setIsCloudVisible] = useState(true);
  const [showCloud, setShowCloud] = useState(true);

  // const started = true; // This should be controlled by your app's state

  const w = window.innerWidth;
  const isMobile = w <= 600; // Check if the window width is 600 or less
  // const scroll = useScroll()
  const shiftedModel = isMobile ? 0.2 : 0; 
  const cloudRadius = isMobile ? 0.9 : 1.5; // Radius of the circle


  
  const [isScrolled, setIsScrolled] = useState(false);
  // Create a useSpring animation for the position
  const springProps = useSpring({
    from: { position: [0, 0.5, 0] },
    to: async (next, cancel) => {
      if (!isMobile && !isScrolled) {
        // Transition to [0, 0, 0] if isMobile and isScrolled are true
        await next({ position: [0, 0, 0] });
      } else {
        // Transition back to [0, 0.35, 0] if the conditions are not met
        await next({ position: [0, 0.35, 0] });
      }
    },
    config: { mass: 1, tension: 120, friction: 14 }, // Adjust these values as needed
  });





  const daoAnimation = useSpring({
    position: [0, isMobile ? shiftedModel : 0, 0],
    config: { duration: 1000 },
  });

  // New animation for shrinking and expanding the model
  const modelScale = useSpring({
    scale: welcomeMessage ? 0.25 : 1,
    position: welcomeMessage ? [0, 8, 0] : [0, 0, 0],
    config: { mass: 1, tension: 120, friction: 14 }, // Wobbly effect configuration
  });

  const scaledText = isMobile ? .5 : 1; // Scaling factor for text based on mobile view or default
  const scaledThree = isMobile ? 3 : 0; // Scaling factor for text based on mobile view or default
  const scaledHeight = isMobile ?  1.05  : 1;



// Outside of your component, add a scroll event listener.
window.addEventListener("scroll", handleScroll);

// Define the handleScroll function.
function handleScroll() {
  const scrollY = window.scrollY;
  const windowHeight = window.innerHeight;
  const documentHeight = document.documentElement.scrollHeight;

  // Calculate the position of the bottom of the page.
  const bottomOfPage = documentHeight - windowHeight;

  // Check if the user has scrolled to the bottom.
  if (scrollY >= bottomOfPage) {
    setIsFooterVisible(true);
  } else {
    setIsFooterVisible(false);
  }
}

useEffect(() => {
  if (!isCloudVisible) {
    // Delay the removal of Cloud to allow for exit animation
    const timeoutId = setTimeout(() => setShowCloud(false), 1000); // 1000 ms for exit animation
    return () => clearTimeout(timeoutId);
  } else {
    setShowCloud(true);
  }
}, [isCloudVisible]);


useEffect(() => {
  if (!scrollExplore) {
      const timer = setTimeout(() => {
          setTimedDisplay(true);
      }, 3000);

      return () => clearTimeout(timer);
  } else {
      setTimedDisplay(false);
  }
}, [scrollExplore]);


// Don't forget to remove the event listener when your component unmounts.
useEffect(() => {
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);

// Don't forget to remove the event listener when your component unmounts.
useEffect(() => {
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);
  const [damping, setDamping] = useState(0.5);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width <= 600) {
        setPosition([0, -0.5, 0]); // Adjust the position value as needed for smaller screens
      } else {
        setPosition([0, -1, 0]); // Default position value for larger screens
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openWhitelistLink = () => {
    setButtonPopup(true);
  };



  return (
    started && (
    <>
  
    <group  >
    {orbitControlsEnabled && (
      <OrbitControls enableZoom enablePan enableRotate zoomSpeed={0.6} panSpeed={0.5} rotateSpeed={0.4} />
    )}
    <PerformanceMonitor onDecline={() => degrade(true)} />
    <color attach="background" args={['#000']} />
      <pointLight position={[-0.5, -1.75, 0]} intensity={3.5} />
      <AccumulativeShadows frames={100} alphaTest={0.85} opacity={0.8} color="white" scale={20} position={[0, -0.005, 0]}>
        <RandomizedLight amount={8} radius={6} ambient={0.5} intensity={1} position={[-1.5, 2.5, -2.5]} bias={0.001} />
      </AccumulativeShadows>
      <Sparkles size={2} color={isDarkMode ? '#fff' : '#cc5500'} scale={[10, 10, 10]} />


      <ScrollControls damping={0} pages={7.725}>
      <a.group  >
    <a.group position={[0, shiftedModel, 0]} >
        <Scene setIsCloudVisible={setIsCloudVisible} damping={damping} setDamping={setDamping} isScrolled={isScrolled} setIsScrolled={setIsFooterVisible}  shiftedModel={shiftedModel} isMobile={isMobile}    daoImages={daoImages}
        scrollExplore={scrollExplore}
        setDaoImages={setDaoImages}
        welcomeMessage2={welcomeMessage2}
        setWelcomeMessage2={setWelcomeMessage2}
        welcomeMessage={welcomeMessage}
        setScrollExplore={setScrollExplore} setWelcomeMessage={setWelcomeMessage} isFooterVisible={isFooterVisible} setIsFooterVisible={setIsFooterVisible} scroll={scroll} isDarkMode={isDarkMode} />
        </a.group>
   </a.group>

        <mesh scale={[0.95, 1, 0.95]} material={innerMaterial} />
        <mesh material={innerMaterial} />
      
   {/* Conditional rendering of the Items component */}

   <Items isDarkMode={isDarkMode} daoImages={daoImages} started={started} />
        <Scroll html style={{ width: '100%', height: '100vh' }}>


        
        {timedDisplay &&
                (<MouseBody
                  style={{
                    position: 'absolute',
                    top: isMobile ? '65vh' : '68.5vh',
                    opacity: scrollExplore > 0 ? 0 : 1,
                    transition: 'opacity 0.5s ease',
                  }}
                >
                  <Paragraph>Scroll to explore!</Paragraph>
                  <Mouse className="mouse" />
                </MouseBody>)
              }



<div style={{ position: 'relative', top: isMobile ? '-25px' : '0' }}>
        <h1 style={{ position: 'absolute', fontSize: `${4 * scaledText}rem`, top: `${68.5 * scaledHeight}vh`, left: '7.5vw', transform: 'translate3d(0,-100%,0)', opacity: scrollExplore ? 0 : 1,     transition: 'opacity 0.5s ease', }}>CREATIVES</h1>
        <h1 style={{ position: 'absolute', fontSize: `${2 * scaledText}rem`, top: '30vh', right: '10vw', transform: 'rotate(180deg)', transformOrigin: 'center', filter: 'blur(1px)', opacity: scrollExplore ? 0 : 1,     transition: 'opacity 0.5s ease', }}>CREATIVES</h1>
</div>

<WelcomeContainer style={{
  zIndex: 999999999999,
  position: 'absolute',
  textAlign: 'left',
  fontSize: `${4 * scaledText}rem`, 
  top: '65vh', 
  right: welcomeMessage ? (isMobile ? '5%' : '-15%') : '-50%', // Adjust right position based on mobile
  opacity: welcomeMessage ? 1 : 0,
  transform: welcomeMessage ? 'translate(0, -150px) scale(1)' : 'translate(0, 20px) scale(0.95)',
  filter: welcomeMessage ? 'blur(0)' : 'blur(10px)',
  transition: 'opacity 0.5s ease, transform 0.5s ease, filter 0.5s ease, right 0.5s ease'
}}>
  <WelcomeMessage>
    Your #1
  </WelcomeMessage>
  <WelcomeMessage>
    Authenticated
  </WelcomeMessage>
</WelcomeContainer>



<WelcomeContainer style={{
  zIndex: 999999999999,
  position: 'absolute',
  textAlign: 'left',
  fontSize: `${4 * scaledText}rem`, 
  top: '115vh',
  left: welcomeMessage2 ? (isMobile ? '-20%' : '-35%') : '-100%', // Adjust left position based on mobile
  opacity: welcomeMessage2 ? 1 : 0,
  transform: welcomeMessage2 ? 'translate(0, -150px) scale(1)' : 'translate(0, 20px) scale(0.95)',
  filter: welcomeMessage2 ? 'blur(0)' : 'blur(10px)',
  transition: 'opacity 0.5s ease, transform 0.5s ease, filter 0.5s ease, left 0.5s ease'
}}>
  <WelcomeMessage>
    Fashion
  </WelcomeMessage>
  <WelcomeMessage>
    MarketPlace
  </WelcomeMessage>
</WelcomeContainer>




        <div style={{ position: 'relative', top: isMobile ? '600px' : '600px' }}>




            <h1 style={{ position: 'absolute', fontSize: `${4 * scaledText}rem`, top: '135vh', right: '20vw', transform: 'translate3d(0,-100%,0)' }}>Artists</h1>
            <h1 style={{ position: 'absolute', fontSize: `${4 * scaledText}rem`, top: '220vh', left: '5vw' }}>Graphic Designer</h1>
            <h1 style={{ position: 'absolute', fontSize: `${4 * scaledText}rem`, top: '270vh', right: '10vw' }}>Digital Creator</h1>
            <h1 style={{ position: 'absolute', fontSize: `${4 * scaledText}rem`, top: '360vh', left: '15vw' }}>Innovator</h1>
            <h1 style={{ position: 'absolute', fontSize: `${4 * scaledText}rem`, top: '440vh', right: '10vw' }}>Social Media Marketer</h1>
            <h1 style={{ position: 'absolute', fontSize: `${4 * scaledText}rem`, top: '510vh', left: '10vw' }}>Influencer</h1>

            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top:  `${619 - (isMobile ? 2 : 0) + (scaledThree * 8)}vh`, right: `${27 - (scaledThree * 5)}vw`, transform: 'translate3d(0,-100%,0)' }}>Artists</h1>
            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top:  `${627 - (isMobile ? -1 : 0) - (scaledThree * -1)}vh`, right: '14vw' }}>Graphic Designer</h1>
            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top:  `${647 + (isMobile ? -10 : 0) + (scaledThree * 7)}vh`, right:  `${21 - (scaledThree * 4)}vw`, transform: 'translate3d(0,-100%,0)' }}>Painter</h1>
            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top:  `${658 + (isMobile ? -6 : 0) + (scaledThree * 5)}vh`, right: '15vw' }}>Social Media Marketer</h1>
            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top: `${671 + (isMobile ? -5 : 0) + (scaledThree * 2)}vh`, right: `${25 + (scaledThree * 4)}vw` }}>Digital Creator</h1>

            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top:  `${618 + (scaledThree * 3)}vh`, left: `${28 + (scaledThree * 4)}vw`}}>3D Modeler</h1>
            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top: `${629  + (isMobile ? -3 : 0) + (scaledThree * 5)}vh`, left: `${17 - (scaledThree * 4)}vw`}}>Innovator</h1>
            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top: `${645 -  (scaledThree * 4) + (isMobile ? -2 : 0)}vh`, left: `${12 + (isMobile ? -5 : 0) +  (scaledThree * 2)}vw` }}>Clothing Designer</h1>
            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top: `${661 - (isMobile ? 7 : 0) + (scaledThree * 1.5)}vh`, left: `${22 - (scaledThree * 4)}vw` }}>Influencer</h1>
            <h1 style={{ position: 'absolute', fontSize: `${1.5 * scaledText}rem`, top: `${670 + (isMobile ? -9 : 0) +   (scaledThree * 1.5)}vh`, left: '27vw' }}>Illustrator</h1>
</div>
        
        </Scroll>
      </ScrollControls>
    </group>
    
    </>
    )
  );
}