import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import '../../model.css';
import {
  CollectionHeaderWrapper,
  FeaturedNFTCard,
  NFTCard, NFTCollectionSubheader, NFTCollectionTitle, NFTDescription, NFTGrid, NFTImage, NFTOwner, NFTPrice, NFTTitle,
  ViewDropsButton
} from './CardElements.js';
const gridVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const AnimatedCollectionWrapper = styled(motion.div)`
  width: ${({ isFullScreen, isMobile }) => isMobile ? '100%' : (isFullScreen ? '90%' : '40%')};
  float: left;
  height: 80vh;
  margin-left: 7.5%;
 //left: 50%;
  margin-left: ${({ isBrowseAllMode }) => (isBrowseAllMode ? 'initial' : '7.5%')};
  transform: ${({ isBrowseAllMode }) => (isBrowseAllMode ? 'translateX(-50%) !important' : 'none')};
  left: ${({ isBrowseAllMode }) => (isBrowseAllMode ? '50%' : 'initial')};
  bottom: 0;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999;
`;

export const TemporaryNFTCollection = ({ isDarkMode, isFullScreen, getPlaceholderUrl, isBrowseAllMode, onBrowseAllClick, onWearablesClick, onNFTsClick }) => {
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    const elements = document.querySelectorAll('.fade-on-scroll');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.01 // Trigger when 10% of the element is in view
      }
    );

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const tempNFTData = new Array(16).fill(null).map((_, index) => ({
    id: index,
    logo: getPlaceholderUrl(index),
    header: `NFT Project ${index + 1}`,
    name: `NFT Name ${index + 1}`,
    description: 'This is a temporary description for the NFT.',
    ethPrice: '0.05',
    owner: '0xABC...123'
  }));
  const featuredNFT = tempNFTData[0];

  return (
    <AnimatedCollectionWrapper
      initial={{ opacity: 0, y: -100, filter: 'blur(15px)', scale: 0 }}
      animate={{ opacity: 1, y: 0, filter: 'blur(0px)', scale: 1 }}
      transition={{ duration: 0.7 }}
      isFullScreen={isFullScreen}
      isMobile={isMobile}
      isBrowseAllMode={isBrowseAllMode}
    >
      <CollectionHeaderWrapper isMobile={isMobile}>
        <NFTCollectionTitle isDarkMode={isDarkMode} className="fade-on-scroll">Our NFT Collection</NFTCollectionTitle>
        <NFTCollectionSubheader isDarkMode={isDarkMode} className="fade-on-scroll">Explore the latest and greatest NFTs</NFTCollectionSubheader>
      </CollectionHeaderWrapper>

      <FeaturedNFTCard isDarkMode={isDarkMode} className="fade-on-scroll">
        <NFTImage src={featuredNFT.logo} alt="Featured NFT Logo" />
        <NFTTitle>{featuredNFT.header}</NFTTitle>
        <NFTDescription>{featuredNFT.name}</NFTDescription>
        <NFTDescription>{featuredNFT.description}</NFTDescription>
        <NFTPrice>{featuredNFT.ethPrice} ETH</NFTPrice>
        <NFTOwner>Owner: {featuredNFT.owner}</NFTOwner>
        <ViewDropsButton isDarkMode={isDarkMode}>View Latest Drops</ViewDropsButton>
      </FeaturedNFTCard>

      <NFTGrid isBrowseAllMode={isBrowseAllMode} isMobile={isMobile} isFullScreen={isFullScreen} variants={gridVariants} initial="hidden" animate="visible">
        {tempNFTData.slice(1).map((nft, index) => (
          <NFTCard key={nft.id} isMobile={isMobile} isBrowseAllMode={isBrowseAllMode} isFullScreen={isFullScreen} isDarkMode={isDarkMode} className="fade-on-scroll">
            <NFTImage src={nft.logo} alt="NFT Logo" />
            <NFTTitle>{nft.header}</NFTTitle>
            <NFTDescription>{nft.name}</NFTDescription>
            <NFTDescription>{nft.description}</NFTDescription>
            <NFTPrice>{nft.ethPrice} ETH</NFTPrice>
            <NFTOwner>Owner: {nft.owner}</NFTOwner>
          </NFTCard>
        ))}
      </NFTGrid>
    </AnimatedCollectionWrapper>
  );
};
