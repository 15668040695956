import React from 'react';
import '../../model.css';
import {
  FaColumnsIcon,
  FaExpandIcon,
  FaHomeIcon,
  FaTagsIcon,
  FaThLargeIcon,
  FaTshirtIcon,
  IconText,
  NavbarItem,
  NavbarItemLast,
  SearchIcon,
  SearchInput,
  SearchInputWrapper,
  SubNavbar2
} from "./SearchComponents";

export const SearchBar = ({ onBrowseAllClick, setIsSelected, setIsBrowseAllMode, onWearablesClick, onNFTsClick, isDarkMode, homeToggle, isBrowseAllMode, browseMode, setBrowseMode }) => {
  const isMobile = window.innerWidth <= 600; // Check if window width is 600px or less
  const browseToggle = () => {
    setBrowseMode(true);
    setIsSelected(false);
    if (isMobile) {
      setIsBrowseAllMode(true);
    }
  };
  

  return (
    <SubNavbar2
      initial={{ opacity: 0, y: browseMode ? -100 : 100, filter: 'blur(15px)' }}
      animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
      transition={{ duration: 0.5 }}
      isDarkMode={isDarkMode}
      browseMode={browseMode}
    >
      {browseMode ? (
        <>
          <SearchInputWrapper>
            <SearchInput type="text" placeholder="Search" isDarkMode={isDarkMode} />
            <SearchIcon isDarkMode={isDarkMode} />
          </SearchInputWrapper>

          <NavbarItem onClick={homeToggle}>
            <FaHomeIcon />
            <IconText>{isMobile ? 'HOME' : ''}</IconText>
            {!isMobile && 'HOME'}
          </NavbarItem>

          <NavbarItem onClick={onNFTsClick}>
            <FaTagsIcon />
            <IconText>{isMobile ? 'NFTs' : ''}</IconText>
            {!isMobile && 'NFTs'}
          </NavbarItem>

          {isMobile ? (
            <NavbarItemLast onClick={onWearablesClick}>
              <FaTshirtIcon />
              <IconText>{isMobile ? 'APPAREL' : ''}</IconText>
              {!isMobile && 'APPAREL'}
            </NavbarItemLast>
          ) : (
            <NavbarItem onClick={onWearablesClick}>
              <FaTshirtIcon />
              <IconText>{isMobile ? 'APPAREL' : ''}</IconText>
              {!isMobile && 'APPAREL'}
            </NavbarItem>
          )}

          {!isMobile && (
            <NavbarItemLast onClick={onBrowseAllClick} style={{ fontWeight: 'bold' }}>
              {isBrowseAllMode ? <FaColumnsIcon /> : <FaExpandIcon />}
              {isBrowseAllMode ? 'SPLIT VIEW' : 'FULL SCREEN'}
            </NavbarItemLast>
          )}
        </>
      ) : (
        <NavbarItemLast onClick={browseToggle}>
          <FaThLargeIcon />
          <IconText>{isMobile ? 'BROWSE COLLECTION' : ''}</IconText>
          {!isMobile && 'BROWSE COLLECTION'}
        </NavbarItemLast>
      )}
    </SubNavbar2>
  );
};
