import { a, config, useChain, useSpring, useSpringRef } from "@react-spring/three";
import { Html, useCursor, useGLTF } from '@react-three/drei';
import { Canvas, extend, useFrame, useLoader } from "@react-three/fiber";
import { EffectComposer, N8AO, Select, TiltShift2 } from "@react-three/postprocessing";
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { FaDiscord, FaInfoCircle, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import styled, { css, keyframes } from 'styled-components';
import { TextureLoader } from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import symbol from '../../images/w3oBlackLogo.png';
import icon from '../../images/w3oNameHorzBlack.png';
import icon2 from '../../images/w3oNameHorzWhite.png';
import { EnvDesktop } from "./ClosetModel";
// Closet Item Imports
import { Logo } from "./CardItems/Logo";
import { Symbol } from "./CardItems/Symbol";
import Chain from "./ClosetItems/Chain";
import FloorBag from "./ClosetItems/FloorBag";
import GlassBalls from "./ClosetItems/GlassBalls";
import Hat from "./ClosetItems/Hat";
import MiddleBag from "./ClosetItems/MiddleBag";
import MiddleShirt from "./ClosetItems/MiddleShirt";
import Mirror from "./ClosetItems/Mirror";
import Perfume from "./ClosetItems/Perfume";
import RedShirt from "./ClosetItems/RedShirt";
import RightShirt from "./ClosetItems/RightShirt";
import Shoes from "./ClosetItems/Shoes";
import TopBag from "./ClosetItems/TopBag";
// Extend the Three.js namespace to include TextGeometry
extend({ TextGeometry });

// Load the font for text rendering
const fontUrl = 'https://threejs.org/examples/fonts/helvetiker_regular.typeface.json';

const itemModels = {
  Chain: Chain,
  FloorBag: FloorBag,
  GlassBalls: GlassBalls,
  Hat: Hat,
  MiddleBag: MiddleBag,
  MiddleShirt: MiddleShirt,
  Mirror: Mirror,
  Perfume: Perfume,
  RedShirt: RedShirt,
  RightShirt: RightShirt,
  Shoes: Shoes,
  TopBag: TopBag,
};

const RotatingModel = ({ hoveredItem }) => {
  const ModelComponent = itemModels[hoveredItem];
  const ref = useRef();

  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.y += 0.01; // Adjust rotation speed as needed
    }
  });

  return ModelComponent ? <group scale={0.1} ref={ref}><ModelComponent /></group> : null;
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.2 // stagger the animation of children
    }
  },
  exit: { opacity: 0, transition: { duration: 0.5 } }
};
const childVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
};
// keyframes for the animations
const blurIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
    filter: blur(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
  }
`;

const scaleIn = keyframes`
  from {
    transform: scale(0.5);
    filter: blur(15px);
  }
  to {
    transform: scale(1);
    filter: blur(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8) blur(15px);
  }
  to {
    opacity: 1;
    transform: scale(1) blur(0px);
  }
`;

const rotateIn = keyframes`
  from {
    opacity: 0;
    transform: rotateX(-90deg) rotateZ(-90deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0) rotateZ(0);
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(200px) blur(15px);
  }
  to {
    opacity: 1;
    transform: translateX(0) blur(0px);
  }
`;
const CanvasWrapper = styled.div`
  width: 100vw; // Full width of the container
  height: 200px; // Fixed height, adjust as needed
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

// Adjust the CardContainer to accommodate the CanvasWrapper
const CardContainer = styled(motion.div)`
  position: absolute !important;
  width: 250px;
  overflow: hidden;
transform: translate(420px, -400px);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  background-color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#FFF'};
  color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#fff'};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;

  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(0px);
  background-color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.65)' : 'rgba(0, 0, 0, 0.65)'};
  backdrop-filter: blur(10px);
  opacity: 0.8;
`;

const ExpandedTopCard = styled(motion.div)`
  position: fixed;
  left: 7.5%;
  width: 40%;
  width: ${({ isMobile }) => isMobile ? '85%' : ' 40%'};
  //height: 35vh;
  height: ${({ isMobile }) => isMobile ? '44vh' : '44vh'};
  bottom: ${({ isMobile }) => isMobile ? '32.5%' : '32.5%'};


  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  background-color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#FFF'};
  color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#fff'};
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(0px);
  background-color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.85)' : 'rgba(0, 0, 0, 0.85)'};
  backdrop-filter: blur(10px);
  opacity: 0.8;
  overflow: hidden;
  animation: ${blurIn} 0.5s ease-in-out forwards;
`;

const ExpandedTopCardColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;



const ExpandedBottomCard = styled(motion.div)`
  position: fixed;
  left: 7.5%;
  width: 40%;
  width: ${({ isMobile }) => isMobile ? '85%' : ' 40%'};
  height: 20vh;
  height: ${({ isMobile }) => isMobile ? '20vh' : ' 20vh'};
bottom: 10%;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  background-color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#FFF'};
  color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#fff'};
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(0px);
  background-color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.85)' : 'rgba(0, 0, 0, 0.85)'};
  backdrop-filter: blur(10px);
  opacity: 0.8;
  //overflow: hidden;
  animation: ${blurIn} 0.8s ease-in-out forwards;
`;

const ExitIcon = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  cursor: pointer;
  font-size: 24px;
  z-index: 1;
  user-select: none;
`;

const LeftArrow = styled(Arrow)`
  left: 10px;
`;

const RightArrow = styled(Arrow)`
  right: 10px;
`;

const LogoImage = styled(motion.img)`
  width: 150px; // Adjust as needed
  height: auto;
`;
const SymbolImage = styled(motion.img)`
  width: 30px; // Adjust size as needed
  height: auto;
  margin-right: 10px; // Spacing between symbol and logo
`;

const Header = styled(motion.h1)`
  margin-top: 10px;
  font-size: 1.5em;
`;

const Description = styled(motion.p)`
  margin-top: 50px;
  text-shadow: 2px 0 2px black;
  text-transform: uppercase;
  font-size: 1.25rem;
 
`;

const Description2 = styled(motion.p)`
  margin-top: 5px;
  text-shadow: 2px 0 2px black;
  text-transform: uppercase;
  font-size: 0.9rem;
 
`;



// Base animation styles
const animationStyles = css`
  animation: ${fadeIn} 0.5s ease-in-out forwards;
`;

// Styled components for the tabs and content
const TabContainer = styled.div`
  margin-bottom: -20px;
  margin-top: -20px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#ccc' : '#333')};
`;

const Tab = styled.div`
  padding: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: ${({ isActive, isDarkMode }) => (isActive ? `2px solid yellow` : 'none')};
  color: ${({ isActive }) => (isActive ? 'yellow' : 'inherit')};
  
  &:hover {
    color: yellow;
    text-shadow: 0 0 10px yellow;
  }
`;
const TabContent = styled.div`
  padding: 10px;
  overflow-y: auto;
  max-height: 50vh;
`;

const TraitContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 25px;
  max-width: 100%; // Ensures the container doesn't exceed its parent's width
`;

const TraitList = styled(motion.div)`
  flex: 1 1 calc(25% - 20px);
  background: ${({ isDarkMode }) => (isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)')};
  border-radius: 10px;
  padding: 6px;
  text-align: center;
  animation: ${blurIn} 0.5s ease-in-out forwards;
`;

const TraitItem = styled.p`
  margin-bottom: -5px;
  font-size: 10px;
`;
const TraitItem2 = styled.p`
  margin-bottom: -5px;
  font-size: 8px;
`;
const TraitItem3 = styled.p`
  margin-bottom: -5px;
  font-size: 6px;
`;
const pageFlip = keyframes`
  0% { opacity: 0; transform: rotateX(-90deg); }
  100% { opacity: 1; transform: rotateX(0deg); }
`;

const AboutDescription = styled(motion.div)`
  margin-top: 20px;
  animation: ${blurIn} 0.8s ease-in-out forwards;
`;

const DetailsList = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  animation: ${slideIn} 0.5s ease-in-out forwards;

  a {
    color: #1DA1F2; // Light blue color for links
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;



const DetailItem = styled.p`
  margin-bottom: -5px;
`;

const SocialMediaIcons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  z-index: 9999;
  cursor: pointer;
  svg {
    transition: filter 0.3s ease-in-out, transform 0.5s ease-in-out;
    &:hover {
      filter: drop-shadow(0 0 10px white);
      transform: translateX(0);
    }
    &:nth-child(1) {
      animation: ${blurIn} 0.6s ease-in-out forwards;
    }
    &:nth-child(2) {
      animation: ${blurIn} 0.7s ease-in-out forwards;
    }
    &:nth-child(3) {
      animation: ${blurIn} 0.8s ease-in-out forwards;
    }
    &:nth-child(4) {
      animation: ${blurIn} 0.9s ease-in-out forwards;
    }
    &:nth-child(5) {
      animation: ${blurIn} 1s ease-in-out forwards;
    }
  }
`;

// Tab components
const Traits = ({ currentDetail, isDarkMode }) => {
  const [traitIndex, setTraitIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTraitIndex((prevIndex) => (prevIndex + 4) % currentDetail.traits.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentDetail.traits.length]);

  const displayedTraits = currentDetail.traits.slice(traitIndex, traitIndex + 4);

  return (
    <TabContent isDarkMode={isDarkMode}>
      <TraitContainer>
        {displayedTraits.map((trait, index) => (
          <TraitList key={index} isDarkMode={isDarkMode}>
            <TraitItem>{trait.kind}</TraitItem>
            <TraitItem2>{trait.description}</TraitItem2>
            <TraitItem3>{trait.price}</TraitItem3>
          </TraitList>
        ))}
      </TraitContainer>
    </TabContent>
  );
};

const Details = ({ currentDetail, isDarkMode }) => (
  <TabContent isDarkMode={isDarkMode}>
    <DetailsList>
      <DetailsColumn>
        <DetailItem><a href={`https://etherscan.io/address/${currentDetail.address}`} target="_blank" rel="noopener noreferrer">{currentDetail.address}</a></DetailItem>
        <DetailItem><a href={`https://etherscan.io/token/${currentDetail.tokenId}`} target="_blank" rel="noopener noreferrer">{currentDetail.tokenId}</a></DetailItem>
        <DetailItem>{currentDetail.tokenStandard}</DetailItem>
      </DetailsColumn>
      <DetailsColumn>
        <DetailItem>{currentDetail.chain}</DetailItem>
        <DetailItem>{currentDetail.earnings} <FaInfoCircle /></DetailItem>
      </DetailsColumn>
    </DetailsList>
  </TabContent>
);

const About = ({ currentDetail, isDarkMode }) => (
  <TabContent isDarkMode={isDarkMode}>
    <AboutDescription>{currentDetail.aboutDescription}</AboutDescription>
    <SocialMediaIcons>
      <FaDiscord />
      <FaInstagram />
      <FaTiktok />
      <FaYoutube />
      <FaXTwitter />
    </SocialMediaIcons>
  </TabContent>
);

const traits = [
  { kind: 'BACKGROUND', description: 'M1 Aquamarine', price: 'Floor: 1.65 ETH' },
  { kind: 'CLOTHES', description: 'M1 Tanktop', price: 'Floor: 1.65 ETH' },
  { kind: 'EYES', description: 'M1 3d', price: 'Floor: 1.65 ETH' },
  { kind: 'FUR', description: 'M1 Black', price: 'Floor: 1.65 ETH' },
  { kind: 'HAT', description: 'M1 Fez', price: 'Floor: 1.65 ETH' },
  { kind: 'MOUTH', description: 'M1 Bored Cigarette', price: 'Floor: 1.65 ETH' },
  { kind: 'ACCESSORY', description: 'M1 Gold Chain', price: 'Floor: 1.65 ETH' },
  { kind: 'STYLE', description: 'M1 Minimalistic', price: 'Floor: 1.65 ETH' },
];

function getRandomTraits() {
  const shuffled = traits.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, Math.floor(Math.random() * 7) + 2);
}

const itemDetailsList = [
  {
    group: 'Mirror',
    header: 'Sample Closet',
    name: 'Mirror',
    description: 'Reflect your style with elegance',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 172',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Mirror NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'TopBag',
    header: 'Sample Closet',
    name: 'TopBag',
    description: 'Carry your essentials in style',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 173',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the TopBag NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Perfume',
    header: 'Sample Closet',
    name: 'Perfume',
    description: 'Scent that defines your presence',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 174',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Perfume NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'RedShirt',
    header: 'Sample Closet',
    name: 'Red Shirt',
    description: 'Bold and stylish fashion choice',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 175',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the RedShirt NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'MiddleShirt',
    header: 'Sample Closet',
    name: 'Middle Shirt',
    description: 'Comfort and style in one',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 176',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the MiddleShirt NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'RightShirt',
    header: 'Sample Closet',
    name: 'Right Shirt',
    description: 'Perfect fit for any occasion',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 177',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the RightShirt NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'MiddleBag',
    header: 'Sample Closet',
    name: 'MiddleBag',
    description: 'Stylish bag for every day',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 178',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the MiddleBag NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'FloorBag',
    header: 'Sample Closet',
    name: 'FloorBag',
    description: 'Trendy and spacious bag',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 179',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the FloorBag NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'GlassBalls',
    header: 'Sample Closet',
    name: 'GlassBalls',
    description: 'Decorate with chic glass balls',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 180',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the GlassBalls NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Hat',
    header: 'Sample Closet',
    name: 'Merch Middle Hat',
    description: 'Top off your look with flair',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 181',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Hat NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Chain',
    header: 'Sample Closet',
    name: 'Merch Top',
    description: 'Add a touch of class',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 182',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Chain NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Shoes',
    header: 'Sample Closet',
    name: 'Merch Bottom Shoes',
    description: 'Step out in ultimate style',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 183',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Shoes NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  }
];

export const ItemDetailsCardExpanded = ({ details = [], isDarkMode,onClose, onNext, isMobile, onPrev, currentIndex = 0 }) => {
  const [activeTab, setActiveTab] = useState('about');

  if (!details || details.length === 0) return null;

  const logoSrc = isDarkMode ? icon : icon2;
  const currentDetail = details[currentIndex];

  if (!currentDetail) return null;

  return (
    <>
      <ExpandedTopCard
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        isDarkMode={isDarkMode}
        isMobile={isMobile}
        style={{ zIndex: 999999999 }} // Increase z-index
      >
        <ExitIcon onClick={onClose}>✖</ExitIcon>
        <LeftArrow onClick={() => { onPrev(); }}>◀</LeftArrow>
        <RightArrow onClick={() => { onNext(); }}>▶</RightArrow>
        <React.Fragment>
          <CanvasWrapper style={{ width: '100vw', height: '225px', marginTop: '25px' }}>
            <Canvas style={{ width: '100vw', height: '100vh' }} camera={{ position: [2, 2, 2], fov: 50 }}>
              <ambientLight intensity={0.5} />
              <pointLight position={[10, 10, 10]} intensity={0.8} />
              <spotLight position={[-10, 10, -10]} angle={0.15} penumbra={1} intensity={1} castShadow />
              <spotLight position={[10, -10, 10]} angle={0.15} penumbra={1} intensity={1} castShadow />
              <group scale={1}>
              <RotatingModel  hoveredItem={currentDetail.group} />
              </group>
              <EffectComposer>
                <N8AO halfRes aoSamples={32} aoRadius={0.5} distanceFalloff={0.5} intensity={1.5} />
                <TiltShift2 samples={5} blur={0.1} />
              </EffectComposer>
            </Canvas>
          </CanvasWrapper>
          <ExpandedTopCardColumn>
   
            <Description variants={childVariants}>{currentDetail.name}</Description>
          </ExpandedTopCardColumn>
        </React.Fragment>
      </ExpandedTopCard>
      <ExpandedBottomCard isMobile={isMobile}>
        <TabContainer isDarkMode={isDarkMode}>
        <Tab isActive={activeTab === 'about'} isDarkMode={isDarkMode} onClick={() => setActiveTab('about')}>About</Tab>
          <Tab isActive={activeTab === 'traits'} isDarkMode={isDarkMode} onClick={() => setActiveTab('traits')}>Traits</Tab>
    
          <Tab isActive={activeTab === 'details'} isDarkMode={isDarkMode} onClick={() => setActiveTab('details')}>Details</Tab>
        </TabContainer>
        {activeTab === 'about' && <About currentDetail={currentDetail} isDarkMode={isDarkMode} />}
        {activeTab === 'traits' && <Traits currentDetail={currentDetail} isDarkMode={isDarkMode} />}

        {activeTab === 'details' && <Details currentDetail={currentDetail} isDarkMode={isDarkMode} />}
      </ExpandedBottomCard>
    </>
  );
};



export const ItemDetailsCardHTML = ({ details, isDarkMode, objectRef, hoveredGroup, currentIndex }) => {
  if (!details) return null;

  const logoSrc = isDarkMode ? icon : icon2;
  const isSelected = details.group === itemDetailsList[currentIndex]?.group;

  return (
    <CardContainer
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      isDarkMode={isDarkMode}
      style={{ borderColor: isSelected ? 'yellow' : 'transparent', borderWidth: isSelected ? '2px' : '0', borderStyle: 'solid' }}
    >
      <CanvasWrapper style={{ width: '300px', height: '200px' }}>
        <Canvas style={{ width: '100%', height: '100%' }} camera={{ position: [2, 2, 2], fov: 50 }}>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} intensity={0.8} />
          <spotLight position={[-10, 10, -10]} angle={0.15} penumbra={1} intensity={1} castShadow />
          <spotLight position={[10, -10, 10]} angle={0.15} penumbra={1} intensity={1} castShadow />
          <RotatingModel hoveredItem={hoveredGroup} />
          <EffectComposer>
            <N8AO halfRes aoSamples={32} aoRadius={0.5} distanceFalloff={0.5} intensity={1.5} />
            <TiltShift2 samples={5} blur={0.1} />
          </EffectComposer>
        </Canvas>
      </CanvasWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SymbolImage src={symbol} alt="Symbol" variants={childVariants} />
        <LogoImage src={logoSrc} alt="Logo" variants={childVariants} />
      </div>
      <Header variants={childVariants}>{details.header}</Header>
      <Description2 variants={childVariants}>{details.name}</Description2>
      <Description2 variants={childVariants}>{details.description}</Description2>
    </CardContainer>
  );
};

const ItemDetailsCard3D = ({ details, isDarkMode, hoveredGroup }) => {
  // Define refs for each animation step
  const symbolRef = useSpringRef();
  const logoRef = useSpringRef();
  const rotatingModelRef = useSpringRef();

  // Define spring props for each animation step
  const symbolSpring = useSpring({
    ref: symbolRef,
    from: { scale: [0, 0, 0], rotation: [0, 0, Math.PI] },
    to: { scale: [0.5, 0.5, 0.5], rotation: [0, 0, 2 * Math.PI] },
    config: config.wobbly,
  });

  const logoSpring = useSpring({
    ref: logoRef,
    from: { scale: [0, 0, 0] },
    to: { scale: [0.5, 0.5, 0.5] },
    config: config.gentle,
  });

  const rotatingModelSpring = useSpring({
    ref: rotatingModelRef,
    from: { scale: [0, 0, 0], rotation: [0, 0, 0] },
    to: { scale: [0.5, 0.5, 0.5], rotation: [0, 2 * Math.PI, 0] },
    config: config.wobbly,
  });

  // Chain animations
  useChain([symbolRef, logoRef, rotatingModelRef], [0, 0.3, 0.6]);

  const cardSpring = useSpring({
    scale: [0.25, 0.25, 0.25],
    opacity: 1,
    from: { scale: [0, 0, 0], opacity: 0 },
    config: config.wobbly,
  });

  if (!details) return null;

  return (
    <a.group rotation={[0, Math.PI / 4, 0]} position={[1, 0.5, 1]} scale={cardSpring.scale} opacity={cardSpring.opacity}>
      <mesh>
        <planeBufferGeometry attach="geometry" args={[4, 2]} />
        <meshStandardMaterial
          attach="material"
          color={isDarkMode ? 'rgba(256, 256, 256, 0.75)' : 'rgba(0, 0, 0, 0.15)'}
          transparent
        />
      </mesh>
      <a.group position={[-1.2, 0.75, 0.01]} scale={symbolSpring.scale} rotation={symbolSpring.rotation}>
        <Symbol isDarkMode={isDarkMode} />
      </a.group>
      <a.group position={[0.5, 0.75, 0.01]} scale={logoSpring.scale}>
        <Logo isDarkMode={isDarkMode} />
      </a.group>
      <a.group position={[0, 0, 1.5]}>
        <Text isDarkMode={isDarkMode} position={[-1.8, 0.2, 0.01]}>{details.header}</Text>
        <Text isDarkMode={isDarkMode} position={[-1.8, 0, 0.01]}>{details.name}</Text>
        <Text isDarkMode={isDarkMode} position={[-1.8, -0.2, 0.01]}>{details.description}</Text>
        <a.group position={[0.6, 0.2, 0]} scale={rotatingModelSpring.scale} rotation={rotatingModelSpring.rotation}>
          <RotatingModel hoveredItem={hoveredGroup} />
        </a.group>
      </a.group>
    </a.group>
  );
};



function useHoverAnimation(ref, hoverColor, hoverEmissiveIntensity, isHovered) {
  const originalMaterials = useRef({});

  useEffect(() => {
    if (ref.current) {
      ref.current.traverse((child) => {
        if (child.isMesh) {
          if (child.material && 'color' in child.material && 'emissive' in child.material) {
            if (isHovered) {
              if (!originalMaterials.current[child.uuid]) {
                originalMaterials.current[child.uuid] = child.material;
              }
              const hoverMaterial = child.material.clone();
              hoverMaterial.color.set(hoverColor);
              hoverMaterial.emissive.set(hoverColor);
              hoverMaterial.emissiveIntensity = hoverEmissiveIntensity;
              child.material = hoverMaterial;
            } else if (originalMaterials.current[child.uuid]) {
              child.material = originalMaterials.current[child.uuid];
            }
          }
        }
      });
    }
  }, [isHovered, ref, hoverColor, hoverEmissiveIntensity]);

  const { scale, rotation, position } = useSpring({
    scale: isHovered ? 1.01 : 1,
    rotation: isHovered ? [-0.00025, 0.0025, 0.0025] : [0, 0, 0],
    position: isHovered ? [0, -0.2, 0.25] : [0, 0, 0],
    config: { mass: 5, tension: 400, friction: 50 }
  });

  return { scale, rotation, position };
}

const LogoTexture = ({ texturePath, position }) => {
  const texture = useLoader(TextureLoader, texturePath);
  return (
    <mesh position={position}>
      <planeBufferGeometry attach="geometry" args={[1, 0.5]} />
      <meshBasicMaterial attach="material" map={texture} transparent />
    </mesh>
  );
};

const Text = ({ children, position, isDarkMode }) => {
  const font = useLoader(FontLoader, fontUrl);
  return (
    <mesh position={position}>
      <textGeometry attach="geometry" args={[children, { font, size: 0.1, height: 0.035 }]} />
      <meshBasicMaterial attach="material" color={isDarkMode ? 'black' : 'white'} />
    </mesh>
  );
};

export default function W3oDefaultCloset({
  BLOOM_LAYER,
  setIsScrolling,
  isScrolling,
  scroll,
  onClickItem,
  focusedItem,
  setWhitelistPopup,
  canvasRef,
  setIsMouseVisible,
  setButtonPopup,
  setIsFooterVisible,
  isDarkMode,
  buttonPopup,
  setProgress,
  openWhitelistLink,
  scale,
  cardToggle,
  setCurrentIndex,
  perfSucks, orbitControlsEnabled,
  isSelected, setIsSelected,
  onPrev, onNext, onClose, currentIndex,
  mobile3Dcollecion, setMobile3Dcollecion,
  browseMode, setBrowseMode,
  setHoveredGroup, hoveredGroup,
  setHoveredItemDetails, hoveredItemDetails,
  props,
  selectedObject, setSelectedObject
}) {
  const group = useRef();
 

 

  const ClothesRef = useRef();
  const MirrorRef = useRef();
  const TopBagRef = useRef();
  const MiddleBagRef = useRef();
  const FloorBagRef = useRef();
  const GlassBallsRef = useRef();
  const MerchMiddleHatRef = useRef();
  const MerchMiddleRef = useRef();
  const MerchTopRef = useRef();
  const ShoesRef = useRef();
  const RedShirtRef = useRef();
  const PerfumeRef = useRef();
  const ChainRef = useRef();
  const HatRef = useRef();
  const MiddleShirtRef = useRef();
  const RightShirtRef = useRef();
  const hoveredGroupRef = useRef();

  const handleHover = (group, objectRef) => {
    setHoveredGroup(group);
    document.body.style.cursor = 'pointer';

    let details;

    switch (group) {
      case 'Mirror':
        details = {
          header: 'Sample Closet',
          name: 'Mirror',
          description: 'Reflect your style with elegance'
        };
        hoveredGroupRef.current = MirrorRef.current;
        break;

      case 'TopBag':
        details = {
          header: 'Sample Closet',
          name: 'TopBag',
          description: 'Carry your essentials in style'
        };
        hoveredGroupRef.current = TopBagRef.current;
        break;
      case 'Perfume':
        details = {
          header: 'Sample Closet',
          name: 'Perfume',
          description: 'Scent that defines your presence'
        };
        hoveredGroupRef.current = PerfumeRef.current;
        break;

      case 'RedShirt':
        details = {
          header: 'Sample Closet',
          name: 'Red Shirt',
          description: 'Bold and stylish fashion choice'
        };
        hoveredGroupRef.current = RedShirtRef.current;
        break;

      case 'MiddleShirt':
        details = {
          header: 'Sample Closet',
          name: 'Middle Shirt',
          description: 'Comfort and style in one'
        };
        hoveredGroupRef.current = MiddleShirtRef.current;
        break;

      case 'RightShirt':
        details = {
          header: 'Sample Closet',
          name: 'Right Shirt',
          description: 'Perfect fit for any occasion'
        };
        hoveredGroupRef.current = RightShirtRef.current;
        break;

      case 'MiddleBag':
        details = {
          header: 'Sample Closet',
          name: 'MiddleBag',
          description: 'Stylish bag for every day'
        };
        hoveredGroupRef.current = MiddleBagRef.current;
        break;

      case 'FloorBag':
        details = {
          header: 'Sample Closet',
          name: 'FloorBag',
          description: 'Trendy and spacious bag'
        };
        hoveredGroupRef.current = FloorBagRef.current;
        break;

      case 'GlassBalls':
        details = {
          header: 'Sample Closet',
          name: 'GlassBalls',
          description: 'Decorate with chic glass balls'
        };
        hoveredGroupRef.current = GlassBallsRef.current;
        break;

      case 'Hat':
        details = {
          header: 'Sample Closet',
          name: 'Merch Middle Hat',
          description: 'Top off your look with flair'
        };
        hoveredGroupRef.current = MerchMiddleHatRef.current;
        break;

      case 'Chain':
        details = {
          header: 'Sample Closet',
          name: 'Merch Top',
          description: 'Add a touch of class'
        };
        hoveredGroupRef.current = MerchTopRef.current;
        break;

      case 'Shoes':
        details = {
          header: 'Sample Closet',
          name: 'Merch Bottom Shoes',
          description: 'Step out in ultimate style'
        };
        hoveredGroupRef.current = ShoesRef.current;
        break;

      default:
        details = null;
    }

    setHoveredItemDetails(details);
  };

  const handleUnhover = (objectRef) => {
    if (hoveredGroup) {
      setHoveredGroup(null);
      setHoveredItemDetails(null);
      document.body.style.cursor = 'default';

      if (objectRef.current) {
        objectRef.current.traverse((child) => {
          if (child.isMesh) {
            child.layers.disable(BLOOM_LAYER);
          }
        });
      }
    }
  };
  const handleItemClick = (itemName) => {
    const index = itemDetailsList.findIndex(item => item.group === itemName);
    if (index !== -1) {
      setCurrentIndex(index);
      setHoveredGroup(null); // Clear the hover state
      setHoveredItemDetails(null); // Clear the hovered item details
      setIsSelected(itemName); // Set the selected item name
      setSelectedObject(itemName); // Ensure only the selected item is highlighted
      setBrowseMode(false);
    }
  };


  const handlePointerOver = (e, name, objectRef) => {
    e.stopPropagation();
    if (!isSelected || name === isSelected) {
      setSelectedObject(name);
      handleHover(name, objectRef);
      document.body.style.cursor = `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJnb2xkIiB0cmFuc2Zvcm09InJvdGF0ZSgxODBkZWcpIi8+CjxwYXRoIGQ9Ik02IDlDNy42NTY4NSA5IDkgNy42NTY4NSA5IDZDOSA0LjM0MzE1IDcuNjU2ODUgMyA2IDNDNC4zNDMxNSAzIDMgNC4zNDMxNSAzIDZDMyA3LjY1Njg1IDQuMzQzMTUgOSA2IDlaIiBmaWxsPSJnb2xkIiB0cmFuc2Zvcm09InJvdGF0ZSgxODBkZWcpIi8+Cjwvc3ZnPgo="), auto`;
    }
  };
  
  const handlePointerOut = (e, objectRef) => {
    e.stopPropagation();
    if (!isSelected) {
      setSelectedObject(null);
      handleUnhover(objectRef);
    }
  };
  
  





  // useAnimation on hover
  const hoverColor = '#00c5ff';
  const hoverEmissiveIntensity = 0.1;
  const clothesRefAnimation = useHoverAnimation(ClothesRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'Clothes');
  const mirrorAnimation = useHoverAnimation(MirrorRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'Mirror');
  const topBagAnimation = useHoverAnimation(TopBagRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'TopBag');
  const middleBagAnimation = useHoverAnimation(MiddleBagRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'MiddleBag');
  const floorBagAnimation = useHoverAnimation(FloorBagRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'FloorBag');
  const glassBallsAnimation = useHoverAnimation(GlassBallsRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'GlassBalls');
  const merchMiddleHatAnimation = useHoverAnimation(MerchMiddleHatRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'MerchMiddleHat');
  const merchMiddleAnimation = useHoverAnimation(MerchMiddleRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'MerchMiddle');
  const merchTopAnimation = useHoverAnimation(MerchTopRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'MerchTop');
  const merchBottomShoesAnimation = useHoverAnimation(ShoesRef, hoverColor, hoverEmissiveIntensity, hoveredGroup === 'MerchBottomShoesGroup');
  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/w3oUserDefaultCloset.glb');
  const [hovered, set] = useState(false);
  useCursor(hovered, `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="), auto`);

  useFrame((state, delta) => {
    const offset = 1 - scroll.offset;

    if (scroll.offset === 0 || scroll.offset === 1) {
      // No spinning at the top or bottom
    } else if (offset < scroll.offset) {
      setIsFooterVisible(false);
    } else if (offset > scroll.offset) {
      setIsFooterVisible(false);
    } else if (scroll.offset < 1) {
      setIsFooterVisible(false);
    }
    if (scroll.offset === 1) {
      // Show the footer when scrolling down (original condition)
    }
    if (scroll.offset === 0) {
      setIsFooterVisible(false);
      setIsScrolling(false);
    }
    if (scroll.offset !== 0) {
      setIsScrolling(true);
    }
  });

  const width = window.innerWidth;
  const isMobile = width <= 600; // Check if the window width is 600 or less

  const orbitControlsRef = useRef(); // Assuming you are using OrbitControls for camera manipulation

  return (
    <>
         <group>
        {hoveredItemDetails && (
        isMobile ? (
    <>

    </>
        ) : (isSelected || browseMode) ? (
         {/* { <ItemDetailsCard3D isDarkMode={isDarkMode} hoveredGroup={hoveredGroup} details={hoveredItemDetails} objectRef={hoveredGroupRef} />} */}
        ) : (
          <Html 
            center
            style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }}
            zIndexRange={[100, 0]}
          >
            <ItemDetailsCardHTML isDarkMode={isDarkMode} hoveredGroup={hoveredGroup} details={hoveredItemDetails} objectRef={hoveredGroupRef} />
          </Html>
        )
      )}

      { isSelected ? (      
     <></>): (<></>)}

 </group>
    <group>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ;;;;6SW"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      Ae38                                                                                                                                
      <pointLight position={[0, 10, 10]} intensity={0.1} />
  
      <group ref={group} {...props} onPointerOver={(e) => set(true)} onPointerOut={() => set(false)} scale={.13} dispose={null}>
        <mesh geometry={nodes.Cylinder004_Material_06936.geometry} material={materials['Material.092']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_1.geometry} material={materials['Material.093']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_2.geometry} material={nodes.Cylinder004_Material_06936_2.material} />
        <mesh geometry={nodes.Cylinder004_Material_06936_3.geometry} material={materials['Material.094']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_4.geometry} material={materials['Material.095']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_5.geometry} material={materials['Material.096']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_6.geometry} material={materials['Material.097']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_7.geometry} material={materials['Material.098']} />
        <mesh geometry={nodes.Cylinder004_Material_06935.geometry} material={materials['Material.086']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_1.geometry} material={nodes.Cylinder004_Material_06935_1.material} />
        <mesh geometry={nodes.Cylinder004_Material_06935_2.geometry} material={materials['PaletteMaterial001.011']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_3.geometry} material={materials['cupbord2.017']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_4.geometry} material={materials['cupbord2.018']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_5.geometry} material={materials['cupbord2.019']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_6.geometry} material={materials['cupbord2.020']} />

        <Select enabled={selectedObject === 'FloorBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'FloorBag')}>
  <a.group name="FloorBag" onPointerOver={(e) => handlePointerOver(e, 'FloorBag', FloorBagRef)} onPointerOut={(e) => handlePointerOut(e, FloorBagRef)} onClick={() => handleItemClick('FloorBag')}>
    <group position={[-3.94, 0.98, 5.04]} rotation={[Math.PI, -0.86, Math.PI]} scale={[0.03, 0.03, 0.03]}>
      <mesh geometry={nodes.bag_bag_0004.geometry} material={materials['material.002']} />
      <mesh geometry={nodes.bag_bag_0004_1.geometry} material={materials['belt.002']} />
      <mesh geometry={nodes.bag_bag_0004_2.geometry} material={materials['metal.002']} />
    </group>
  </a.group>
</Select>

<mesh geometry={nodes.Floor_Top001.geometry} material={materials['Material.071']} />

<Select enabled={selectedObject === 'MiddleBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleBag')}>
  <a.group name="Middle Bag" onPointerOver={(e) => handlePointerOver(e, 'MiddleBag', MiddleBagRef)} onPointerOut={(e) => handlePointerOut(e, MiddleBagRef)} onClick={() => handleItemClick('MiddleBag')}>
    <mesh geometry={nodes.Middle_Bag005.geometry} material={materials['map_BagWomen_01.006']} />
  </a.group>
</Select>

<Select enabled={selectedObject === 'Perfume' || (isSelected && itemDetailsList[currentIndex]?.group === 'Perfume')}>
  <a.group name="Perfume" onPointerOver={(e) => handlePointerOver(e, 'Perfume', PerfumeRef)} onPointerOut={(e) => handlePointerOut(e, PerfumeRef)} onClick={() => handleItemClick('Perfume')}>
    <mesh geometry={nodes.Perfume005.geometry} material={materials['PaletteMaterial004.003']} />
  </a.group>
</Select>

<Select enabled={selectedObject === 'RedShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'RedShirt')}>
  <a.group name="Red Shirt" onPointerOver={(e) => handlePointerOver(e, 'RedShirt', RedShirtRef)} onPointerOut={(e) => handlePointerOut(e, RedShirtRef)} onClick={() => handleItemClick('RedShirt')}>
    <group position={[0.39, 1.36, -3.59]} scale={[11.48, 11.48, 12.94]}>
      <mesh geometry={nodes.Plane249.geometry} material={materials['tshirtonhanger3.010']} />
      <mesh geometry={nodes.Plane249_1.geometry} material={materials['tshirtonhanger3.011']} />
    </group>
  </a.group>
</Select>

<Select enabled={selectedObject === 'TopBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'TopBag')}>
  <a.group name="Top Bag" onPointerOver={(e) => handlePointerOver(e, 'TopBag', TopBagRef)} onPointerOut={(e) => handlePointerOut(e, TopBagRef)} onClick={() => handleItemClick('TopBag')}>
    <mesh geometry={nodes.Top_Bag005.geometry} material={materials['bag1.006']} />
  </a.group>
</Select>

<Select enabled={selectedObject === 'RightShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'RightShirt')}>
  <a.group name="Right Shirt" onPointerOver={(e) => handlePointerOver(e, 'RightShirt', RightShirtRef)} onPointerOut={(e) => handlePointerOut(e, RightShirtRef)} onClick={() => handleItemClick('RightShirt')}>
    <mesh geometry={nodes.Chain002.geometry} material={nodes.Chain002.material} />
    <mesh geometry={nodes.Chain002_1.geometry} material={nodes.Chain002_1.material} />
    <mesh geometry={nodes.Chain002_2.geometry} material={materials['tsh3.008']} />
  </a.group>
</Select>

<Select enabled={selectedObject === 'Mirror' || (isSelected && itemDetailsList[currentIndex]?.group === 'Mirror')}>
  <a.group name="Mirror" onPointerOver={(e) => handlePointerOver(e, 'Mirror', MirrorRef)} onPointerOut={(e) => handlePointerOut(e, MirrorRef)} onClick={() => handleItemClick('Mirror')}>
    <mesh geometry={nodes.Chain004.geometry} material={nodes.Chain004.material} />
    <mesh geometry={nodes.Chain004_1.geometry} material={materials['PaletteMaterial003.003']} />
  </a.group>
</Select>

<mesh geometry={nodes.Stand_Top001.geometry} material={nodes.Stand_Top001.material} />

<Select enabled={selectedObject === 'Hat' || (isSelected && itemDetailsList[currentIndex]?.group === 'Hat')}>
  <a.group name="Hat" onPointerOver={(e) => handlePointerOver(e, 'Hat', HatRef)} onPointerOut={(e) => handlePointerOut(e, HatRef)} onClick={() => handleItemClick('Hat')}>
    <mesh geometry={nodes.Hat003.geometry} material={nodes.Hat003.material} />
  </a.group>
</Select>

<Select enabled={selectedObject === 'GlassBalls' || (isSelected && itemDetailsList[currentIndex]?.group === 'GlassBalls')}>
  <a.group name="Glass Balls" onPointerOver={(e) => handlePointerOver(e, 'GlassBalls', GlassBallsRef)} onPointerOut={(e) => handlePointerOut(e, GlassBallsRef)} onClick={() => handleItemClick('GlassBalls')}>
    <mesh geometry={nodes.Glass_Balls_Hanger001.geometry} material={nodes.Glass_Balls_Hanger001.material} />
  </a.group>
</Select>

<mesh geometry={nodes.GlassBalls003.geometry} material={nodes.GlassBalls003.material} />
<mesh geometry={nodes.FloorBottom001.geometry} material={nodes.FloorBottom001.material} />
<mesh geometry={nodes.Clothes_Rod001.geometry} material={nodes.Clothes_Rod001.material} />

<Select enabled={selectedObject === 'Shoes' || (isSelected && itemDetailsList[currentIndex]?.group === 'Shoes')}>
  <a.group name="Shoes" onPointerOver={(e) => handlePointerOver(e, 'Shoes', ShoesRef)} onPointerOut={(e) => handlePointerOut(e, ShoesRef)} onClick={() => handleItemClick('Shoes')}>
    <mesh geometry={nodes.Chain012.geometry} material={nodes.Chain012.material} />
    <mesh geometry={nodes.Chain012_1.geometry} material={materials['blinn5SG.006']} />
    <mesh geometry={nodes.Chain012_2.geometry} material={materials['blinn1SG.008']} />
    <mesh geometry={nodes.Chain012_3.geometry} material={materials['PaletteMaterial005.003']} />
    <mesh geometry={nodes.Chain012_4.geometry} material={materials['PaletteMaterial006.003']} />
    <mesh geometry={nodes.Chain012_5.geometry} material={materials['blinn3SG.006']} />
    <mesh geometry={nodes.Chain012_6.geometry} material={materials['blinn2SG.008']} />
    <mesh geometry={nodes.Chain012_7.geometry} material={nodes.Chain012_7.material} />
  </a.group>
</Select>

<Select enabled={selectedObject === 'Chain' || (isSelected && itemDetailsList[currentIndex]?.group === 'Chain')}>
  <a.group name="Chain" onPointerOver={(e) => handlePointerOver(e, 'Chain', ChainRef)} onPointerOut={(e) => handlePointerOut(e, ChainRef)} onClick={() => handleItemClick('Chain')}>
    <mesh geometry={nodes.Chain014.geometry} material={nodes.Chain014.material} />
    <mesh geometry={nodes.Chain014_1.geometry} material={nodes.Chain014_1.material} />
  </a.group>
</Select>

<mesh geometry={nodes.Stand001.geometry} material={nodes.Stand001.material} />

<Select enabled={selectedObject === 'MiddleShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleShirt')}>
  <a.group name="MiddleShirt" onPointerOver={(e) => handlePointerOver(e, 'MiddleShirt', MiddleShirtRef)} onPointerOut={(e) => handlePointerOut(e, MiddleShirtRef)} onClick={() => handleItemClick('MiddleShirt')}>
    <mesh geometry={nodes.Cylinder004_Material_06042.geometry} material={materials['default.006']} />
    <mesh geometry={nodes.Cylinder004_Material_06042_1.geometry} material={nodes.Cylinder004_Material_06042_1.material} />
    <mesh geometry={nodes.Cylinder004_Material_06042_2.geometry} material={nodes.Cylinder004_Material_06042_2.material} />
  </a.group>
</Select>


        <mesh geometry={nodes.Plane379.geometry} material={materials['cupbord2.012']} />
        <mesh geometry={nodes.Plane379_1.geometry} material={materials['Material.075']} />
        <mesh geometry={nodes.Plane379_2.geometry} material={materials['PaletteMaterial001.009']} />
        <mesh geometry={nodes.Plane379_3.geometry} material={materials['Material.076']} />
        <mesh geometry={nodes.Plane379_4.geometry} material={nodes.Plane379_4.material} />
        <mesh geometry={nodes.Plane379_5.geometry} material={materials['PaletteMaterial001.010']} />
        <mesh geometry={nodes.Plane379_6.geometry} material={materials['Material.077']} />
      </group>
    </group>
    <EnvDesktop BLOOM_LAYER={BLOOM_LAYER} orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} />
    </>
  );
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/w3oUserDefaultCloset.glb');
