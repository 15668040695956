
import {
    ExitIcon, ExitIconWrap,
    PExit,
    ProjectDescription,
    ProjectDetails,
    ProjectGraphic,
    ProjectPopup,
    ProjectTitle
} from "./ProjectElements";



export const ProjectPopUp = ({  isDarkMode, handleCloseAbout, placeholderImageUrl }) => {
    return (
        <ProjectPopup>
        <ProjectGraphic src={placeholderImageUrl} alt="Project Graphic" />
        <ProjectDetails>
          <ProjectTitle>Project Title</ProjectTitle>
          <hr /> {/* Horizontal line */}
          <ProjectDescription>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </ProjectDescription>
        </ProjectDetails>
        <PExit isDarkMode={isDarkMode} className='close-btn'  onClick={handleCloseAbout}>
          <ExitIconWrap
            whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
            whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
          >
            <ExitIcon whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
          </ExitIconWrap>
        </PExit>
      </ProjectPopup>
    );
  };
  