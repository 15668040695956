import React, { useState } from 'react';

//import emailjs from 'emailjs-com';



//import coinbaseWalletModule from '@web3-onboard/coinbase';
//import Onboard from '@web3-onboard/core';
//import injectedModule from '@web3-onboard/injected-wallets';

import {
  AnimateBubbles,
  AnimateBubbles2,
  ButtonText,
  ButtonText2,
  Customize,
  CustomizeIcon,
  InspectModel,
  InspectModelWrap,
  ModelSwitch,
  ModelSwitchIcon,
  OverlayButtonRow,
  OverlayButtonWrapper,
  OverlayContainer,
  Shop,
  ShopIcon,
  TextSpan,
  TextSpan2,
  TopButtonWrapper,
  Utilities,
  UtilitiesIcon
} from './OverlayElements';
/*
const MAINNET_RPC_URL = "https://goerli.infura.io/v3/7c9d1c18f6244605b8d57b8dd612aa7e";
const injected = injectedModule();
const walletConnect = walletConnectModule()
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: false })


const onboard = Onboard({
  wallets: [
      injected,
      walletConnect,
      coinbaseWalletSdk
  ],
  chains: [
    {
      id: '0x5',
      token: 'gETH',
      label: 'Goerli Testnet',
      rpcUrl: MAINNET_RPC_URL
    }
  ]
});
*/

export function ClosetOverlay({ setIsMouseVisible, onEffectChange, orbitControlsEnabled, setOrbitControlsEnabled, buttonPopup, setCurrentState, currentState, toggleOrbitControls, toggleUtilities, isDarkMode, setModelSwitch, modelSwitch, buttonPopup2, buttonPopup3, setButtonPopup, setButtonPopup2, setButtonPopup3 ,props}) {
    const [walletAddress, setWalletAddress] = useState("");
    const [hover, setHover] = useState(false);
    const [isShopHovered, setIsShopHovered] = useState(false);
    const handleMouseVisibility = () => {
      setIsMouseVisible(true);
    };
   
    const width = window.innerWidth;
    //const mValue = width <= 600  ? 15 : 0;
    const opacityValue = orbitControlsEnabled ? 0 : 1;
    const baseYValue = orbitControlsEnabled ? (75) : 0;
    const yValue = (width <= 600 && orbitControlsEnabled) ? baseYValue - 35 : baseYValue;
   // const yValue = orbitControlsEnabled ? 75 : 0;
    const sValue = orbitControlsEnabled ? 1 : 0;

    const displayValue = orbitControlsEnabled ? 'none' : 'initial';
    const handleButtonClick = () => {
      // Call existing logic for toggleOrbitControls
      setOrbitControlsEnabled(false);
      
      // Call logic for toggling the modelSwitch state
      setModelSwitch(!modelSwitch);
    };
    const handleShopHover = () => {
      setIsShopHovered(true);
    };
    const handleShopMouseLeave = () => {
      setIsShopHovered(false);
    };


    const [isCustomizeHovered, setIsCustomizeHovered] = useState(false);
  
  
    const handleCustomizeHover = () => {
      setIsCustomizeHovered(true);
    };
    const handleCustomizetMouseLeave = () => {
      setIsCustomizeHovered(false);
    };


    const [buttonHovered, setButtonHovered] = useState(false);
    const onHover = () => {
        setHover(!hover);
    }
    const toggleEffect = () => {
      if (onEffectChange) {
        onEffectChange();
      }
    };
    const handleClick = () => {
      toggleOrbitControls();
      handleMouseVisibility();
    };

    /*

    async function loadWeb3() {
        try {
            const wallets = await onboard.connectWallet();
            let web3 = window.web3 = new Web3(wallets[0].provider);
            const account = wallets[0].accounts[0].address;
            console.log("account", account)
            const msgToSign = web3.utils.sha3("Wallet Verification Message for Web3 Outfitters.");
            const signature = await web3.eth.personal.sign(msgToSign, account);
            const signingAddress = web3.eth.accounts.recover(msgToSign, 
            signature);
            console.log("signingAddress", signingAddress)
            if (account.toLowerCase() === signingAddress.toLowerCase()) {
                window.account = account;
                setWalletAddress(account);
            }
            return [(account.toLowerCase() === signingAddress.toLowerCase()), account];
        }
        catch (e) {
            console.log(e);
            return [false, ""];
        }
    }

    var user = {
      name: walletAddress,
      avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      email: 'test@example.com'
    }
    // const user = useContext(UserContext);
    console.log(user);
    */
    return (
      <>
      
<OverlayContainer >
  <OverlayButtonWrapper>
    <OverlayButtonRow>

      <Utilities      style={{display: displayValue, opacity: opacityValue }}     >
     
      
          <AnimateBubbles2
           // to="/"
           isDarkMode={isDarkMode}
           onClick={toggleUtilities} 
            primary="true"
            dark="true"
            fontBig="true"
          >
            <ButtonText><TextSpan  isDarkMode={isDarkMode}>HOW IT WORKS</TextSpan> 
            <UtilitiesIcon isDarkMode={isDarkMode}/>
            </ButtonText>
          </AnimateBubbles2>
      
      </Utilities>


      <ModelSwitch style={{ opacity: opacityValue }}>
        <AnimateBubbles
                isDarkMode={isDarkMode}
                primary="true"
                dark="true"
                fontBig="true"
                className={buttonHovered ? 'animate' : ''}
                
                onClick={() => setCurrentState(currentState === 1 ? 2 : 1)} 
                onMouseEnter={() => setButtonHovered(true)}
                onMouseLeave={() => setButtonHovered(false)}
              >
    
        {/* Add more buttons if needed */}
 
          <ButtonText2> <TextSpan2 isDarkMode={isDarkMode}  style={{ marginLeft: '0px' }}>TOGGLE</TextSpan2>
            <ModelSwitchIcon    isDarkMode={isDarkMode} />
          </ButtonText2>
        </AnimateBubbles>
      </ModelSwitch>




      <Shop  
      style={{display: displayValue,  opacity: opacityValue }}    
       onMouseEnter={handleShopHover}
       onMouseLeave={handleShopMouseLeave}>
     

          <AnimateBubbles2
           // to="/"
           isDarkMode={isDarkMode}
            primary="true"
            dark="true"
            fontBig="true"
            //onClick={() => {setButtonPopup3(true); setButtonPopup(false);  setButtonPopup2(false); }}
          onClick={() => {setButtonPopup(true); setButtonPopup3(false);  setButtonPopup2(false); }}
          //onClick={() => {console.log(setButtonPopup); console.log(setButtonPopup2);  console.log(setButtonPopup3); }}
          >
            <ButtonText><TextSpan  isDarkMode={isDarkMode}>APPAREL</TextSpan>
            <ShopIcon    isDarkMode={isDarkMode} />
            </ButtonText>
          </AnimateBubbles2>

                          
         
      </Shop>



<TopButtonWrapper  transition={  { delay: 0}}
                             initial ={ { scale: 0, opacity: 0, y:-20  }} // Start with flip
                            animate = {{ scale: 1 + sValue, opacity: 1, y:yValue }} // End with no flip
                            final = {{ scale: 1, opacity: 1, y:0}} >
      <Customize 
      orbitControlsEnabled={orbitControlsEnabled}
             isDarkMode={isDarkMode}> 
  
      <AnimateBubbles  onMouseEnter={handleCustomizeHover}
                       onMouseLeave={handleCustomizetMouseLeave}
                       orbitControlsEnabled={orbitControlsEnabled}
                       isDarkMode={isDarkMode}
                       primary="true"
                       dark="true"
                       fontBig="true"
                       onClick={() => {setButtonPopup2(true); setButtonPopup(false);  setButtonPopup3(false); }}
               // className={buttonHovered ? 'animate' : ''}
                
            
                //onMouseEnter={() => setButtonHovered(true)}
                //onMouseLeave={() => setButtonHovered(false)}
              >
    
        {/* Add more buttons if needed */}
 
            <ButtonText2> <TextSpan2  orbitControlsEnabled={orbitControlsEnabled} isDarkMode={isDarkMode}  style={{ marginLeft: '0px' }}>CUSTOMIZE</TextSpan2>
            <CustomizeIcon     orbitControlsEnabled={orbitControlsEnabled} isDarkMode={isDarkMode} />
            </ButtonText2>
            </AnimateBubbles>
   

  

      <InspectModelWrap     orbitControlsEnabled={orbitControlsEnabled}>
                       
                  <AnimateBubbles
                            isDarkMode={isDarkMode}
                            primary="true"
                            dark="true"
                            fontBig="true"
                            onClick={handleClick}
                            style={{ width: '100px' }}
                            orbitControlsEnabled={orbitControlsEnabled}
                          // className={buttonHovered ? 'animate' : ''}
                            
                        
                            //onMouseEnter={() => setButtonHovered(true)}
                            //onMouseLeave={() => setButtonHovered(false)}
                          >
                

            
                      
                            <InspectModel     orbitControlsEnabled={orbitControlsEnabled} isDarkMode={isDarkMode} />
                        </AnimateBubbles>
     
        
             </InspectModelWrap>
             </Customize>
</TopButtonWrapper>

    </OverlayButtonRow>
    
  </OverlayButtonWrapper>

</OverlayContainer>

</>
    );
  }
  
  export default ClosetOverlay;