import React, { useEffect, useState } from 'react';
//import emailjs from 'emailjs-com';
import { motion, useAnimation } from 'framer-motion';
import { useSpring } from 'react-spring';
//import { MasterPlan } from '../../../components/MasterPlan/MasterPlan';
import './Overlay.css';
import {
  AnimateBubbles,
  AnimateBubbles2,
  ButtonText,
  ButtonText2,
  ModelSwitch,
  ModelSwitchIcon,
  OverlayButtonRow,
  OverlayButtonWrapper,
  OverlayContainer,
  Roadmap,
  RoadmapIcon,
  TextSpan,
  TextSpan2,
  Utilities,
  UtilitiesIcon
} from './OverlayElements';
function DaoOverlay({setIsMouseVisible, onEffectChange, started, currentState, setCurrentState, toggleUtilities, isDarkMode, modelSwitch, setModelSwitch, planSwitch, setPlanSwitch, props, scroll, setTrigger4, trigger4, setPlanPopup, planPopup, buttonPopup4}) {
  const [buttonHovered, setButtonHovered] = useState(false);
    const [buttonPopup, setButtonPopup] = useState(false);
    const [shouldHideOverlay, setShouldHideOverlay] = useState(false);
    const overlayControls = useAnimation(); // Animation controls for the overlay

   const pageTransition = useSpring({
    from: { scale: 0, opacity: 0 },
    to: { scale: 1, opacity: 1 },
  });
    useEffect(() => {
      // Check the scroll prop to determine if the component should be hidden
      if (scroll > 0) {
        setShouldHideOverlay(true);
      }
    }, [shouldHideOverlay, scroll]);

    
    return (
      <>
      {(!shouldHideOverlay && started) && (
        
        <OverlayContainer
        initial={{ opacity: 0}}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0}}
          transition={{ duration: 0.5 }}
          className={`${started ? "" : "hidden"}`}
        >
          {/* Your animated elements */}
          <motion.div
          initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            exit={{ opacity: 1}}
          >

  <OverlayButtonWrapper>

    <OverlayButtonRow>
    <Utilities >
     
      
     <AnimateBubbles2
      // to="/"
      isDarkMode={isDarkMode}
      onClick={toggleUtilities} 
       primary="true"
       dark="true"
       fontBig="true"
     >
       <ButtonText><TextSpan isDarkMode={isDarkMode}>HOW IT WORKS</TextSpan> 
       <UtilitiesIcon isDarkMode={isDarkMode}/>
       </ButtonText>
     </AnimateBubbles2>
 
 </Utilities>




      <Roadmap >
      
          <AnimateBubbles2
             // to="/"
           isDarkMode={isDarkMode}
            //  to='/'
            primary="true"
            dark="true"
            fontBig="true"
            onClick={() => setCurrentState(3)} 
            >
           <ButtonText><TextSpan  isDarkMode={isDarkMode} style={{ marginLeft: '0px' }}>MASTER PLAN</TextSpan> 
            <RoadmapIcon isDarkMode={isDarkMode} />
            </ButtonText>
          </AnimateBubbles2>
      
      </Roadmap>



      <ModelSwitch isDarkMode={isDarkMode}>        
      <AnimateBubbles
                isDarkMode={isDarkMode}
                primary="true"
                dark="true"
                fontBig="true"
                className={buttonHovered ? 'animate' : ''}
                onClick={() => setCurrentState(currentState === 1 ? 2 : 1)} 
                onMouseEnter={() => setButtonHovered(true)}
                onMouseLeave={() => setButtonHovered(false)}
              >
        {/* Add more buttons if needed */}
 
            <ButtonText2> <TextSpan2 isDarkMode={isDarkMode}  style={{ marginLeft: '0px' }}>TOGGLE</TextSpan2>
            <ModelSwitchIcon    isDarkMode={isDarkMode} />
            </ButtonText2>
            </AnimateBubbles>
       
      </ModelSwitch>


    </OverlayButtonRow>
  </OverlayButtonWrapper>
  </motion.div>
</OverlayContainer>
     )}

</>
    );
  }
  
  export default DaoOverlay;