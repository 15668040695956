import { useAnimations, useGLTF } from '@react-three/drei';
import { editable as e } from "@theatre/r3f";
import { useEffect, useRef } from 'react';
import { MeshStandardMaterial } from 'three';

export default function Mannequin({isDarkMode, props}) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/walkingMannequin.glb');
  const { actions } = useAnimations(animations, group);

  // Define materials
  const darkModeMaterial = new MeshStandardMaterial({ color: 'black', metalness: 0.1, roughness: 0.9 });
  const lightModeMaterial = materials['Scene_-_Root']; // Or define a specific light mode material

  useEffect(() => {
    const actionName = 'mixamo.com';
    if (actions[actionName]) {
      actions[actionName].setEffectiveTimeScale(0.4);
      actions[actionName].reset().fadeIn(0.5).play();
    }
  }, [actions]);

  // Update material based on isDarkMode
  useEffect(() => {
    const skinnedMesh = group.current.getObjectByName('Object_7'); // Adjust 'Object_7' if needed
    if (skinnedMesh) {
      skinnedMesh.material = isDarkMode ? darkModeMaterial : lightModeMaterial;
    }
  }, [isDarkMode, darkModeMaterial, lightModeMaterial]);

  return (
    <e.group scale={2500} ref={group} theatreKey='mannequin' {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes._rootJoint} />
          <skinnedMesh
            name="Object_7" // Ensure this matches the name in your GLTF model
            geometry={nodes.Object_7.geometry}
            material={isDarkMode ? darkModeMaterial : lightModeMaterial}
            skeleton={nodes.Object_7.skeleton}
          />
        </group>
      </group>
    </e.group>
  );
}

useGLTF.preload('/walkingMannequin.glb');
