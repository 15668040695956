import { motion } from 'framer-motion';
import { MdCancel } from 'react-icons/md';
import styled from 'styled-components';

export const ProjectPopup = styled.div`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: white;
padding: 20px;
border-radius: 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
display: flex;
align-items: center;
z-index: 100;
`;

export const ProjectGraphic = styled.img`
height: auto; // Adjust size as needed
width: 200px; // Adjust size as needed
margin-right: 20px;
`;

export const ProjectDetails = styled.div`
display: flex;
flex-direction: column;
`;

export const ProjectTitle = styled.h2`
margin-bottom: 10px;
`;

export const ProjectDescription = styled.p`
margin-top: 10px;
`;

  //ExitIcon
  
  export const ExitIcon = styled(MdCancel)` 
   cursor: pointer;
   // background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    border-radius: 100%;
  
   // transform: translate(10px, 10px) rotate()(180deg);
    transition: transform 3300ms ease-in-out;
    font-size: 32px; /* Adjust the font size as per your requirements */
  //z-index: -10000000;
  transform-origin: center;
  display: flex;
    justify-content: center;
    align-items: center;
      //display: flex;
      &:hover {
     
  
  }
  
  `;
  export const PExit = styled(motion.div)`
      position: absolute;
  
      right: 2.5%;
      transition: transform 30ms ease-in-out;
      top: 2.5%;
      cursor: pointer;
      transform-origin: center;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
   
  
     &:hover {
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      //background-color: #000;
      //transform: translate(10px, 10px) rotate()(360deg);
        //right: -5px;
        //filter: drop-shadow(0 0 10px white);
    }
    &:hover ${ExitIcon} {
      transform-origin: center;
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
     // background-color: #000;
     // transform:  rotateZ(180deg);
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
        filter: drop-shadow(0 0 5px white);
    }
  
  
  `
  
  export const ExitIconWrap = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center; /* Set the transform origin to the center */
  
  cursor: pointer;
    `