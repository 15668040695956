import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import '../../model.css';
import {
  CollectionHeaderWrapper,
  FeaturedWearableCard,
  ViewDropsButton,
  WearableCard,
  WearableCollectionSubheader,
  WearableCollectionTitle,
  WearableDescription,
  WearableGrid,
  WearableImage,
  WearableOwner,
  WearablePrice,
  WearableTitle
} from './CardElements.js';
const gridVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};
const AnimatedCollectionWrapper = styled(motion.div)`
  width: ${({ isFullScreen, isMobile }) => isMobile ? '100%' : (isFullScreen ? '90%' : '40%')};
  float: left;
  height: 80vh;
  margin-left: 7.5%;
 //left: 50%;
  margin-left: ${({ isBrowseAllMode }) => (isBrowseAllMode ? 'initial' : '7.5%')};
  transform: ${({ isBrowseAllMode }) => (isBrowseAllMode ? 'translateX(-50%) !important' : 'none')};
  left: ${({ isBrowseAllMode }) => (isBrowseAllMode ? '50%' : 'initial')};
  bottom: 0;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999;
`;


  export const TemporaryWearableCollection = ({isDarkMode, isFullScreen, isBrowseAllMode, getPlaceholderUrl, onBrowseAllClick, onWearablesClick, onNFTsClick }) => {
    const isMobile = window.innerWidth <= 600;

    useEffect(() => {
      const elements = document.querySelectorAll('.fade-on-scroll');
  
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
            } else {
              entry.target.classList.remove('visible');
            }
          });
        },
        {
          threshold: 0.01 // Trigger when 10% of the element is in view
        }
      );
  
      elements.forEach((element) => {
        observer.observe(element);
      });
  
      return () => {
        elements.forEach((element) => {
          observer.unobserve(element);
        });
      };
    }, []);
    const tempWearableData = new Array(16).fill(null).map((_, index) => ({
      id: index,
      logo: getPlaceholderUrl(index),
      header: `Physical Wearable Project ${index + 1}`,
      name: `Physical Wearable Name ${index + 1}`,
      description: 'This is a temporary description for the Wearable.',
      ethPrice: '0.05',
      owner: '0xABC...123'
    }));
    const featuredWearable = tempWearableData[0];
  
    return (
      <AnimatedCollectionWrapper
      initial={{ opacity: 0, y: -100, filter: 'blur(15px)', scale: 0 }}
      animate={{ opacity: 1, y: 0, filter: 'blur(0px)', scale: 1 }}
      transition={{ duration: 0.7 }}
      isFullScreen={isFullScreen}
      isMobile={isMobile}
      isBrowseAllMode={isBrowseAllMode}
    >
  


  <CollectionHeaderWrapper isMobile={isMobile}>
        <WearableCollectionTitle className="fade-on-scroll" isDarkMode={isDarkMode}>Our Physical Wearable Collection</WearableCollectionTitle>
        <WearableCollectionSubheader className="fade-on-scroll" isDarkMode={isDarkMode}>Explore the latest and greatest Physical Wearables</WearableCollectionSubheader>
        </CollectionHeaderWrapper>
          <FeaturedWearableCard className="fade-on-scroll" isDarkMode={isDarkMode}>
            <WearableImage src={featuredWearable.logo} alt="Featured Wearable Logo" />
            <WearableTitle>{featuredWearable.header}</WearableTitle>
            <WearableDescription>{featuredWearable.name}</WearableDescription>
            <WearableDescription>{featuredWearable.description}</WearableDescription>
            <WearablePrice>{featuredWearable.ethPrice} ETH</WearablePrice>
            <WearableOwner>Owner: {featuredWearable.owner}</WearableOwner>
            <ViewDropsButton isDarkMode={isDarkMode}>View Latest Drops</ViewDropsButton>
          </FeaturedWearableCard>


        <WearableGrid isBrowseAllMode={isBrowseAllMode} isMobile={isMobile}  isFullScreen={isFullScreen}  variants={gridVariants} initial="hidden" animate="visible">

          {tempWearableData.slice(1).map(wearable => (
            <WearableCard className="fade-on-scroll" isBrowseAllMode={isBrowseAllMode} isMobile={isMobile} isFullScreen={isFullScreen} key={wearable.id} isDarkMode={isDarkMode}>
              <WearableImage src={wearable.logo} alt="Wearable Logo" />
              <WearableTitle>{wearable.header}</WearableTitle>
              <WearableDescription>{wearable.name}</WearableDescription>
              <WearableDescription>{wearable.description}</WearableDescription>
              <WearablePrice>{wearable.ethPrice} ETH</WearablePrice>
              <WearableOwner>Owner: {wearable.owner}</WearableOwner>
            </WearableCard>
          ))}
        </WearableGrid>
        </AnimatedCollectionWrapper>
    );
  };