import { a, useSpring } from "@react-spring/three";
import { Html, Scroll, ScrollControls, useScroll } from '@react-three/drei';
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { EffectComposer, N8AO, Outline, TiltShift2, ToneMapping } from "@react-three/postprocessing";
import { AnimatePresence, motion } from 'framer-motion';
import { easing } from 'maath';
import React, { Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import * as THREE from 'three';
import Footer from '../../components/Footer/Footer.js';
import icon2 from '../../images/w3oNameHorzWhite.png';
import { BlurBackground, ClosetBottom, ClosetTop, Mouse, MouseBody, Paragraph, WebLogo, WebLogoCont } from "../JoinDao/ModelElements";
import ClosetModel from './ClosetModel.js';
import {
  MainMouseBody,
  MouseIcon,
  ScrollText
} from "./CompanyElements";
import { SearchBar } from "./Components/NavComponents/SearchBar.js";
import { MainSubNavbar } from "./Components/NavComponents/SubNavbar.js";
import { TemporaryNFTCollection } from "./Components/PageComponets/NFTPage.js";
import { TemporaryWearableCollection } from "./Components/PageComponets/WearablePage.js";
import { ProjectPopUp } from "./Components/ProjectPopup/Project.js";
import CustomClosetOverlay from './CustomClosetOverlay.js';
import { ItemDetailsCardExpanded } from "./W3oUserDefaultCloset.js";
const FullSizeHtml = styled(Html)`
 // width: 85vw;
`;

const traits = [
  { kind: 'BACKGROUND', description: 'M1 Aquamarine', price: 'Floor: 1.65 ETH' },
  { kind: 'CLOTHES', description: 'M1 Tanktop', price: 'Floor: 1.65 ETH' },
  { kind: 'EYES', description: 'M1 3d', price: 'Floor: 1.65 ETH' },
  { kind: 'FUR', description: 'M1 Black', price: 'Floor: 1.65 ETH' },
  { kind: 'HAT', description: 'M1 Fez', price: 'Floor: 1.65 ETH' },
  { kind: 'MOUTH', description: 'M1 Bored Cigarette', price: 'Floor: 1.65 ETH' },
  { kind: 'ACCESSORY', description: 'M1 Gold Chain', price: 'Floor: 1.65 ETH' },
  { kind: 'STYLE', description: 'M1 Minimalistic', price: 'Floor: 1.65 ETH' },
];

function getRandomTraits() {
  const shuffled = traits.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, Math.floor(Math.random() * 7) + 2);
}

const itemDetailsList = [
  {
    group: 'Mirror',
    header: 'Sample Closet',
    name: 'Mirror',
    description: 'Reflect your style with elegance',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 172',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Mirror NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'TopBag',
    header: 'Sample Closet',
    name: 'TopBag',
    description: 'Carry your essentials in style',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 173',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the TopBag NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Perfume',
    header: 'Sample Closet',
    name: 'Perfume',
    description: 'Scent that defines your presence',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 174',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Perfume NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'RedShirt',
    header: 'Sample Closet',
    name: 'Red Shirt',
    description: 'Bold and stylish fashion choice',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 175',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the RedShirt NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'MiddleShirt',
    header: 'Sample Closet',
    name: 'Middle Shirt',
    description: 'Comfort and style in one',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 176',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the MiddleShirt NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'RightShirt',
    header: 'Sample Closet',
    name: 'Right Shirt',
    description: 'Perfect fit for any occasion',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 177',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the RightShirt NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'MiddleBag',
    header: 'Sample Closet',
    name: 'MiddleBag',
    description: 'Stylish bag for every day',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 178',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the MiddleBag NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'FloorBag',
    header: 'Sample Closet',
    name: 'FloorBag',
    description: 'Trendy and spacious bag',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 179',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the FloorBag NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'GlassBalls',
    header: 'Sample Closet',
    name: 'GlassBalls',
    description: 'Decorate with chic glass balls',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 180',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the GlassBalls NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Hat',
    header: 'Sample Closet',
    name: 'Merch Middle Hat',
    description: 'Top off your look with flair',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 181',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Hat NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Chain',
    header: 'Sample Closet',
    name: 'Merch Top',
    description: 'Add a touch of class',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 182',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Chain NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Shoes',
    header: 'Sample Closet',
    name: 'Merch Bottom Shoes',
    description: 'Step out in ultimate style',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 183',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Shoes NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  }
];

// Animation variants for NFTGrid
const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const HighestZIndexWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999999; // Set a very high z-index
`;

const gridVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};
const AnimatedCollectionWrapper = styled(motion.div)`
  width: ${({ isFullScreen }) => isFullScreen ? '100%' : '50%'};
  float: left;
  height: 100vh;
`;
const getPlaceholderUrl = (index) => `https://source.unsplash.com/random/150x150?sig=${index}`;

const fadeInOut = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const Wrap = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;

  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    width: 3px;  /* Width of the vertical scrollbar */
}

/* Optionally, style the scrollbar's thumb (the draggable element) */
::-webkit-scrollbar-thumb {
    background-color: darkgrey;  /* Or any color you prefer */
    border-radius: 3px;
}

/* Optionally, style the scrollbar's track (the background) */
::-webkit-scrollbar-track {
    background: lightgrey;  /* Or any color you prefer */
}
  //margin-bottom: 200px;

  @media (max-width: 600px) {
    .model {
      transform: scale(0.5);
    }
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    .model {
      transform: scale(0.8);
    }
  }
`;

function WebLogoContainer({ onClick, isDarkMode }) {
  return (
    <>
      {isDarkMode ? (
        <WebLogo to ='/' src={icon2} alt="Icon"/>
                              
      ) : (
        <WebLogo to ='/' src={icon2} alt="Icon"  />
                              
      )}
    </>
  );
}

export function Effects() {
  const { size } = useThree()

  return (
    <EffectComposer stencilBuffer disableNormalPass autoClear={false} multisampling={4}>
      <N8AO halfRes aoSamples={5} aoRadius={0.4} distanceFalloff={0.75} intensity={1} />
      <Outline visibleEdgeColor="black" hiddenEdgeColor="black" blur width={size.width * 1.25} edgeStrength={10} />
      <TiltShift2 samples={5} blur={0.05} />
      <ToneMapping />
      <Outline
    visibleEdgeColor={0x0000ff} // Blue color for the outline
    edgeStrength={10}
    pulseSpeed={0} // No pulsing
    width={1000}
    selectedObjects={[/* Array of objects to apply the outline to, managed via state */]}
  />
    </EffectComposer>
  )
}

export function Focus({ children }) {
  const dof = useRef()
  const ring = useRef()
  const pointer = useRef([0, 0, 0])
  useLayoutEffect(() => {
    dof.current.target = new THREE.Vector3()
    ring.current.geometry = new THREE.RingGeometry(0.125, 0.185)
    ring.current.geometry.rotateX(Math.PI / 2).rotateZ(Math.PI / 2)
  }, [])
  useFrame((state, delta) => {
    easing.damp3(dof.current.target, pointer.current, 0.2, delta)
    ring.current.scale.setScalar(1 + Math.sin(state.clock.elapsedTime * 6) / 4)
  })
  return (
    <>
      <group
        onPointerMove={(e) => {
          e.stopPropagation()
          pointer.current = e.point.toArray()
          ring.current.position.copy(e.point)
          if (e.normal && ring.current) {
            ring.current.rotation.set(...e.normal.multiplyScalar(Math.PI / 2).toArray());
          }
        }}>
        {children}
      </group>
      <mesh ref={ring}>
        <meshBasicMaterial color="#ff0ff0" toneMapped={false} polygonOffset polygonOffsetFactor={-1} side={THREE.DoubleSide} />
      </mesh>
    </>
  )
}

export default function CustomCloset({setWhitelistPopup, whitelistPopup, isFooterVisible, setOrbitControlsEnabled, setIsFooterVisible, started,  buttonPopup, buttonPopup2, buttonPopup3, setButtonPopup, setButtonPopup2, setButtonPopup3,  isDarkMode, orbitControlsEnabled, modelSwitch, setModelSwitch, toggleOrbitControls, daoPopup, setDaoPopup, toggleUtilities,  }) {
  const orbitControlsRef = useRef();
  const [isScrolling, setIsScrolling] = useState(false);
  const [isSelected, setIsSelected] = useState(false); 
  const [isBrowseAllMode, setIsBrowseAllMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false); // New state
  const w = window.innerWidth;
  const isMobile = w <= 600; // Check if the window width is 600 or less
  const maxWidth = 1200;
  const [browseMode, setBrowseMode] = useState(false); //
  const [hoveredGroup, setHoveredGroup] = useState(null);
  const searchBarRef = useRef();

// Function to scroll back to the top and toggle browseMode to false and isSelected to true
const cardToggle = () => {
  setBrowseMode(false);
      setIsSelected(true);
};

// Function to scroll back to the top and toggle browseMode to false and isSelected to false
const homeToggle = () => {
  setBrowseMode(false);
  setIsSelected(true);
  setIsBrowseAllMode(false)
};
  
  // Function to scroll back to the top of the page
  const scrollToggle = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };



  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleBrowseAllClick = () => {
    setIsBrowseAllMode((prevMode) => !prevMode);
  };
  
  const placeholderImageUrl = "https://source.unsplash.com/random/400x300";
  const closetModelSpring = useSpring({
    scale: !isMobile && (isSelected || browseMode) && !isBrowseAllMode ? 
      (windowWidth > maxWidth ? 1 : (windowWidth / maxWidth) * 0.7) : 
      1,
    position: !isMobile && (isSelected || browseMode) && !isBrowseAllMode ? 
      (windowWidth > maxWidth ? [0, 0, 0] : [-0.75, 0, 0]) : 
      [0, 0, 0],
    config: { tension: 200, friction: 20 },
  });
  
  const dynamicSpring = useSpring({
    scale: isMobile ? 
      ((isSelected || browseMode) ? 
        (isBrowseAllMode ? 0 : 0.25) 
        : 0.5) 
      : (isBrowseAllMode && (isSelected || browseMode) ? 0.7 : ((isSelected || browseMode) ? 0.75 : 1)),
    position: isMobile ? 
      (isSelected ? 
        (isBrowseAllMode ? [0, 0, 0] : [0, 0.25, 0]) 
        : [0, -0.5, 0]) 
      : (isBrowseAllMode ? [0, 0, 0] : ((isSelected || browseMode) ? [1.70, -0.25, 0] : [0, 0, 0])),
    config: { tension: 200, friction: 20 }
  });
  
  // CustomCloset component
  const [mobile3Dcollecion, setMobile3Dcollecion] = useState(false); 
  const [pageSwitch, setPageSwitch] = useState(false); // false for ClosetModel, true for new TemporaryNFTCollection
  const [direction, setDirection] = useState(0);

  // References for transitions
  const [subscribers, setSubscribers] = useState(123);
  const [diamonds, setDiamonds] = useState(456);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isDiamondClicked, setIsDiamondClicked] = useState(false);
  const [hoveredItemDetails, setHoveredItemDetails] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);

  const handleFollowClick = () => {
    setIsSubscribed(!isSubscribed);
    setSubscribers(isSubscribed ? subscribers - 1 : subscribers + 1);
  };
  
  const handleDiamondClick = () => {
    setIsDiamondClicked(!isDiamondClicked);
    setDiamonds(isDiamondClicked ? diamonds - 1 : diamonds + 1);
  };

  const handleNextItem = () => {
    setHoveredGroup(null); // Clear the hover state
    setHoveredItemDetails(null); // Clear the hovered item details
    //setIsSelected(false); // Clear the selected state
    setSelectedObject(null); // Clear the selected object
    setCurrentIndex((prevIndex) => (prevIndex + 1) % itemDetailsList.length);
  };
  
  const handlePrevItem = () => {
    setHoveredGroup(null); // Clear the hover state
    setHoveredItemDetails(null); // Clear the hovered item details
    //setIsSelected(false); // Clear the selected state
    setSelectedObject(null); // Clear the selected object
    setCurrentIndex((prevIndex) => (prevIndex - 1 + itemDetailsList.length) % itemDetailsList.length);
  };
  

  const handleItemClose = () => {
    setIsSelected(false);
  };

const togglePageSwitch = () => {
  setPageSwitch((prev) => !prev);
  setDirection(pageSwitch ? -1 : 1); // reverse direction based on current page
};

  const [isMouseVisible, setIsMouseVisible] = useState(true);
  const scroll = useScroll()

  const [shiftedModel, setShiftedModel] = useState(isMobile ? 0.35 : 0);

  const slideInFromRight = useSpring({
    transform: pageSwitch ? 'translateX(0)' : 'translateX(100%)',
    from: { transform: 'translateX(100%)' },
    config: { tension: 250, friction: 20 }
  });
  
  const slideOutToLeft = useSpring({
    transform: pageSwitch ? 'translateX(-100%)' : 'translateX(0)',
    from: { transform: 'translateX(0)' },
    config: { tension: 250, friction: 20 }
  });
  
  const sceneContainerRef = useRef();

  const overlaySpring = useSpring({
    opacity: (isSelected || browseMode) ? 0 : 1,
    config: { tension: 200, friction: 20 },
  });
  const footerSpring = useSpring({
    opacity: isFooterVisible ? 1 : 0,
    config: { tension: 200, friction: 20 },
  });
  const canvasSpring = useSpring({
    transform: isFooterVisible ? 'translateY(-50px)' : 'translateY(0px)',
    config: { tension: 200, friction: 20 },
  });
  
  const contentSpring = useSpring({
    transform: isFooterVisible ? 'translateY(-50px)' : 'translateY(0px)',
    config: { tension: 200, friction: 20 },
  });

  useEffect(() => {
    if (isFooterVisible) {
      sceneContainerRef.current.scrollTo({
        behavior: 'smooth',
      });
    }
    if (!isFooterVisible) {
      sceneContainerRef.current.scrollTo({
        behavior: 'smooth',
      });
    }
  }, [isFooterVisible]);

  useEffect(() => {
    const handleResize = () => {
      console.log('Window resized to: ', window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  const canvasRef = useRef();

  const [focusedItem, setFocusedItem] = useState(null); // State to track focused item

  // Function to handle model item click
  const handleItemClick = (itemName) => {
    setFocusedItem(itemName); // Set the focused item
  };

  const handleOutsideClick = () => {
    if (focusedItem) {
      setFocusedItem(null);
    }
  };
  const handleCloseItem = () => {
    setIsSelected(false);
  };

  const [showAbout, setShowAbout] = useState(false);

  const handleCloseAbout = () => setShowAbout(false);
  useEffect(() => {
    const handleWheel = (e) => {
      if (e.deltaY > 0 || e.deltaX > 0) {
        if (!isSelected) {
          const randomIndex = Math.floor(Math.random() * itemDetailsList.length);
          setIsSelected(true);
          setCurrentIndex(randomIndex);
        }
      } else if (e.deltaY < 0 || e.deltaX < 0) {
        setCurrentIndex(null);
        setHoveredGroup(null); // Clear the hover state
        setHoveredItemDetails(null); // Clear the hovered item details
        setIsSelected(false); // Deselect the item
        setSelectedObject(null); // Ensure no item is highlighted
        setBrowseMode(false);
      }
    };
  
    window.addEventListener('wheel', handleWheel);
  
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [isSelected]);
  
  
  return (
    <>
            {isBrowseAllMode && (
                 <BlurBackground  style={{
  position: 'absolute',
  opacity: isBrowseAllMode > 0 ? 1 : 0, // Set opacity to isMouseVisible (0 or 1)
  transition: 'opacity 0.5s ease', // Add a 0.5s transition with ease timing function
}} isMouseVisible={isBrowseAllMode}></BlurBackground>   )}

{!orbitControlsEnabled &&
        <MainSubNavbar 
            showAbout={showAbout}
            setShowAbout={setShowAbout}
            isDarkMode={isDarkMode} 
            handleFollowClick={handleFollowClick} 
            handleDiamondClick={handleDiamondClick} 
            subscribers={subscribers}
            diamonds={diamonds}
            isSubscribed={isSubscribed}
            isDiamondClicked={isDiamondClicked}
          />
}
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={fadeInOut}
          transition={{ duration: 1 }}
          style={{zIndex: 999999, position: 'relative', top: '12.5%' }}
        >
  

          {!isSelected && !browseMode&& !orbitControlsEnabled &&
            (<MainMouseBody
              style={{
                position: 'absolute',
                top: isMobile ? '6vh' : '25vh',
                opacity: isSelected > 0 ? 0 : 1,
                transition: 'opacity 0.5s ease',
              }}
            >
              <ScrollText             style={{
      
                marginBottom: '-20px',
       
              }}>Hover an item to</ScrollText>
              <ScrollText>view the collection!</ScrollText>
              <MouseIcon className="mouse" />
            </MainMouseBody>)
          }

          {showAbout && (
            <ProjectPopUp handleCloseAbout={handleCloseAbout} placeholderImageUrl={placeholderImageUrl}/>
          )}
        </motion.div>
      </AnimatePresence>

      {orbitControlsEnabled && (
        <>      
          <ClosetTop
            style={{ y: orbitControlsEnabled ? -100 : 100 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
            isDarkMode={isDarkMode}
          />   
          <WebLogoCont
            initial={{ y: orbitControlsEnabled ? -100 : 100, x: '-50%' }}
            animate={{ y: 0, x: '-50%' }}
            transition={{ duration: 0.25, delay: 0.5 }}
            to={'/'}
            isDarkMode={isDarkMode}
          >
            <WebLogoContainer isDarkMode={isDarkMode} />
          </WebLogoCont>
          <MouseBody
            style={{
              position: 'absolute',
              opacity: isMouseVisible > 0 ? 1 : 0,
              transition: 'opacity 0.5s ease',
            }}
          >
            <Paragraph>Click & Drag to Interact!</Paragraph>
            <Mouse className="mouse" />
          </MouseBody>
          <ClosetBottom
            style={{ y: orbitControlsEnabled ? 100 : -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
            isDarkMode={isDarkMode}
          />
        </>
      )}
      <animated.div style={{ ...overlaySpring }}>
        <CustomClosetOverlay
          mobile3Dcollecion={mobile3Dcollecion} 
          setMobile3Dcollecion ={setMobile3Dcollecion}
          togglePageSwitch={togglePageSwitch}
          pageSwitch={pageSwitch}
          setPageSwitch={setPageSwitch}
          setIsMouseVisible={setIsMouseVisible}
          setOrbitControlsEnabled={setOrbitControlsEnabled}
          orbitControlsEnabled={orbitControlsEnabled}
          toggleOrbitControls={toggleOrbitControls}
          toggleUtilities={toggleUtilities}
          isDarkMode={isDarkMode}
          setButtonPopup={setButtonPopup}
          setButtonPopup2={setButtonPopup2}
          setButtonPopup3={setButtonPopup3}
          buttonPopup={buttonPopup}
          buttonPopup2={buttonPopup2}
          buttonPopup3={buttonPopup3}
          setDaoPopup={setDaoPopup}
          daoPopup={daoPopup}
          started={started}
          setModelSwitch={setModelSwitch}
          modelSwitch={modelSwitch}
        />
      </animated.div>

      {(isSelected || browseMode) && (
        <SearchBar
        setIsBrowseAllMode={setIsBrowseAllMode}
        ref={searchBarRef}
        setIsSelected={setIsSelected}
        homeToggle={homeToggle}
        browseMode={browseMode}
         setBrowseMode ={setBrowseMode}
          mobile3Dcollecion = {mobile3Dcollecion}
          setMobile3Dcollecion = {setMobile3Dcollecion}
          isBrowseAllMode={isBrowseAllMode}
          onBrowseAllClick={handleBrowseAllClick}
          onWearablesClick={() => setPageSwitch(true)}
          onNFTsClick={() => setPageSwitch(false)}
          isDarkMode={isDarkMode}
        />
      )}

      {!pageSwitch && browseMode && !isSelected && (
                    
                        <TemporaryNFTCollection
                        searchBarRef={searchBarRef}
                          isDarkMode={isDarkMode}
                          isFullScreen={isBrowseAllMode}
                          isBrowseAllMode={isBrowseAllMode}
                          onBrowseAllClick={handleBrowseAllClick}
                          onWearablesClick={() => setPageSwitch(true)}
                          onNFTsClick={() => setPageSwitch(false)}
                          getPlaceholderUrl={getPlaceholderUrl}
                        />
               
                    )}

                    {pageSwitch && browseMode && !isSelected && (
                  
                            <TemporaryWearableCollection
                            searchBarRef={searchBarRef}
                              isDarkMode={isDarkMode}
                              isFullScreen={isBrowseAllMode}
                              isBrowseAllMode={isBrowseAllMode}
                             onBrowseAllClick={handleBrowseAllClick}
                              onWearablesClick={() => setPageSwitch(true)}
                              onNFTsClick={() => setPageSwitch(false)}
                              getPlaceholderUrl={getPlaceholderUrl}
                            />
                
                    )}
     
      <animated.div
        ref={sceneContainerRef}
        style={{
          position: 'relative',
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
          ...canvasSpring,
        }}
      >
        <animated.div
          style={{
            position: 'relative',
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
            ...contentSpring,
          }}
        >
          {isBrowseAllMode && isSelected && (
<></>
          )}
          <Suspense fallback={null}>
            <Wrap>
              <Canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} camera={{ position: [20, 0.9, 20], fov: 26 }}>
              <ScrollControls damping={0} pages={0}>

                  <Scroll>
           
                        <a.group style={slideOutToLeft}>
                          <a.group scale={dynamicSpring.scale} position={dynamicSpring.position}>
                            <a.group scale={closetModelSpring.scale} position={closetModelSpring.position}>
                              <ClosetModel
                              hoveredGroup={hoveredGroup} 
                              setHoveredGroup={setHoveredGroup}
                              browseMode = {browseMode}
                               setBrowseMode ={setBrowseMode}
                                mobile3Dcollecion={mobile3Dcollecion}
                                setMobile3Dcollecion={setMobile3Dcollecion}
                                isScrolling={isScrolling}
                                setIsScrolling={setIsScrolling}
                                onClickItem={handleItemClick}
                                focusedItem={focusedItem}
                                canvasRef={canvasRef}
                                isMouseVisible={isMouseVisible}
                                setIsMouseVisible={setIsMouseVisible}
                                orbitControlsRef={orbitControlsRef}
                                isFooterVisible={isFooterVisible}
                                scroll={scroll}
                                setModelSwitch={setModelSwitch}
                                modelSwitch={modelSwitch}
                                setButtonPopup={setButtonPopup}
                                buttonPopup={buttonPopup}
                                orbitControlsEnabled={orbitControlsEnabled}
                                toggleOrbitControls={toggleOrbitControls}
                                isDarkMode={isDarkMode}
                                setIsFooterVisible={setIsFooterVisible}
                                setWhitelistPopup={setWhitelistPopup}
                                whitelistPopup={whitelistPopup}
                                isSelected={isSelected}
                                setIsSelected={setIsSelected}
                                setCurrentIndex={setCurrentIndex}
                                currentIndex={currentIndex}
                                onClose={handleCloseItem}
                                onNext={handleNextItem}
                                onPrev={handlePrevItem}
                                cardToggle={cardToggle}
                                selectedObject={selectedObject}
                               setSelectedObject={setSelectedObject}

                             hoveredItemDetails={hoveredItemDetails} 
                             setHoveredItemDetails={setHoveredItemDetails}
                              />
                            </a.group>
                          </a.group>
                        </a.group>
      

         



                  
                   
                  </Scroll>
                </ScrollControls>
                
              </Canvas>
            </Wrap>
          </Suspense>
        </animated.div>
      </animated.div>
    
      {(isSelected && !browseMode) && (
                     
                        <ItemDetailsCardExpanded 
                            isSelected={isSelected} 
                            isDarkMode={isDarkMode} 
                            isMobile={isMobile}
                            details={itemDetailsList} 
                            currentIndex={currentIndex}
                            onClose={handleCloseItem}
                            onNext={handleNextItem}
                            onPrev={handlePrevItem}
                            setIsSelected={setIsSelected} 
                            hoveredGroup={hoveredGroup}
                             setHoveredGroup={setHoveredGroup}
                             hoveredItemDetails={hoveredItemDetails} 
                             setHoveredItemDetails={setHoveredItemDetails}
                        />
                    
                    )}
      {isFooterVisible && (
        <animated.div style={{ ...footerSpring }}>
          <Footer
            setDaoPopup={setDaoPopup}
            daoPopup={daoPopup}
            isDarkMode={isDarkMode}
            setWhitelistPopup={setWhitelistPopup}
            whitelistPopup={whitelistPopup}
          />
        </animated.div>
      )}
    </>
  );
}
