/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React, { useRef } from 'react'

export default function FloorBag(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/FloorBag.glb')
  return (
    <group scale={1.75} ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI, -0.86, -Math.PI]} scale={[0.03, 0.03, 0.03]}>
        <mesh geometry={nodes.bag_bag_0001.geometry} material={materials['material.003']} />
        <mesh geometry={nodes.bag_bag_0001_1.geometry} material={materials['belt.003']} />
        <mesh geometry={nodes.bag_bag_0001_2.geometry} material={materials['metal.003']} />
      </group>
    </group>
  )
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/FloorBag.glb')
