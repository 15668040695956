import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from 'react';
import { FaDiscord, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import dao from '../../images/daoBlack.svg';
import dao2 from '../../images/daoWhite.svg';
import mbg2 from '../../images/middleBG.png';
import bg2 from '../../images/w3oDayBg.svg';
import white from '../../images/w3oWhitelistBlack.svg';
import white2 from '../../images/w3oWhitelistWhite.svg';
import x from '../../images/xLogoBlack.svg';
import x2 from '../../images/xLogoWhite.svg';
import PopUp2 from "../PopUp/PopUps/PopUp2";
import PopUp3 from "../PopUp/PopUps/PopUp3";
import {
  BottomLine,
  BottomLink,
  ButtonText,
  Closet,
  ClosetIcon,
  Dao,
  DiscordButton,
  InstagramButton,
  Masterplan,
  Meet,
  Privacy,
  PrivacyTosContainer,
  RoundedSocialButtons,
  SideBtnWrap,
  SidebarButtonWrap,
  SidebarContainer,
  SidebarContainerWrap,
  SidebarLink,
  SidebarMenu,
  SidebarRoute,
  SocialMediaWrap,
  Terms,
  TextSpan,
  TikTokButton,
  TopLine,
  Wallet,
  WalletIcon,
  Whitelist,
  XButton,
  YoutubeButton
} from './SidebarElements';


const Sidebar = ({isFooterVisible, setIsFooterVisible, currentState, setCurrentState, isOpen, toggle, buttonPopup3, buttonPopup2, trigger5, setTrigger5, trigger4, setTrigger4,  isDarkMode, setWhitelistPopup, setDaoPopup, whitelistPopup, setButtonPopup, setButtonPopup2, setButtonPopup3, daoPopup, props}) => {
  const [isWhitelistHovered, setWhitelistHovered] = useState(false);
  const [isXHovered, setXHovered] = useState(false);
  const [isDaoHovered, setDaoHovered] = useState(false);
  const [isWalletHovered, setIsWalletHovered] = useState(false);
  const [isClosetHovered, setIsClosetHovered] = useState(false);
  const width = window.innerWidth;
  const isMobile = width <= 600; // Check if the window width is 600 or less
  const controls = useAnimation();
  const handleWalletHover = () => {
    setIsWalletHovered(true);
  };
  const handleWalletMouseLeave = () => {
    setIsWalletHovered(false);
  };

  const handleClosetMouseLeave = () => {
    setIsClosetHovered(false);
  };
  const handleClosetHover = () => {
    setIsClosetHovered(true);
  };





  useEffect(() => {
    if (isOpen) {
      controls.start({ opacity: 1, x: 0  });
    } else {
      controls.start({ opacity: 1, x: -500 });
      
    }
  }, [isOpen]);




    return (
<>

    
 <>
<SidebarContainerWrap isDarkMode={isDarkMode} isOpen={isOpen} onClick={toggle} />
<SidebarContainerWrap/>
<AnimatePresence>
<SidebarContainer
  initial={{ opacity: 0, x: '-100vw' }}
  animate={controls}
  exit={{ opacity: 0, x: '-100vw'}}
  transition={{ duration: 0.25 }}
  //transition={isOpen ? { staggerChildren: 0.2, staggerDirection: -1 } : { staggerChildren: 0.2, staggerDirection: 1 }}
  style={{
    backgroundImage: `url(${isDarkMode ? mbg2 : bg2})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }}
  isOpen={isOpen}
>
   

   

   
           
                <SidebarMenu>


            
            
      
        


            
          
        <SideBtnWrap          
                   initial={{ x: -100, opacity: 0, transform: "matrix(0, 0, 0, 0, 0, 0)" }}
  transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.4 } : { staggerChildren: 0.2, staggerDirection: 1 }}
  animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)", transform: "matrix(1, 0, 0, 1, 0, 0)" } : { x: -100, opacity: 0, filter: "blur(5px)", transform: "matrix(0, 0, 0, 0, 0, 0)" }}
  isDarkMode={isDarkMode}>
        
        <SidebarRoute   whileHover={{ scale: 1.1, }}
        //transition={{type: 'spring', stiffness: 300}} 
        onClick={() => {
    setCurrentState(4);
    toggle();  // Also trigger toggle when "Meet the Team" is clicked
  }} isDarkMode={isDarkMode}>

          <Meet isDarkMode={isDarkMode}/>
            Meet the Team
          </SidebarRoute>
    
        </SideBtnWrap>
       
             
             
        <SideBtnWrap          
          initial={{ x: -100, opacity: 0, transform: "matrix(0, 0, 0, 0, 0, 0)" }}
  transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.425 } : { staggerChildren: 0.2, staggerDirection: 1 }}
  animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)", transform: "matrix(1, 0, 0, 1, 0, 0)" } : { x: -100, opacity: 0, filter: "blur(5px)", transform: "matrix(0, 0, 0, 0, 0, 0)" }}
  isDarkMode={isDarkMode}>
       
        <SidebarRoute    whileHover={{ scale: 1.1, }}
        //transition={{type: 'spring', stiffness: 300, duration: 0.1      }} 
        onClick={() => {
    setCurrentState(3);
    toggle();  // Also trigger toggle when "MASTER PLAN" is clicked
  }}
            isDarkMode={isDarkMode}>{' '}
          <Masterplan  src={isDarkMode ? (isXHovered ? x2 : x) : (isXHovered ? x : x2)}
              isDarkMode={isDarkMode} />
            MASTER PLAN
          </SidebarRoute>
    
        </SideBtnWrap>
   



        <SideBtnWrap          
      initial={{ x: -100, opacity: 0, transform: "matrix(0, 0, 0, 0, 0, 0)" }}
  transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.2 } : { staggerChildren: 0.2, staggerDirection: 1 }}
  animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)", transform: "matrix(1, 0, 0, 1, 0, 0)" } : { x: -100, opacity: 0, filter: "blur(5px)", transform: "matrix(0, 0, 0, 0, 0, 0)" }}
  isDarkMode={isDarkMode}>
       
        <SidebarRoute 
          // style={{    transform: 'matrix(1, 0, 0, 1, 0, 0)'}}
        whileHover={{ scale: 1.1, }}
       // transition={{type: 'spring', stiffness: 300}}
          onClick={() => {setDaoPopup(true)}}
         onMouseEnter={() => setDaoHovered(true)}
            onMouseLeave={() => setDaoHovered(false)} isDarkMode={isDarkMode}>
         
          <Dao path="/Dao"  src={isDarkMode ? (isDaoHovered ? dao2 : dao) : (isDaoHovered ? dao : dao2)}
              isDarkMode={isDarkMode} />
            Join Our Team
          </SidebarRoute>
     
        </SideBtnWrap>
       
 

             
       
        <SideBtnWrap          
                  initial={{ x: -100, opacity: 0, transform: "matrix(0, 0, 0, 0, 0, 0)" }}
  transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.3 } : { staggerChildren: 0.2, staggerDirection: 1 }}
  animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)", transform: "matrix(1, 0, 0, 1, 0, 0)" } : { x: -100, opacity: 0, filter: "blur(5px)", transform: "matrix(0, 0, 0, 0, 0, 0)" }}
  isDarkMode={isDarkMode}>
        
          <SidebarRoute  
             whileHover={{ scale: 1.1, }}
       // transition={{type: 'spring', stiffness: 30}}
          onClick={() => {setWhitelistPopup(true)}}
             onMouseEnter={() => setWhitelistHovered(true)}
            onMouseLeave={() => setWhitelistHovered(false)} isDarkMode={isDarkMode}>
             <Whitelist src={isDarkMode ? (isWhitelistHovered ? white2 : white) : (isWhitelistHovered ? white : white2)}
              isDarkMode={isDarkMode} />
            Get Whitelisted
          </SidebarRoute>
         
        </SideBtnWrap>
          
                  </SidebarMenu>

<SidebarButtonWrap>


             
<Closet
  onMouseEnter={handleClosetHover}
  onMouseLeave={handleClosetMouseLeave}
  initial={{ x: -150, opacity: 0 }}
  animate={isOpen ? { x: 0, opacity: 1 } : { x: -150, opacity: 0 }}
  transition={{ delay: isOpen ? 0.45 : 0 }}
 // whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}  // Quick transition for both hover in and out
  isDarkMode={isDarkMode}
>
                <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}>
                        
                               
                                      <SidebarLink       onClick={() => {setButtonPopup3(true); setButtonPopup(false);  setButtonPopup2(false); }} isDarkMode={isDarkMode}  smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>  
                                        <ButtonText>
                                          <TextSpan isDarkMode={isDarkMode}>Your Closet</TextSpan>  
                                          <ClosetIcon isDarkMode={isDarkMode}  />
                                        </ButtonText> 
                                      </SidebarLink>
                                      </motion.div>
     
     
                              </Closet>
                         
        
             
                              <Wallet
  onMouseEnter={handleWalletHover}
  onMouseLeave={handleWalletMouseLeave}
  initial={{ x: -150, opacity: 0 }}
  animate={isOpen ? { x: 0, opacity: 1 } : { x: -150, opacity: 0 }}
  transition={{ delay: isOpen ? 0.465 : 0 }}
 // whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}  // Quick transition for both hover in and out
  isDarkMode={isDarkMode}
>
                            
                            <motion.div whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}>
                 
                                      <SidebarLink       onClick={() => {setButtonPopup2(true); setButtonPopup3(false);  setButtonPopup(false); }}  isDarkMode={isDarkMode} /*state = {user} *//*  onClick={loadWeb3} */ smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>   
                                      <ButtonText>
                                            <TextSpan  isDarkMode={isDarkMode} >Your Wallet</TextSpan>  
                                            <WalletIcon isDarkMode={isDarkMode}  />
                                          </ButtonText> 
                                      </SidebarLink>
                                      </motion.div>
                   
                             
                              </Wallet>
                     


                   
</SidebarButtonWrap>


<PrivacyTosContainer 
  onMouseEnter={handleWalletHover}
  onMouseLeave={handleWalletMouseLeave}
  initial={{ x: -150, opacity: 0 }}
  animate={isOpen ? { x: "-50%", scale: 1, filter: "blur(0px)", opacity: 1 } : { x: -150,  filter: "blur(5px)", opacity: 0, scale: 0 }}
  transition={{ delay: isOpen ? 0.475 : 0 }}
 // whileHover={{ scale: 1.1, transition: { duration: 0.05 } }}  // Quick transition for both hover in and out
  isDarkMode={isDarkMode}
>
      <BottomLink isDarkMode={isDarkMode} href="/Tos"><Terms isDarkMode={isDarkMode}/> Terms of Service</BottomLink>{' '}
    <BottomLink isDarkMode={isDarkMode} href="/PrivacyPolicy"><Privacy isDarkMode={isDarkMode}/> Privacy</BottomLink>{' '}
        </PrivacyTosContainer>
             
                  <SocialMediaWrap  
                     initial={{ x: -150, opacity: 0}}
                
                //animate={isOpen ? "open" : "closed"}
               //</SidebarMenu> initial={isOpen ? { opacity: 1 } : { opacity: 1 }}
                transition={isOpen ? { staggerChildren: 2, staggerDirection: -1, delay: 0.5} :  {staggerChildren: 0.2, staggerDirection: 1}}
                animate={isOpen ? { x: 0, opacity: 1, filter: "blur(0px)" } :{ x: -150, opacity: 0, filter: "blur(5px)"}}  
           isDarkMode={isDarkMode}
                >
                
                      <RoundedSocialButtons    transition={isOpen ? { staggerChildren: 8, staggerDirection: -1} :  {staggerChildren: 0.2, staggerDirection: 1}}      >
              
                  <XButton   href="https://twitter.com/web3outfitters" target="_blank">
                    <FaXTwitter />
                  </XButton>

                  <DiscordButton   href="https://discord.gg/qSk7XKdtHa" target="_blank" >
                    <FaDiscord />
                  </DiscordButton>

                  <InstagramButton    href="https://www.instagram.com/web3outfitters" target="_blank">
                    <FaInstagram />
                  </InstagramButton>


                  <YoutubeButton  href="https://www.youtube.com/@web3outfitters" target="_blank">
                    <FaYoutube />
                  </YoutubeButton>          

                  <TikTokButton href="https://www.tiktok.com/web3outfitters" target="_blank" >
                    <FaTiktok />
                  </TikTokButton>

                      </RoundedSocialButtons>
                      
                </SocialMediaWrap>
               
{/* ... other content of your app */}

  
           
            <TopLine/>
            <BottomLine/>
    

        
  <PopUp3  isDarkMode={isDarkMode} trigger3 ={buttonPopup3} setTrigger3 = {setButtonPopup3} />
  <PopUp2  isDarkMode={isDarkMode} trigger2 ={buttonPopup2} setTrigger2 = {setButtonPopup2} />
        </SidebarContainer>
   </AnimatePresence>
   </>
  
        </>
        
    );
};

export default Sidebar