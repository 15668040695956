import { useGLTF } from '@react-three/drei'
import React from 'react'

export function Logo(props) {
  const { nodes, materials } = useGLTF('/w3oNameWhite.glb')

  // Clone the material to avoid modifying the original
  const material = materials['SVGMat.011'].clone()

  // Modify the material color based on isDarkMode
  if (props.isDarkMode) {
    material.color.set('black') // Set to black for dark mode
  } else {
    material.color.set('white') // Set to white for light mode
  }

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.w3oName.geometry}
        material={material}
        position={[0, 0, 0.015]}
        rotation={[1.574, 0.015, -0.016]}
        scale={[3.696, 3.67, 3.076]}
      />
    </group>
  )
}

useGLTF.preload('/w3oNameWhite.glb')
