
import { a, useSpring } from "@react-spring/three";
import { AccumulativeShadows, PerformanceMonitor, RandomizedLight, Scroll, ScrollControls, Sparkles, useIntersect, useTexture } from '@react-three/drei';
import { useLoader, useThree } from '@react-three/fiber';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import arrowDark from "../../images/arrowDark.png";
import arrowLight from "../../images/arrowLight.png";
import { itemsInfo } from "./Data";
import './Team.css';
import {
  InfoWrapper,
  ProfileBio,
  ProfileImage,
  ProfileName,
  ProfileRole
} from "./TeamElements";
import Symbol from './W3oSymbol';

function createCircularMaskTexture() {
    const size = 512; // Adjust the size as needed
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext('2d');
    const centerX = size / 2;
    const centerY = size / 2;
    const radius = size / 2;

    context.fillStyle = 'transparent'; // Set the background color
    context.fillRect(0, 0, size, size);

    context.beginPath();
    context.arc(centerX, centerY, radius, 0, Math.PI * 2);
    context.closePath();
    context.clip();

    // Fill the circle with white to create the mask
    context.fillStyle = 'white';
    context.fill();

    return new THREE.CanvasTexture(canvas);
};

function createSquareMaskTexture() {
  const size = 512; // Adjust the size as needed
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext('2d');
  
  // Fill the entire canvas with white to create the mask
  context.fillStyle = 'white';
  context.fillRect(0, 0, size, size);

  // Draw a square in the center with a transparent color
  const squareSize = size * 0.8; // Making the square 80% of the canvas size
  const squareStart = (size - squareSize) / 2; // Center the square on the canvas
  context.clearRect(squareStart, squareStart, squareSize, squareSize);

  return new THREE.CanvasTexture(canvas);
};



function Item2({ url, scale, isSelected, onSelectItem, ...props }) {
  const visible = useRef(false);
  const ref = useIntersect((isVisible) => (visible.current = isVisible));
  const [hovered, hover] = useState(false);
  const { height } = useThree((state) => state.viewport);
  const [selected, setSelected] = useState(isSelected);
  // Handle cursor change
  useEffect(() => {
    document.body.style.cursor = selected ? 'pointer' : hovered ? 'pointer' : 'auto';
  }, [hovered, selected]);

  // Define the wobble effect
  const wobbleEffect = useSpring({
    scale: isSelected ? [1.1, 1.1, 1.1] : [1, 1, 1],
    config: { mass: 1, tension: 180, friction: 12 },
  });

  const circularMaskTexture = useMemo(() => createSquareMaskTexture(), []);

  // Define a spring animation for opacity
  const opacitySpring = useSpring({
    opacity: isSelected ? 1 : 0.9,
    config: { duration: 1000 },
  });

  // Handle cursor change
  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto';
  }, [hovered]);


  // Glow effect material
  const glowMaterial = useMemo(() => new THREE.MeshBasicMaterial({
    color: isSelected ? '#00FFFF' : '#ffffff', // Glow color when selected
    side: THREE.DoubleSide,
    transparent: true,
    opacity: isSelected ? 1 : 0.9,
    emissive: isSelected ? new THREE.Color(0x00ff00) : new THREE.Color(0x000000),
    emissiveIntensity: isSelected ? 1 : 0,
  }), [isSelected]);

  const itemMaterial = new THREE.MeshStandardMaterial({
    color: isSelected ? 'white' : 'grey',
    emissive: isSelected ? new THREE.Color(0x00ff00) : new THREE.Color(0x000000),
    emissiveIntensity: isSelected ? 1 : 0,
  });

  

  // Texture for the item
  const texture = useTexture(url);

  // Click handler
  const handleClick = () => {
    setSelected(!selected);
    onSelectItem && onSelectItem(); // Trigger onSelectItem if provided
  };
  return (
    <group {...props}>
      <group>
        <a.mesh
          ref={ref}
          onClick={handleClick}
          scale={scale}
          style={{ cursor: isSelected ? 'pointer' : 'pointer' }}
        >
          <planeGeometry args={[1, 1]} />
          <>

  <meshBasicMaterial    emissiveIntensity={20} map={texture} attach="material" />
</>
          <meshBasicMaterial map={texture} attach="material" {...glowMaterial} />
          <a.meshBasicMaterial map={circularMaskTexture} side={THREE.DoubleSide} transparent />
          <a.meshBasicMaterial
            map={useTexture(url)}
            emissiveIntensity={2}
            side={THREE.DoubleSide}
            transparent
            color={isSelected ? '#00ffff' : '#ffffff'} // Use color to change item color
            {...opacitySpring}
            {...wobbleEffect}
          />
        </a.mesh>
      </group>
    </group>
  );
}


// Keyframes for the glitch effect
const glitchAnimation = keyframes`
  0%, 100% {
    opacity: 1;
    transform: none;
    filter: blur(0px);
  }
  10%, 90% {
    opacity: 0.7;
    transform: translate(-2px, 0);
    filter: blur(2px);
  }
  20%, 80% {
    opacity: 0.4;
    transform: translate(2px, 0);
    filter: blur(4px);
  }
  30%, 70% {
    opacity: 0.6;
    transform: translate(-2px, 0);
    filter: blur(2px);
  }
  40%, 60% {
    opacity: 0.3;
    transform: translate(2px, 0);
    filter: blur(3px);
  }
  50% {
    opacity: 0.5;
    transform: translate(-2px, 0);
    filter: blur(1px);
  }
`;

// Styled component for the header container
const MeetTeamHeaderContainer = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;


  @media (max-width: 600px) {
    top: 10%;
  }
`;

// Styled component for each letter in the header
const MeetTeamHeader = styled.span`
  font-size: 100px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#e2e8f0' : '#2d3748')};
  //font-weight: 200 !important;
  white-space: nowrap;
  opacity: 0;
  animation: ${glitchAnimation} 3s forwards;


  @media (max-width: 1200px) {
    font-size: 90px;
  }
  @media (max-width: 900px) {
    font-size: 80px;
  }
  @media (max-width: 768px) {
    font-size: 60px;
  }
  @media (max-width: 600px) {
    font-size: 35px;
  }
`;

// React component for the animated header
// React component for the animated header
function AnimatedHeader({ isDarkMode, isMobile }) {
  const text = "MEET THE TEAM";
  const words = text.split(' '); // Split by spaces to separate words

  const marginSize = isMobile ? '0.5rem' : '1rem'; // Adjust margin based on isMobile

  return (
    <MeetTeamHeaderContainer>
      {words.map((word, index) => (
        <MeetTeamHeader
          key={index}
          isDarkMode={isDarkMode}
          style={{ 
            animationDelay: `${0.1 * index}s`, 
            display: 'inline-block', 
            marginRight: marginSize, 
            marginLeft: marginSize // Use marginSize for left and right margins
          }}
        >
          {word}
        </MeetTeamHeader>
      ))}
    </MeetTeamHeaderContainer>
  );
}





function AnimatedItem({
  daoImages,
  position,
  imageUrl,
  scale,
  onSelectItem,
  selectedItem,
  index,
}) {
  const isSelected = selectedItem === index;
  const itemStyle = isSelected ? {} : {};

  const onClick = () => {
    onSelectItem(index);
  };

  const selectionAnimation = useSpring({
    scale: isSelected ? [1.1, 1.1, 1.1] : [1, 1, 1],
    config: { tension: 300, friction: 10 },
  });

  const itemAnimation = useSpring({
    from: {
      position: [0, 0, 0],
      opacity: 0,
      scale: 0,
    },
    to: {
      position,
      opacity: 1,
      scale: 1,
    },
    config: { duration: 250 },
  });

  const emissiveMaterial = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        emissive: isSelected ? new THREE.Color(0xff0000) : new THREE.Color(0x000000),
      }),
    [isSelected]
  );

  return (
    <a.group scale={selectionAnimation.scale}>
    <a.group scale={itemAnimation.scale} opacity={itemAnimation.opacity} position={position} onClick={onSelectItem}>
      <Item2 isSelected={isSelected} daoImages={daoImages} url={imageUrl} scale={scale} position={position} onSelectItem={onSelectItem} />
    </a.group>
  </a.group>
  );
}





function Items({ daoImages, onSelectItem, selectedItem }) {
  const { viewport } = useThree();
  const [scaledCreatives, setScaledCreatives] = useState(1.5);
  const [radius, setRadius] = useState(0.6);
  const [creativePosition, setCreativePosition] = useState(0.5);
  const [scale, setScale] = useState([10 / 24 * 1.5, 10 / 24 * 1.5, 0.5 * 0.25]);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 600) {
      setScaledCreatives(1.5);
      setRadius(0.6);
      setCreativePosition(0.5);
      setScale([10 / 24 * 1.8, 10 / 24 * 1.8, 0.5 * 0.25]);
    } else if (width <= 768) {
      setScaledCreatives(1.5);
   
      setCreativePosition(0.5);
      setScale([10 / 24 * 1.6, 10 / 24 * 1.6, 0.5 * 0.25]);
    } else if (width <= 1200) {
      setScaledCreatives(1.6);
   
      setCreativePosition(0.5);
      setScale([10 / 24 * 1.5, 10 / 24 * 1.5, 0.5 * 0.25]);
    } else {
      setScaledCreatives(1.8);
      setRadius(0.6);
      setCreativePosition(0.5);
      setScale([10 / 24 * 1.5, 10 / 24 * 1.5, 0.5 * 0.25]);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const numItems = 10;
  const angleStep = (2 * Math.PI) / numItems;

  const imageUrls = useMemo(() => [
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team1.png?${new Date().getTime()}`",
      "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team2.png",
      "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team3.png",
      "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team4.png",
      "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team5.png",
      "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team6.png",
      "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team7.png",
      "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team8.png",
      "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team9.png",
      "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team10.png",
  ], []);

  const CreativeItems = imageUrls.map((imageUrl, index) => {
    const angle = (index / numItems) * 2 * Math.PI;
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * radius;
    const position = [x, y, 0];
    return (
      <AnimatedItem
        key={index}
        scale={scale}
        position={position}
        selectedItem={selectedItem}
        index={index}
        onSelectItem={() => onSelectItem(index)}
        imageUrl={imageUrl}
        daoImages={daoImages}
      />
    );
  });

  return (
    <a.group>
      {CreativeItems}
    </a.group>
  );
}




  function getArrowPositionAndDirection(selectedIndex, numItems, radius, offsetRadius) {
    const angle = (selectedIndex / numItems) * Math.PI * 2;
    // Calculate position closer to the center
    const arrowX = Math.cos(angle) * (radius - offsetRadius);
    const arrowY = Math.sin(angle) * (radius - offsetRadius);
    // Arrow position
    const position = [arrowX, arrowY, arrowY]; // Assuming Y is up and you want the arrow on the ground plane
    // Arrow rotation to face towards the center (reversed angle + Math.PI)
    const rotation = [0, -angle + Math.PI / 2, 0];
  
    return { position, rotation };
  }
  
  function Arrow({ selectedIndex, radius, isDarkMode }) {
    const numItems = 10;
    // Calculate initial position and rotation based on selectedIndex
    const angle = (selectedIndex / numItems) * 2 * Math.PI;
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * radius;
    const position = [x, y, 0]; // Cone's position on the circle's perimeter
    const rotationZ = angle + Math.PI *2; // Rotation to point towards the circle's center
    const rotation = [0, 0, rotationZ];
    const texture = useLoader(TextureLoader, isDarkMode ? arrowLight : arrowDark);

    const { position: springPosition, rotation: springRotation } = useSpring({
      position,
      rotation,
      config: { mass: 1, tension: 170, friction: 26 },
    });
  
    return (
      <a.mesh position={springPosition} rotation={springRotation}>
      <planeGeometry args={[0.2, 0.2]} /> {/* Adjust size as needed */}
      <meshBasicMaterial map={texture} transparent={true} />
    </a.mesh>
    );
  }
  

  

  










const innerMaterial = new THREE.MeshStandardMaterial({
  transparent: true,
  opacity: 1,
  color: 'black',
  roughness: 0,
  side: THREE.FrontSide,
  blending: THREE.AdditiveBlending,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  envMapIntensity: 2
});




  




function Scene({decreaseShiftedModel,  isScrolled, setIsScrolled, shiftedModel, isMobile,  scrollExplore, setScrollExplore, isDarkMode, config, scroll}) {
  
  const { size } = useThree();
  const [scale, setScale] = useState(1);
  const [scale2, setScale2] = useState(1);
 
  useEffect(() => {
    const updateScale = () => {
      const width = size.width;
      let newScale = 1;

      if (width <= 600) {
        newScale = 1; // Adjust the scale value as needed for smaller screens
      } else if (width > 600 && width <= 1200) {
        newScale = 0.71428571; // Adjust the scale value as needed for medium-sized screens
      }
      setScale2(newScale)
      setScale(newScale);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [size?.width]);


  return (
    <>
            <Symbol decreaseShiftedModel={decreaseShiftedModel} shiftedModel={shiftedModel} isMobile={isMobile} 
                   scrollExplore={scrollExplore} 
                    setScrollExplore={setScrollExplore}
                     scroll={scroll} isDarkMode={isDarkMode} scale={scale} isScrolled={isScrolled} setIsScrolled={setIsScrolled} />

      
      </>
  );
}








export default function Team({ scroll, scrollExplore, 
                                   setScrollExplore,
                                   isDarkMode, orbitControlsEnabled, setButtonPopup,  
                               }) {
 // const { camera, gl } = useThree();
 const width = window.innerWidth;

 const modelTransform = useSpring({
   position: width <= 1200 && width >= 601 ? [1 - (width / 1200), 0, 0] : [0, 0, 0],
   scale: width <= 1200 && width >= 601 ? 1 - ((1200 - width) / 3000) : 1,
   config: { mass: 1, tension: 170, friction: 26 },
 });



  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 600) {
      setGroupProps({ position: [0, -1, 0], scale: 0.4 });
    } else if (width <= 768) {
      setGroupProps({ position: [0, -0.75, 0], scale: 0.6 });
    } else if (width <= 1200) {
      setGroupProps({ position: [0, -0.5, 0], scale: 0.8 });
    } else {
      setGroupProps({ position: [-1.5, 0, 0], scale: 1 });
    }
  };

  useEffect(() => {
    handleResize(); // Call once on mount
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



   // Animation variants for each component
// Variants for animations
const imageVariants = {
  hidden: { scale: 0, opacity: 0 },
  visible: { scale: 1, opacity: 1, transition: { duration: 0.5 } },
};

const textVariants = {
  hidden: { filter: 'blur(15px)', opacity: 0, x: -100 },
  visible: { filter: 'blur(0px)', opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.5 } },
};
  const ref = useRef();
  const [perfSucks, degrade] = useState(false);
  //const [position, setPosition] = useState([0, -1.5, 0]);

  const [groupProps, setGroupProps] = useState({ position: [-1.5, 0, 0], scale: 0.8 });
  const [selectedItem, setSelectedItem] = useState(0);

  // Function to update group position and scale based on window size
  const updateGroupProps = () => {
      const width = window.innerWidth;
      if (width <= 600) {
          setGroupProps({ position: [0, -0.6, 0], scale: 0.4 });
      } else {
          setGroupProps({ position: [-1.5, -0.65, 0], scale: 0.8 });
      }
  };

  useEffect(() => {
      updateGroupProps(); // Update on mount
      window.addEventListener('resize', updateGroupProps); // Update on window resize

      return () => {
          window.removeEventListener('resize', updateGroupProps);
      };
  }, []);
  const index = 0;
  const w = window.innerWidth;
  const isMobile = w <= 600; // Check if the window width is 600 or less
 // const scroll = useScroll()
const shiftedModel = isMobile ? 0.2 : 0; 


useEffect(() => {
  // Reset cursor when the component is mounted
  document.body.style.cursor = 'auto';
  
  // Optionally, reset cursor when the component is unmounted
  return () => {
    document.body.style.cursor = 'auto';
  };
}, []);
const onSelectItem = (index) => {
  setSelectedItem(index); // Update the selected item state when an item is selected
};
const onSelect = useCallback(() => {
  onSelectItem(index);
}, [onSelectItem, index]);

  
  const [isScrolled, setIsScrolled] = useState(false);
  // Create a useSpring animation for the position









  const openWhitelistLink = () => {
    setButtonPopup(true);
  };


  const numItems = 10; // Or however many items you have
  const radius = 0.65; // Adjust based on your circle's radius
  const offsetRadius = 0.1; // How much closer to the center you want the arrow
  const { position, rotation} = getArrowPositionAndDirection(selectedItem, numItems, radius, offsetRadius);
  
  return (

<>



        

<ScrollControls pages={0} /* Adjust the number of pages based on content length */>
    



          <a.group position={modelTransform.position} scale={modelTransform.scale}>
            <PerformanceMonitor onDecline={() => degrade(true)} />
            <color attach="background" args={['#000']} />
            <pointLight position={[-0.5, -1.75, 0]} intensity={3.5} />
            <AccumulativeShadows frames={100} alphaTest={0.85} opacity={0.8} color="white" scale={20} position={[0, -0.005, 0]}>
              <RandomizedLight amount={8} radius={6} ambient={0.5} intensity={1} position={[-1.5, 2.5, -2.5]} bias={0.001} />
            </AccumulativeShadows>
            <Sparkles size={5} color={isDarkMode ? '#cc5500' : '#000'} scale={[10, 10, 10]} />
            <group {...groupProps}>
              <group>
                <Scene
                  isScrolled={isScrolled}
                  shiftedModel={shiftedModel}
                  isMobile={isMobile}
                  scrollExplore={scrollExplore}
                  setScrollExplore={setScrollExplore}
                  scroll={scroll}
                  isDarkMode={isDarkMode}
                />
              </group>
              <mesh scale={[0.95, 1, 0.95]} material={innerMaterial} />
              <mesh material={innerMaterial} />
              <Items selectedItem={selectedItem} onSelectItem={onSelectItem} />
              <Arrow isDarkMode={isDarkMode} selectedIndex={selectedItem} radius={isMobile ? 1 : 0.8} />
            </group>
          </a.group>
   
<group  position={[0, 0, 0]} >
{selectedItem !== null && selectedItem < itemsInfo.length && (
  <Scroll html style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
  <AnimatedHeader isDarkMode={isDarkMode} isMobile={isMobile} />
  <InfoWrapper>
  <ProfileImage 
        src={itemsInfo[selectedItem].imageUrl} 
        alt={itemsInfo[selectedItem].name} 
        variants={imageVariants} 
        initial="hidden" 
        animate="visible" 
        key={`image-${selectedItem}`} // Key based on selectedItem
      />
      <ProfileName 
      isDarkMode={isDarkMode}
        variants={textVariants} 
        initial="hidden" 
        animate="visible" 
        key={`name-${selectedItem}`} // Key based on selectedItem
      >
        {itemsInfo[selectedItem].name}
      </ProfileName>
      <ProfileRole 
      isDarkMode={isDarkMode}
        variants={textVariants} 
        initial="hidden" 
        animate="visible" 
        key={`role-${selectedItem}`} // Key based on selectedItem
      >
        {itemsInfo[selectedItem].role}
      </ProfileRole>
      <ProfileBio 
      isDarkMode={isDarkMode}
        variants={textVariants} 
        initial="hidden" 
        animate="visible" 
        key={`bio-${selectedItem}`} // Key based on selectedItem
      >
        {itemsInfo[selectedItem].bio}
      </ProfileBio>
  </InfoWrapper>
</Scroll>
)}
</group>

</ScrollControls>
</>

    
    
    );
}